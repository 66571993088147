<template>
        <span>유효시간 <span>{{ padLeadingZeros( countminutes,2) }} : {{ padLeadingZeros(countseconds,2)}}</span>초</span>  
</template>

<script>
export default {
  props: {
    minutes: {
      type: Number,
      required: true,
    },
    seconds: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
        countDown: 0,
        initCount: 0,
    };
  },
  mounted() {
  },
  created() {
    this.initCount  = this.minutes * 60 + this.seconds ; 
    this.restartCountDownTimer(); 
  },
  computed: {
    countseconds() {
      return  this.countDown % 60 ;
    },
    countminutes() {
      return Math.floor( this.countDown / 60) ;
    },
  },
  methods: {
    padLeadingZeros(num, size) {
        var s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    },
    restartCountDownTimer() {
        this.countDown = this.initCount ; 
        this.countDownTimer();
    },
    countDownTimer () {
        if (this.countDown > 0) {
            setTimeout(() => {
                this.countDown -= 1
                this.countDownTimer()
            }, 1000)
        }
        else {
           //  count complete 
      
          this.$emit("onTimeOut", null  );
        }
    }

  }
}
</script>

