
import {mutations} from './mutations'
import {actions} from './actions'
import {getters} from "./getters";

const state = {
    
    glassesframelist: [],
    glassesframe_total_count: 0,
    glasseslenslist: [],
    glasseslens_total_count: 0,
    contactlenslist: [],
    contactlens_total_count: 0,
    hearingaidlist: [],
    hearingaid_total_count: 0,
    
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
