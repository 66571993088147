export const mutations = {

    // 안경테 데이터 
    'SET_GLASSFRAMES_LIST' (state, {glassesframelist}) {
      state.glassesframelist = glassesframelist ; 
    },
    'SET_GLASSFRAMES_TOTALCOUNT' (state, {glassesframe_total_count}) {
      state.glassesframe_total_count = glassesframe_total_count ; 
    },

    // 안경렌즈 데이터
    'SET_GLASSLENS_LIST' (state, {glasseslenslist}) {
      state.glasseslenslist = glasseslenslist ; 
    },
    'SET_GLASSLENS_TOTALCOUNT' (state, {glasseslens_total_count}) {
      state.glasseslens_total_count = glasseslens_total_count ; 
    },

    // 콘택트렌즈 데이터
    'SET_CONTACTLENS_LIST' (state, {contactlenslist}) {
        state.contactlenslist = contactlenslist ; 
    },
    'SET_CONTACTLENS_TOTALCOUNT' (state, {contactlens_total_count}) {
        state.contactlens_total_count = contactlens_total_count ; 
    },

    // 보청기 데이터
    'SET_HEARINGAID_LIST' (state, {hearingaidlist}) {
        state.hearingaidlist = hearingaidlist ; 
    },
    'SET_HEARINGAID_TOTALCOUNT' (state, {hearingaid_total_count}) {
        state.hearingaid_total_count = hearingaid_total_count ; 
    },
  
  }