export default {
    0: {
    },
    1: {
        'about': [ 
            'HistoryList', 'HistoryCreate' , 'HistoryEdit',
            'EyesightList', 'EyesightCreate', 'EyesightEdit',
            'BrochureEdit',
        ],
        'partnership': [
            'PartnershipTypeCreate',
            'PartnershipPostList', 'PartnershipPostCreate', 'PartnershipPostEdit', 'PartnershipPostShow' ,
            'PartnershipManagerList', 'PartnershipManagerCreate', 'PartnershipManagerEdit', 'PartnershipManagerShow' ,
        ],
        'whatsnew': [
            'AdContentList', 'AdContenCreate','AdContentEdit','AdContentShow',
            'NewsAndNoticeList', 'NewsAndNoticeCreate', 'NewsAndNoticeEdit', 'NewsAndNoticeShow',
            'EventList', 'EventCreate', 'EventEdit', 'EventShow'
        ],  
        'others':[
            'MainVisualContentsList', 'MainVisualContentsCreate', 'MainVisualContentsEdit', 'MainVisualContentsShow',
            'DavichNumberEdit',
            'PopupList', 'PopupCreate', 'PopupEdit', 'PopupShow',
        ]
    },
    2: {
        'products': [
            'GlassesFrameList', 'GlassesFrameCreate', 'GlassesFrameEdit', 'GlassesFrameShow'
        ],
        'partnership': [
            'PartnershipTypeCreate',
            'PartnershipPostList', 'PartnershipPostCreate', 'PartnershipPostEdit', 'PartnershipPostShow' ,
            'PartnershipManagerList', 'PartnershipManagerCreate', 'PartnershipManagerEdit', 'PartnershipManagerShow' ,
        ],
    },
    3: {
        'products': [
            'GlassesLensList', 'GlassesLensCreate', 'GlassesLensEdit', 'GlassesLensShow'
        ],
        'partnership': [
            'PartnershipTypeCreate',
            'PartnershipPostList', 'PartnershipPostCreate', 'PartnershipPostEdit', 'PartnershipPostShow' ,
            'PartnershipManagerList', 'PartnershipManagerCreate', 'PartnershipManagerEdit', 'PartnershipManagerShow' ,
        ],
    },
    4: {
        'products': [
            'ContactLensList', 'ContactLensCreate', 'ContactLensEdit', 'ContactLensShow'
        ],
        'partnership': [
            'PartnershipTypeCreate',
            'PartnershipPostList', 'PartnershipPostCreate', 'PartnershipPostEdit', 'PartnershipPostShow' ,
            'PartnershipManagerList', 'PartnershipManagerCreate', 'PartnershipManagerEdit', 'PartnershipManagerShow' ,
        ],
    },
    5: {
        'products': [
            'HearingAidList', 'HearingAidCreate', 'HearingAidEdit', 'HearingAidShow'
        ]
    },
    6: {
        'partnership': [
            'StoreList', 'StoreCreate', 'StoreEdit', 'StoreShow' ,
            'StoreOpenAreaList', 'StoreOpenAreaCreate', 'StoreOpenAreaEdit', 'StoreOpenAreaShow' ,
            'StoreOpenInquirePostList', 'StoreOpenInquirePostCreate', 'StoreOpenInquirePostEdit', 'StoreOpenInquirePostShow' ,
            'StoreOpenInquireManagerList', 'StoreOpenInquireManagerCreate', 'StoreOpenInquireManagerEdit', 'StoreOpenInquireManagerShow' ,
            'PartnershipTypeCreate',
            'PartnershipPostList', 'PartnershipPostCreate', 'PartnershipPostEdit', 'PartnershipPostShow' ,
            'PartnershipManagerList', 'PartnershipManagerCreate', 'PartnershipManagerEdit', 'PartnershipManagerShow' ,
        ],
        'others':[
            'DavichNumberEdit',
        ]
    },
    7: {
        'partnership': [
            'PartnershipTypeCreate',
            'PartnershipPostList', 'PartnershipPostCreate', 'PartnershipPostEdit', 'PartnershipPostShow' ,
            'PartnershipManagerList', 'PartnershipManagerCreate', 'PartnershipManagerEdit', 'PartnershipManagerShow' ,
        ],
        'education': [
            'DepartmentList', 'DepartmentCreate', 'DepartmentEdit', 'DepartmentShow' ,
            'GroupList', 'GroupCreate', 'GroupEdit', 'GroupShow' ,
            'SuccessStoryList', 'SuccessStoryCreate', 'SuccessStoryEdit', 'SuccessStoryShow' ,
            'PictureAndVideoList', 'PictureAndVideoCreate', 'PictureAndVideoEdit', 'PictureAndVideoShow' ,
            'OfficerOpenRecruitmentPostList', 'OfficerOpenRecruitmentPostCreate', 'OfficerOpenRecruitmentPostEdit', 'OfficerOpenRecruitmentPostShow' ,
            'OfficerOpenRecruitmentApplyList', 'OfficerOpenRecruitmentApplyCreate', 'OfficerOpenRecruitmentApplyEdit', 'OfficerOpenRecruitmentApplyShow' ,
            'OfficerOpenRecruitmentManagerList', 'OfficerOpenRecruitmentManagerCreate', 'OfficerOpenRecruitmentManagerEdit', 'OfficerOpenRecruitmentManagerShow' ,
        ] , 
        'support': [
            'CustomerVoicePostList', 'CustomerVoicePostCreate', 'CustomerVoicePostEdit', 'CustomerVoicePostShow' ,
            'CustomerVoiceManagerList', 'CustomerVoiceManagerCreate', 'CustomerVoiceManagerEdit', 'CustomerVoiceManagerShow' ,
        ]
    },
    8: {
        'partnership': [
            'PartnershipTypeCreate',
            'PartnershipPostList', 'PartnershipPostCreate', 'PartnershipPostEdit', 'PartnershipPostShow' ,
            'PartnershipManagerList', 'PartnershipManagerCreate', 'PartnershipManagerEdit', 'PartnershipManagerShow' ,
        ],
        'support': [
            'GiftCardList', 'GiftCardCreate', 'GiftCardEdit', 'GiftCardShow' ,
            'GiftCardManagerList', 'GiftCardManagerCreate', 'GiftCardManagerEdit', 'GiftCardManagerShow' ,
        ]
    },
    9: {
        'partnership': [
            'PartnershipTypeCreate',
            'PartnershipPostList', 'PartnershipPostCreate', 'PartnershipPostEdit', 'PartnershipPostShow' ,
            'PartnershipManagerList', 'PartnershipManagerCreate', 'PartnershipManagerEdit', 'PartnershipManagerShow' ,
        ],
    }
  
   
  
}
