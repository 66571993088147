<template>

   <div>
     <nav>
        <ul>
          <li v-bind:class="{ checked: (sideMenu == 'GlassesFrameList' ) }" v-show="IsMySideMenu('GlassesFrameList', getAuthGroup)">    
            <a @click.stop="onSideMenu('GlassesFrameList')">안경테 관리<img src="@/assets/image/icon-arrow.png" alt="icon-arrow"/></a>
          </li>
          <li v-bind:class="{ checked: (sideMenu == 'GlassesLensList' ) }" v-show="IsMySideMenu('GlassesLensList', getAuthGroup)">    
            <a @click.stop="onSideMenu('GlassesLensList')">안경렌즈 관리<img src="@/assets/image/icon-arrow.png" alt="icon-arrow" /></a>
          </li>
          <li v-bind:class="{ checked: (sideMenu == 'ContactLensList' ) }" v-show="IsMySideMenu('ContactLensList', getAuthGroup)">    
            <a @click.stop="onSideMenu('ContactLensList')">콘택트렌즈 관리<img src="@/assets/image/icon-arrow.png" alt="icon-arrow" /></a>
          </li>
          <li v-bind:class="{ checked: (sideMenu == 'HearingAidList' ) }" v-show="IsMySideMenu('HearingAidList', getAuthGroup)">    
            <a @click.stop="onSideMenu('HearingAidList')">보청기 관리<img src="@/assets/image/icon-arrow.png" alt="icon-arrow" /></a>
          </li>
        </ul>
      </nav>
      <router-view />
    </div>

</template>

<script>
import { mapState  } from "vuex";
import { mapGetters} from "vuex";
import menuhaneld  from "../../utils/menuhandle";

export default {
  name: "Procucts",

  data: () => ({
  }),
  computed: {
    ...mapState({
      sideMenu: state => state.sideMenu,
    }),
    ...mapGetters( 'auth', ['getAuthGroup']),
  },
  methods: {
    IsMySideMenu: menuhaneld.IsMySideMenu ,
    onSideMenu(menuname) {
      this.$store.commit('set_side_menu', {sideMenu:menuname}); 
      if( this.$route.name !== menuname ) this.$router.push( {name: menuname });       
    },  
  }
}

</script>

<style></style>
