import axios from '@/api/axios-auth';
import { 
  createProductBrand, 
  reqUpdateProductBrand ,
  removeProductBrandList 
  } from "@/api/davich_api";

import $ from 'jquery'

export const actions = {

    // 안경테 데이터 요청 
    // eslint-disable-next-line no-unused-vars
    async reqGlassesFrameBrands ({ commit } , { page, size ,  searchtext } ) {
      
        console.log('reqGlassesFrameBrands ===== ' , page , ' size ', size ); 
        var baseUrl = '/products/brands/glassesframe'; 

        if( page != null && size != null ) {
            const params = {page:page, size:size}; 
            if( searchtext != null && searchtext != undefined && searchtext.length > 0 ) {
                params.searchtext = searchtext; 
            }
            let queryString = $.param( params );
            if( queryString.length > 0 ) {
                baseUrl += '?' + queryString ;
            }
        }

        return await axios.get(baseUrl)
          .then( (res) => {
  
            console.log('reqGlassesFrameBrands res ', res.data); 
            commit('SET_GLASSFRAMES_LIST', {glassesframelist: res.data} );
            commit('SET_GLASSFRAMES_TOTALCOUNT', {glassesframe_total_count: res.headers['x-total-count'] } );
            return true
          })
          .catch((error) => {
              console.log('action /products/brands/glassesframe error => ', error); 
            throw error
          })
    },



    // 안경렌즈 데이터 요청 
    // eslint-disable-next-line no-unused-vars
    async reqGlassesLensBrands ({ commit } , { page, size , searchtext} ) {
      
        console.log('reqGlassesLensBrands ===== ' , page , ' size ', size ); 
        var baseUrl = '/products/brands/glasseslens'; 

        if( page != null && size != null ) {
            const params = {page:page, size:size}; 
            if( searchtext != null && searchtext != undefined && searchtext.length > 0 ) {
                params.searchtext = searchtext; 
            }
            let queryString = $.param( params );
            if( queryString.length > 0 ) {
                baseUrl += '?' + queryString ;
            }
        }
        return await axios.get(baseUrl)
          .then( (res) => {
  
            console.log('reqGlassesLensBrands res ', res.data); 
            commit('SET_GLASSLENS_LIST', {glasseslenslist: res.data} );
            commit('SET_GLASSLENS_TOTALCOUNT', {glasseslens_total_count: res.headers['x-total-count'] } );
            return true
          })
          .catch((error) => {
              console.log('action /products/brands/glasseslens error => ', error); 
            throw error
          })
    },

    // 콘택트렌즈 데이터 요청 
    // eslint-disable-next-line no-unused-vars
    async reqContactLensBrands ({ commit } , { page, size , searchtext } ) {
      
        console.log('reqContactLensBrands ===== ' , page , ' size ', size ); 
        var baseUrl = '/products/brands/contactlens'; 

        if( page != null && size != null ) {
            const params = {page:page, size:size}; 
            if( searchtext != null && searchtext != undefined && searchtext.length > 0 ) {
                params.searchtext = searchtext; 
            }
            let queryString = $.param( params );
            if( queryString.length > 0 ) {
                baseUrl += '?' + queryString ;
            }
        }

        return await axios.get(baseUrl)
          .then( (res) => {
  
            console.log('reqContactLensBrands res ', res.data); 
            commit('SET_CONTACTLENS_LIST', {contactlenslist: res.data} );
            commit('SET_CONTACTLENS_TOTALCOUNT', {contactlens_total_count: res.headers['x-total-count'] } );
            return true
          })
          .catch((error) => {
              console.log('action /products/brands/contactlens error => ', error); 
            throw error
          })
    },

    // 보청기 데이터 요청 
    // eslint-disable-next-line no-unused-vars
    async reqHearingAidBrands ({ commit } , { page, size , searchtext } ) {
      
        console.log('reqHearingAidBrands ===== ' , page , ' size ', size ); 

        var baseUrl = '/products/brands/hearingaid'; 

        if( page != null && size != null ) {
            const params = {page:page, size:size}; 
            if( searchtext != null && searchtext != undefined && searchtext.length > 0 ) {
                params.searchtext = searchtext; 
            }
            let queryString = $.param( params );
            if( queryString.length > 0 ) {
                baseUrl += '?' + queryString ;
            }
        }

        return await axios.get(baseUrl)
          .then( (res) => {
  
            console.log('reqHearingAidBrands res ', res.data); 
            
            commit('SET_HEARINGAID_LIST', {hearingaidlist: res.data} );
            commit('SET_HEARINGAID_TOTALCOUNT', {hearingaid_total_count: res.headers['x-total-count'] } );
            return true
          })
          .catch((error) => {
              console.log('action /products/brands/hearingaid error => ', error); 
            throw error
          })
    },


    // 브랜드 데이터 생성 요청. - 통합. ( 카테고리 다른 경우 product_id 를 달리 줌)
    // eslint-disable-next-line no-unused-vars
    create_product_brand ( { commit } , payload ) {
        
        createProductBrand(payload)
          .then((res) => {
            console.log('res => ', res.data ); 
          })
          .catch( (err) => { 
            
            console.log(err) ; 

          });
    }, 

      // eslint-disable-next-line no-unused-vars
    req_update_product_brand( { commit } ,  payload  ) {
        console.log('req_update_product_brand payload => ', payload ); 
        return new Promise(( resolve, reject ) => {
          reqUpdateProductBrand(payload).then((res) => {
                // console.log('req_update_product_brand res => ',res ); 
                resolve( res.data  );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    },

      
    // eslint-disable-next-line no-unused-vars
    remove_product_brand_list ( { commit } , payload ) {

      console.log('payload => ', payload );
      removeProductBrandList( payload )
        .then((res) => {

          console.log('res => ', res.data ); 

          if( payload.product_id == 1 ) {
            // 안경테 
            commit('SET_GLASSFRAMES_LIST', {glassesframelist: res.data} );
            commit('SET_GLASSFRAMES_TOTALCOUNT', {glassesframe_total_count: res.headers['x-total-count'] } );
          }
          else if( payload.product_id == 2 ) {
            // 안경렌즈 
            commit('SET_GLASSLENS_LIST', {glasseslenslist: res.data} );
            commit('SET_GLASSLENS_TOTALCOUNT', {glasseslens_total_count: res.headers['x-total-count'] } );
          }
          else if( payload.product_id == 3 ) {
            // 콘택트렌즈
            commit('SET_CONTACTLENS_LIST', {contactlenslist: res.data} );
            commit('SET_CONTACTLENS_TOTALCOUNT', {contactlens_total_count: res.headers['x-total-count'] } );
          }
          else if( payload.product_id == 4 ) {
            // 보청기
            commit('SET_HEARINGAID_LIST', {hearingaidlist: res.data} );
            commit('SET_HEARINGAID_TOTALCOUNT', {hearingaid_total_count: res.headers['x-total-count'] } );
          }

        })
        .catch( (err) => { 
          
          console.log(err) ; 

        });
    },



}