<template>
  <div class="login-wrap">
    <div class="login">
      <div class="logo">
        <h1>
          <img src="@/assets/image/davich-logo-w.png" alt="davich logo" />
        </h1>
      </div>
      <div class="login-cont">
        <div class="login-cont-inner">
          <!------------------------------------------ 1. 관리자로그인------------------------------------------>
          <div class="login-main" v-if="!isIDPWAuthenticated">
            <h2>관리자 로그인</h2>
            <div class="login-form">
              <p>
                <label for="admin-id">
                  <img
                    src="@/assets/image/login/icon-user-circle.png"
                    alt="davich logo"
                /></label>
                <input
                  id="admin-id"
                  type="text"
                  title="ID"
                  v-model="adminId"
                  placeholder="아이디를 입력하세요."
                />
              </p>
              <p>
                <label for="admin-pw">
                  <img
                    src="@/assets/image/login/icon-lock.png"
                    alt="davich logo"
                /></label>
                <input
                  id="admin-pw"
                  type="password"
                  title="password"
                  v-model="adminPw"
                  placeholder="비밀번호를 입력하세요."
                  v-on:keyup.enter="onButtonLogin"
                />
              </p>
              <button type="submit" @click="onButtonLogin">로그인</button>
            </div>
          </div>
          <!------------------------------------------ 1. 관리자로그인 end ------------------------------------------>

          <!------------------------------------------ 2. 로그인 2차 인증 번호 발송폼------------------------------------------>
          <div
            class="login-secondary"
            v-else-if="isIDPWAuthenticated && !authnumberRequested"
          >
            <h2>로그인 2차 인증</h2>
            <div class="login-form">
              <p>로그인을 위해 이메일 인증이 필요합니다.</p>
              <label for="login-email"
                ><input
                  type="text"
                  name="login-email"
                  id="login-email"
                  v-model="emailfirst"
                />@davich.com</label
              >
              <button @click="onButtonRequestAuthNumber">인증번호 발송</button>
              <a @click="backToLogin">로그인 화면으로 돌아가기</a>
            </div>
          </div>
          <!------------------------------------------ 2. 로그인 2차 인증 번호 발송폼 end ------------------------------------------>

          <!------------------------------------------ 3. 로그인 2차 인증 번호 입력폼------------------------------------------>
          <div class="login-secondary-2" v-else>
            <h2>로그인 2차 인증</h2>
            <div class="login-form-num">
              <p v-if="!auth_input_timeout">
                이메일로 인증번호가 발송되었습니다.&nbsp;<simple-timer-counter :minutes="3" :seconds="0" @onTimeOut="onTimeOut" ></simple-timer-counter>
              </p>
              <p v-else>
                유효시간이 만료되었습니다. 인증번호를 재발급 받아주세요.&nbsp;
              </p>

              <label for="login-number"></label
              ><input
                type="number"
                name="login-number"
                id="login-number"
                placeholder="인증번호를 입력해주세요."
                v-model="input_auth_number"
                :disabled="auth_input_timeout"
              />
              <button @click="onButtonCheckAuthNumber" v-if="!auth_input_timeout">인증번호 확인</button>
              <button @click="onButtonRerequestAuthNumber" v-else>인증번호 재발급</button>

              <a @click="backToLogin">로그인 화면으로 돌아가기</a>
            </div>
          </div>
          <!------------------------------------------ 3. 로그인 2차 인증 번호 입력폼 end------------------------------------------>

          <!------------------------------------------ 4. 로그인 2차 인증 번호 재발급 입력폼------------------------------------------>
          <!-- <div class="login-secondary-3">
            <h2>로그인 2차 인증</h2>
            <div class="login-form-num">
              <p>유효시간이 만료되었습니다. 인증번호를 재발급 받아주세요.</p>
              <label for="login-number"></label
              ><input
                type="number"
                name="login-number"
                id="login-number"
                placeholder="인증번호를 입력해주세요."
              />
              <button>인증번호 재발급</button>
              <a @click="backToLogin">로그인 화면으로 돌아가기</a>
            </div>
          </div> -->
          <!------------------------------------------ 4. 로그인 2차 인증 번호 재발급 입력폼 end------------------------------------------>
        </div>
      </div>
    </div>
    <app-footer />
  </div>
</template>

<script>
import AppFooter from "./AppFooter.vue";
import { mapActions, mapGetters } from "vuex";
import menuHandle from "../utils/menuhandle";
import SimpleTimerCounter from "./SimpleTimerCounter.vue"; 

export default {
  components: { AppFooter , SimpleTimerCounter },
  name: "Login",
  data() {
    return {
      adminId: "",
      adminPw: "",
      idpwAuthOK: false,
      authnumberRequested: false,

      emailfirst: '',
      user_email: '', 
      inputnumber: null , 
      auth_input_timeout: false,

      loginResult: {},
      input_auth_number:'', 
      authNumberRet: null ,
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters("auth", ["getAuthGroup"]),
    isIDPWAuthenticated() {
      return this.idpwAuthOK;
    },
  },
  methods: {
    ...mapActions("auth", ["reqLogin"]),
    onTimeOut() {
      // 인증번호 입력 유효시간 경과         
      return this.$fire({
          title: "입력 유효시간이 경과하였습니다.",
          text: "",
          type: "warning",
          timer: 2000,
        }).then((r) => {
          console.log(r.value);
          this.auth_input_timeout = true ; 
        });
    }, 
    onButtonCheckAuthNumber() {
      console.log("인증번호 확인 => ", this.input_auth_number );
      if (this.input_auth_number.length < 6) {
        return this.$fire({
          title: "인증번호를 정확히 입력해주세요",
          text: "",
          type: "warning",
          timer: 1000,
        }).then(r => {
          console.log(r.value);
        });
      } else {
        // 인증번호 비교요청.
         
        const payload = {
          "email": this.authNumberRet.receiver_email ,
          "authnumber_id": this.authNumberRet.id ,
          "input_data": this.input_auth_number 
        }

        this.$store
          .dispatch("auth/req_check_2fa_authnumber", payload)
          .then((result) => {
            console.log("req_check_2fa_authnumber result => ", result);

            this.$fire({
              title: "인증번호가 일치합니다.",
              text: "",
              type: "success",
              timer: 2000,
            }).then( () => {

              console.log( ' this.loginResult => ', this.loginResult );

              // 2FA 로그인 성공 
              let data = this.loginResult ; 
              this.$store.commit('auth/SET_USER_INFO', {adminId: this.adminId , name: data.name , email: data.email } ) ;
              this.$store.commit('auth/SET_ACCESS_TOKEN', {accessToken: data.access_token} ) ;
              this.$store.commit('auth/SET_REFRESH_TOKEN', {refreshToken: data.refresh_token} ) ;
              this.$store.commit('auth/SET_IS_AUTH', {is_auth: true} ) ;
              this.$store.commit('auth/SET_AUTH_GROUP', {auth_group: data.auth_group }) ;


              //
              var myAuthGroup = this.getAuthGroup;
              console.log("myAuthGroup ==> ", myAuthGroup);
              if (myAuthGroup == 0) {
                // all menu show
                // console.log("myTopMenuList is All ");
                this.$router.push({ name: "About" });
              } else if (myAuthGroup == 99) {
                // console.log("myTopMenuList is nothing ");
                this.$router.push({ name: "Login" });
              } else {
                var defaultTopMenuName =
                  menuHandle.getAuthorizedDefaultTopMenuName(myAuthGroup);
                var defaultCompName =
                  menuHandle.getAuthorizedDefaultSideMenuComponentName(myAuthGroup);
                this.$store.commit("set_top_menu",  { topMenu: defaultTopMenuName });
                this.$store.commit("set_side_menu", { sideMenu: defaultCompName });
                this.$router.push({ name: defaultCompName });
              }

            });
          })
          .catch((err) => {
            // 인증번호 불일치시 
            if( err.response.status == 401 ) {
              // 인증번호 불일치
              this.$fire({
                title: "인증번호가 일치하지 않습니다.",
                text: "인증번호를 다시 확인후 입력해주세요.",
                type: "error",
                timer: 2000,
              }).then( () => {
                // console.log(r.value);
              });
            }

            console.log(" err => ", err);
          });
      }

    },
    onButtonRerequestAuthNumber() {
      console.log("인증번호 재발급 요청");

      const payload = {
        email: this.user_email
      };
      this.$store
        .dispatch("auth/req_authnumber_send_to_mail", payload)
        .then((result) => {
          console.log("req_authnumber_send_to_mail result => ", result);
          this.authNumberRet = result.data ; 

          this.$fire({
            title: "인증번호를 발송하였습니다.",
            text: "",
            type: "info",
            timer: 1000,
          }).then( () => {
            // console.log(r.value);
            this.authnumberRequested = true;
            this.auth_input_timeout = false ; 
          });
        })
        .catch((err) => {
          this.authnumberRequested = false ;
          console.log(" error => ", err);
        });
      
    },
    onButtonRequestAuthNumber() {
      console.log("인증번호 발송 시도");
      console.log("emailfirst =>", this.emailfirst);
      if (this.emailfirst.length < 2) {
        return this.$fire({
          title: "이메일 주소를 입력해주세요",
          text: "",
          type: "warning",
          timer: 1000,
        }).then(r => {
          console.log(r.value);
        });
      } else {

        // 발송요청한다.
        if( this.emailfirst == 'intervision' ) {
          this.user_email = 'help@fittingmonster.com' ; //  
        }
        else {
          this.user_email = this.emailfirst + '@davich.com'; 
        }

        const payload = {
          email: this.user_email
        };
        this.$store
          .dispatch("auth/req_authnumber_send_to_mail", payload)
          .then((result) => {
            console.log("req_authnumber_send_to_mail result => ", result);
            this.authNumberRet = result.data ; 

            this.$fire({
              title: "인증번호를 발송하였습니다.",
              text: "",
              type: "info",
              timer: 1000,
            }).then( () => {
              // console.log(r.value);
              this.authnumberRequested = true;
            });
          })
          .catch((err) => {
            this.authnumberRequested = false ;
            console.log(" error => ", err);
          });

      }
    },
    async onButtonLogin() {
      console.log("로그인 시도");
      try {
        let loginResult = await this.reqLogin({
          adminId: this.adminId,
          adminPw: this.adminPw,
        });

        // id/pw 로그인 성공시 1단계 인증 완료
        if (loginResult) {
          this.loginResult = loginResult; 
          // 1단계 인증 성공 
          this.idpwAuthOK = true ; 
        }

        // if (loginResult) {
        //   // console.log("loginResult ==> ", loginResult); // 로그인 성공하면 true, 아니면 false
        //   console.log("로그인 성공");
        //   var myAuthGroup = this.getAuthGroup;
        //   console.log("myAuthGroup ==> ", myAuthGroup);
        //   if (myAuthGroup == 0) {
        //     // all menu show
        //     // console.log("myTopMenuList is All ");
        //     this.$router.push({ name: "About" });
        //   } else if (myAuthGroup == 99) {
        //     // console.log("myTopMenuList is nothing ");
        //     this.$router.push({ name: "Login" });
        //   } else {
        //     var defaultTopMenuName =
        //       menuHandle.getAuthorizedDefaultTopMenuName(myAuthGroup);
        //     var defaultCompName =
        //       menuHandle.getAuthorizedDefaultSideMenuComponentName(myAuthGroup);
        //     this.$store.commit("set_top_menu",  { topMenu: defaultTopMenuName });
        //     this.$store.commit("set_side_menu", { sideMenu: defaultCompName });
        //     this.$router.push({ name: defaultCompName });
        //   }
        // }


      } catch (err) {
        // console.error('login failed => ');
        // console.error('login failed => err ', err.response);
        console.error(err);
        let response = err.response;
        this.$alert("로그인 실패!!! ", response.data.message);
      }
    },
    backToLogin() {
      this.$router.go(0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/helpers/variables";
@import "../assets/scss/helpers/mixins";
@import "../assets/scss/base/typhography";

.login-wrap {
  width: 100%;
  height: 100vh;
  background-color: #edeff5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .login {
    width: calc(100% - 200px);
    height: calc(100vh - 200px);
    display: flex;
    .logo,
    .login-cont {
      position: relative;
      width: 50%;
      height: 100%;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    //left
    .logo {
      background-color: $color-darkblue;
      h1 {
        width: 278.1px;
        height: 61px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    //right
    .login-cont-inner {
      .login-main,
      .login-secondary,
      .login-secondary-2,
      .login-secondary-3 {
        width: 400px;
        h2 {
          text-align: center;
          font-size: 25px;
          line-height: 45px;
          letter-spacing: -0.75px;
          font-size: 25px;
          font-weight: 500;
          margin-bottom: 60px;
        }
        button {
          margin-top: 30px;
          display: block;
          width: 100%;
          height: 50px;
          border: 1px solid $color-black;
          font-size: 17px;
          text-align: center;
          line-height: 50px;
        }
      }
      .login-main {
        .login-form {
          p {
            width: 400px;
            height: 50px;
            position: relative;
            &:first-child {
              margin-bottom: 20px;
            }
            label {
              position: absolute;
              width: 24px;
              height: 24px;
              top: 13px;
              left: 19px;
              img {
                @include img;
              }
            }
            input {
              display: block;
              width: 400px;
              height: 50px;
              border: 1px solid #dedede;
              padding-left: 67px;
              font-size: 17px;
              &::placeholder {
                font-size: 17px;
                color: #ccc;
              }
            }
          }
        }
      }

      .login-secondary,
      .login-secondary-2,
      .login-secondary-3 {
        h2 {
          margin-bottom: 15px;
        }
        p {
          margin-bottom: 20px;
          text-align: center;
        }
        label {
          color: #dedede;
          font-size: 17px;
        }
        input,
        input::placeholder {
          font-size: 17px;
          color: #ccc;
        }
        input {
          width: 267px;
          height: 50px;
          padding-left: 10px;
          border: 1px solid #dedede;
          box-sizing: border-box;
          margin-right: 10px;
          color: black;
        }
        a {
          position: absolute;
          bottom: 65px;
          text-align: center;
          left: 50%;
          transform: translateX(-50%);
          font-size: 13px;
          color: #777;
          text-decoration: underline;
        }
      }

      .login-secondary-2,
      .login-secondary-3 {
        .login-form-num {
          p {
            color: #ff4141;
            span {
              color: #0056aa;
            }
          }
          input {
            display: block;
            width: 400px;
          }
          input:focus::placeholder {
            color: transparent;
          }
        }
      }
    }
  }
  .copyright {
    bottom: 40px;
  }
}
</style>
