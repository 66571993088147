
import {mutations} from './mutations'
import {actions} from './actions'
import {getters} from "./getters";

const state = {
    adminId: '' ,
    name: '',
    email: '',
    isAuth: false,
    accessToken: '',
    auth_group: 99,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
