const DepartmentCreate  = () => import(/* webpackChunkName: "education" */ '../components/education/department/DepartmentCreate.vue');
const DepartmentEdit    = () => import(/* webpackChunkName: "education" */ '../components/education/department/DepartmentCreate.vue');
const DepartmentList    = () => import(/* webpackChunkName: "education" */ '../components/education/department/DepartmentList.vue');
const DepartmentShow    = () => import(/* webpackChunkName: "education" */ '../components/education/department/DepartmentShow.vue');

// import DepartmentCreate from "../components/education/department/DepartmentCreate.vue";
// import DepartmentEdit from "../components/education/department/DepartmentCreate.vue";
// import DepartmentList from "../components/education/department/DepartmentList.vue";
// import DepartmentShow from "../components/education/department/DepartmentShow.vue";


const GroupCreate  = () => import(/* webpackChunkName: "education" */ '../components/education/group/GroupCreate.vue');
const GroupEdit    = () => import(/* webpackChunkName: "education" */ '../components/education/group/GroupEdit.vue');
const GroupList    = () => import(/* webpackChunkName: "education" */ '../components/education/group/GroupList.vue');
const GroupShow    = () => import(/* webpackChunkName: "education" */ '../components/education/group/GroupShow.vue');

// import GroupCreate from "../components/education/group/GroupCreate.vue";
// import GroupEdit from "../components/education/group/GroupEdit.vue";
// import GroupList from "../components/education/group/GroupList.vue";
// import GroupShow from "../components/education/group/GroupShow.vue";

const SuccessStoryCreate  = () => import(/* webpackChunkName: "education" */ '../components/education/successstory/SuccessStoryCreate.vue');
const SuccessStoryEdit    = () => import(/* webpackChunkName: "education" */ '../components/education/successstory/SuccessStoryEdit.vue');
const SuccessStoryList    = () => import(/* webpackChunkName: "education" */ '../components/education/successstory/SuccessStoryList.vue');
const SuccessStoryShow    = () => import(/* webpackChunkName: "education" */ '../components/education/successstory/SuccessStoryShow.vue');

// import SuccessStoryCreate from "../components/education/successstory/SuccessStoryCreate.vue";
// import SuccessStoryEdit from "../components/education/successstory/SuccessStoryEdit.vue";
// import SuccessStoryList from "../components/education/successstory/SuccessStoryList.vue";
// import SuccessStoryShow from "../components/education/successstory/SuccessStoryShow.vue";


const PictureAndVideoCreate  = () => import(/* webpackChunkName: "education" */ '../components/education/pictureandvideo/PictureAndVideoCreate.vue');
const PictureAndVideoEdit    = () => import(/* webpackChunkName: "education" */ '../components/education/pictureandvideo/PictureAndVideoEdit.vue');
const PictureAndVideoList    = () => import(/* webpackChunkName: "education" */ '../components/education/pictureandvideo/PictureAndVideoList.vue');
const PictureAndVideoShow    = () => import(/* webpackChunkName: "education" */ '../components/education/pictureandvideo/PictureAndVideoShow.vue');

// import PictureAndVideoCreate from "../components/education/pictureandvideo/PictureAndVideoCreate.vue";
// import PictureAndVideoEdit from "../components/education/pictureandvideo/PictureAndVideoEdit.vue";
// import PictureAndVideoList from "../components/education/pictureandvideo/PictureAndVideoList.vue";
// import PictureAndVideoShow from "../components/education/pictureandvideo/PictureAndVideoShow.vue";

const OfficerOpenRecruitmentPostCreate  = () => import(/* webpackChunkName: "education" */ '../components/education/officeropenrecruitmentpost/OfficerOpenRecruitmentPostCreate.vue');
const OfficerOpenRecruitmentPostEdit    = () => import(/* webpackChunkName: "education" */ '../components/education/officeropenrecruitmentpost/OfficerOpenRecruitmentPostEdit.vue');
const OfficerOpenRecruitmentPostList    = () => import(/* webpackChunkName: "education" */ '../components/education/officeropenrecruitmentpost/OfficerOpenRecruitmentPostList.vue');
const OfficerOpenRecruitmentPostShow    = () => import(/* webpackChunkName: "education" */ '../components/education/officeropenrecruitmentpost/OfficerOpenRecruitmentPostShow.vue');

// import OfficerOpenRecruitmentPostCreate from "../components/education/officeropenrecruitmentpost/OfficerOpenRecruitmentPostCreate.vue";
// import OfficerOpenRecruitmentPostEdit from "../components/education/officeropenrecruitmentpost/OfficerOpenRecruitmentPostEdit.vue";
// import OfficerOpenRecruitmentPostList from "../components/education/officeropenrecruitmentpost/OfficerOpenRecruitmentPostList.vue";
// import OfficerOpenRecruitmentPostShow from "../components/education/officeropenrecruitmentpost/OfficerOpenRecruitmentPostShow.vue";

const OfficerOpenRecruitmentApplyCreate  = () => import(/* webpackChunkName: "education" */ '../components/education/officeropenrecruitmentapply/OfficerOpenRecruitmentApplyCreate.vue');
const OfficerOpenRecruitmentApplyEdit    = () => import(/* webpackChunkName: "education" */ '../components/education/officeropenrecruitmentapply/OfficerOpenRecruitmentApplyEdit.vue');
const OfficerOpenRecruitmentApplyList    = () => import(/* webpackChunkName: "education" */ '../components/education/officeropenrecruitmentapply/OfficerOpenRecruitmentApplyList.vue');
const OfficerOpenRecruitmentApplyShow    = () => import(/* webpackChunkName: "education" */ '../components/education/officeropenrecruitmentapply/OfficerOpenRecruitmentApplyShow.vue');

// import OfficerOpenRecruitmentApplyCreate from "../components/education/officeropenrecruitmentapply/OfficerOpenRecruitmentApplyCreate.vue";
// import OfficerOpenRecruitmentApplyEdit from "../components/education/officeropenrecruitmentapply/OfficerOpenRecruitmentApplyEdit.vue";
// import OfficerOpenRecruitmentApplyList from "../components/education/officeropenrecruitmentapply/OfficerOpenRecruitmentApplyList.vue";
// import OfficerOpenRecruitmentApplyShow from "../components/education/officeropenrecruitmentapply/OfficerOpenRecruitmentApplyShow.vue";

const OfficerOpenRecruitmentManagerCreate  = () => import(/* webpackChunkName: "education" */ '../components/education/officeropenrecruitmentmanager/OfficerOpenRecruitmentManagerCreate.vue');
const OfficerOpenRecruitmentManagerEdit    = () => import(/* webpackChunkName: "education" */ '../components/education/officeropenrecruitmentmanager/OfficerOpenRecruitmentManagerEdit.vue');
const OfficerOpenRecruitmentManagerList    = () => import(/* webpackChunkName: "education" */ '../components/education/officeropenrecruitmentmanager/OfficerOpenRecruitmentManagerList.vue');
const OfficerOpenRecruitmentManagerShow    = () => import(/* webpackChunkName: "education" */ '../components/education/officeropenrecruitmentmanager/OfficerOpenRecruitmentManagerShow.vue');

// import OfficerOpenRecruitmentManagerCreate from "../components/education/officeropenrecruitmentmanager/OfficerOpenRecruitmentManagerCreate.vue";
// import OfficerOpenRecruitmentManagerEdit from "../components/education/officeropenrecruitmentmanager/OfficerOpenRecruitmentManagerEdit.vue";
// import OfficerOpenRecruitmentManagerList from "../components/education/officeropenrecruitmentmanager/OfficerOpenRecruitmentManagerList.vue";
// import OfficerOpenRecruitmentManagerShow from "../components/education/officeropenrecruitmentmanager/OfficerOpenRecruitmentManagerShow.vue";

export default [
  // 부서관리
  {
    path: "/education/department/create",
    name: "DepartmentCreate",
    component: DepartmentCreate,
  },
  {
    path: "/education/department/edit",
    name: "DepartmentEdit",
    component: DepartmentEdit,
    props: true
  },
  {
    path: "/education/department/list",
    name: "DepartmentList",
    component: DepartmentList,
  },
  {
    path: "/education/department/show",
    name: "DepartmentShow",
    component: DepartmentShow,
    props: true
  },

  // 조직관리
  {
    path: "/education/group/create",
    name: "GroupCreate",
    component: GroupCreate,
  },
  {
    path: "/education/group/edit",
    name: "GroupEdit",
    component: GroupEdit,
    props: true
  },
  {
    path: "/education/group/list",
    name: "GroupList",
    component: GroupList,
  },
  {
    path: "/education/group/show",
    name: "GroupShow",
    component: GroupShow,
    props: true
  },

  // 성공스토리
  {
    path: "/education/successstory/create",
    name: "SuccessStoryCreate",
    component: SuccessStoryCreate,
  },
  {
    path: "/education/successstory/edit",
    name: "SuccessStoryEdit",
    component: SuccessStoryEdit,
    props: true
  },
  {
    path: "/education/successstory/list",
    name: "SuccessStoryList",
    component: SuccessStoryList,
  },
  {
    path: "/education/successstory/show",
    name: "SuccessStoryShow",
    component: SuccessStoryShow,
    props: true
  },

  // 사진및졸업동영상관리
  {
    path: "/education/pictureandvideo/create",
    name: "PictureAndVideoCreate",
    component: PictureAndVideoCreate,
  },
  {
    path: "/education/pictureandvideo/edit",
    name: "PictureAndVideoEdit",
    component: PictureAndVideoEdit,
    props: true
  },
  {
    path: "/education/pictureandvideo/list",
    name: "PictureAndVideoList",
    component: PictureAndVideoList,
  },
  {
    path: "/education/pictureandvideo/show",
    name: "PictureAndVideoShow",
    component: PictureAndVideoShow,
    props: true
  },

  // 사관 공채 공고 관리
  {
    path: "/education/officeropenrecruitmentpost/create",
    name: "OfficerOpenRecruitmentPostCreate",
    component: OfficerOpenRecruitmentPostCreate,
  },
  {
    path: "/education/officeropenrecruitmentpost/edit",
    name: "OfficerOpenRecruitmentPostEdit",
    component: OfficerOpenRecruitmentPostEdit,
    props: true
  },
  {
    path: "/education/officeropenrecruitmentpost/list",
    name: "OfficerOpenRecruitmentPostList",
    component: OfficerOpenRecruitmentPostList,
  },
  {
    path: "/education/officeropenrecruitmentpost/show",
    name: "OfficerOpenRecruitmentPostShow",
    component: OfficerOpenRecruitmentPostShow,
    props: true
  },

  // 사관 공채 지원 관리
  {
    path: "/education/officeropenrecruitmentapply/create",
    name: "OfficerOpenRecruitmentApplyCreate",
    component: OfficerOpenRecruitmentApplyCreate,
  },
  {
    path: "/education/officeropenrecruitmentapply/edit",
    name: "OfficerOpenRecruitmentApplyEdit",
    component: OfficerOpenRecruitmentApplyEdit,
    props: true
  },
  {
    path: "/education/officeropenrecruitmentapply/list",
    name: "OfficerOpenRecruitmentApplyList",
    component: OfficerOpenRecruitmentApplyList,
  },
  {
    path: "/education/officeropenrecruitmentapply/show",
    name: "OfficerOpenRecruitmentApplyShow",
    component: OfficerOpenRecruitmentApplyShow,
    props: true
  },

  // 사관 공채 지원 담당자 관리
  {
    path: "/education/officeropenrecruitmentmanager/create",
    name: "OfficerOpenRecruitmentManagerCreate",
    component: OfficerOpenRecruitmentManagerCreate,
  },
  {
    path: "/education/officeropenrecruitmentmanager/edit",
    name: "OfficerOpenRecruitmentManagerEdit",
    component: OfficerOpenRecruitmentManagerEdit,
    props: true
  },
  {
    path: "/education/officeropenrecruitmentmanager/list",
    name: "OfficerOpenRecruitmentManagerList",
    component: OfficerOpenRecruitmentManagerList,
  },
  {
    path: "/education/officeropenrecruitmentmanager/show",
    name: "OfficerOpenRecruitmentManagerShow",
    component: OfficerOpenRecruitmentManagerShow,
    props: true
  },
];
