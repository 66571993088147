export const getters = {

    getFranchiseCount:state => {
        return state.franchise_count;
    },
    getGraduateCount:state => {
        return state.graduate_count;
    },
    getGlassesGiveCount:state => {
        return state.glasses_give_count;
    },
    getGustomerCount:state => {
        return state.customer_count;
    },
    getVolunteerCount:state => {
        return state.volunteer_count;
    },
}
