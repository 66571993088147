import axios from '@/api/axios-auth';
import $ from 'jquery'

import {
  reqUpdateEyesight
} from "@/api/davich_api";

export const actions = {


  // ======================================================================== 
  // - About 
  // - 연혁관리 ( /about/history )
  // ======================================================================== 
  
  // 생성 
  // eslint-disable-next-line no-unused-vars
  createHistory ({commit}, { yyyy, description } ) {
    var baseUrl = '/about/history'; 
    return axios.post(baseUrl, {yyyy: yyyy, description: description});
  },
  
  reqHistoryList ({ commit } ,  payload ) {
      
    var apiUrl = '/about/history'; 

    var params = {}; 
    if( payload.page != null && payload.size != null ) {
      params.page = payload.page ;
      params.size =  payload.size ;   
    }
    if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
      params.searchtext = payload.searchtext; 
    }       
  
    let queryString = $.param( params );
    if( queryString.length > 0 ) {
      apiUrl += '?' + queryString ;
    }

    return new Promise(( resolve, reject ) => {

        axios.get(apiUrl)
        .then( (res) => {
          console.log('history res ', res.data); 
          commit('SET_HISTORY_LIST', {historylist: res.data} );
          commit('SET_HISTORY_TOTALCOUNT', {history_total_count: res.headers['x-total-count'] } );

          resolve({ data: res.data , total_count:res.headers['x-total-count']} );
          return true
        })
        .catch((error) => {
            console.log('action /about/history error => ', error); 
            reject(error); 
          throw error
        });


      });
  },

  // 업데이트 
  // eslint-disable-next-line no-unused-vars
  updateHistory ({ commit } , { id, yyyy , description} ) {
      
      var baseUrl = '/about/history'; 
      var reqUrl = baseUrl + '/' + id ; 
      return axios.patch(reqUrl , {yyyy: yyyy, description: description});
        
  },

    async removeHistoryList ({ commit } , { idarr } ) {
      
      var baseUrl = '/about/history'; 
      return await axios.delete(baseUrl, { data: { "idarr": idarr }} )
        .then( (res) => {

          console.log('history res ', res.data); 
          commit('SET_HISTORY_LIST', {historylist: res.data} );
          commit('SET_HISTORY_TOTALCOUNT', {history_total_count: res.headers['x-total-count'] } );
          return true
        })
        .catch((error) => {
            console.log('action /about/history error => ', error); 
          throw error
        })
    },

  // ======================================================================== 
  // - About 
  // - 눈건강 시력지킴이 게시판  ( /about/eyesight )
  // ======================================================================== 

  async reqEyesightList ({ commit } , payload ) {
    
    var apiUrl = '/about/eyesight'; 

    var params = {}; 
    if( payload.page != null && payload.size != null ) {
      params.page = payload.page ;
      params.size =  payload.size ;   
    }
    if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
      params.searchtext = payload.searchtext; 
    }       
  
    let queryString = $.param( params );
    if( queryString.length > 0 ) { 
      apiUrl += '?' + queryString ;
    }

    return await axios.get(apiUrl)
      .then( (res) => {
        console.log('eyesight res ', res.data); 
        console.log('eyesight res.headers[x-total-count] ', res.headers['x-total-count']) ; 

        commit('SET_EYESIGHT_LIST', {eyesightlist: res.data} );
        commit('SET_EYESIGHT_TOTALCOUNT', {eyesight_total_count: res.headers['x-total-count'] } );
        return true
      })
      .catch((error) => {
          console.log('action /about/eyesight error => ', error); 
        throw error
      })
  },
  
  // eslint-disable-next-line no-unused-vars
  req_update_eyesight  ( { commit } ,  payload  ) {
    console.log('payload => ', payload ); 
    return new Promise(( resolve, reject ) => {
      reqUpdateEyesight( payload ).then( (res) => {
            console.log('req_update_eyesight res => ', res.data ); 
            resolve( res.data );
        }).catch( (err) => { 
            console.log(err) ;    
            reject(err); 
        });
    });

  },

  async removeEyesightList ({ commit } , { idarr } ) {
      
    console.log('removeEyesightList ===== ' , idarr ); 
    var baseUrl = '/about/eyesight'; 

    return await axios.delete(baseUrl, { data : {"idarr": idarr }})
      .then( (res) => {

        console.log('eyesight res ', res.data); 
        // res.status 
        commit('SET_EYESIGHT_LIST', {eyesightlist: res.data} );
        commit('SET_EYESIGHT_TOTALCOUNT', {eyesight_total_count: res.headers['x-total-count'] } );
        return true
      })
      .catch((error) => {
          console.log('action /about/eyesight error => ', error); 
        throw error
      })
  },

  // ======================================================================== 
  // - About 
  // - 브로슈어  ( /about/brochure )
  // ======================================================================== 

  // eslint-disable-next-line no-unused-vars
  req_brochure({ commit } , payload ) {
    console.log('req_brochure ===== '  ); 
    var baseUrl = '/about/brochure'; 
    return axios.get(baseUrl) ;

  },



}