<template>
  <div>
    <nav>
      <ul>
        <li v-bind:class="{ checked: sideMenu == 'AdContentList' }" v-show="IsMySideMenu('AdContentList', getAuthGroup)">
          <a @click.stop="onSideMenu('AdContentList')"
            >광고/홍보영상 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
        <li v-bind:class="{ checked: sideMenu == 'NewsAndNoticeList' }" v-show="IsMySideMenu('NewsAndNoticeList', getAuthGroup)">
          <a @click.stop="onSideMenu('NewsAndNoticeList')"
            >다비치 소식(공지/뉴스)<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
        <li v-bind:class="{ checked: sideMenu == 'EventList' }" v-show="IsMySideMenu('EventList', getAuthGroup)">
          <a @click.stop="onSideMenu('EventList')"
            >이벤트 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
      </ul>
    </nav>
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters} from "vuex";
import menuhaneld  from "../../utils/menuhandle";

export default {
  name: "Whatsnew",
  data: () => ({}),
  computed: {
    ...mapState({
      sideMenu: (state) => state.sideMenu,
    }),
    ...mapGetters( 'auth', ['getAuthGroup']),
  },
  methods: {
    IsMySideMenu: menuhaneld.IsMySideMenu ,
    onSideMenu(menuname) {
      this.$store.commit("set_side_menu", { sideMenu: menuname });
      if (this.$route.name !== menuname) this.$router.push({ name: menuname });
    },
  },
};
</script>

<style></style>
