export const mutations = {

    'SET_DAVICH_NUMBERS' (state, {franchise_count,graduate_count,glasses_give_count,customer_count, volunteer_count}) {
        state.franchise_count = franchise_count ;
        state.graduate_count = graduate_count;
        state.glasses_give_count =  glasses_give_count;
        state.customer_count = customer_count;
        state.volunteer_count = volunteer_count; 
    },
 
    'SET_FRANCHISE_COUNT' (state, {franchise_count}) {
        state.franchise_count = franchise_count ;
    },
    'SET_GRADUATE_COUNT' (state, {graduate_count}) {
        state.graduate_count = graduate_count;
    },
    'SET_GLASSES_COUNT' (state, {glasses_give_count}) {
        state.glasses_give_count =  glasses_give_count;
    },
    'SET_CUSTOMER_COUNT' (state, {customer_count}) {
        state.customer_count = customer_count;
    },
    'SET_VOLUNTEER_COUNT' (state, {volunteer_count}) {
        state.volunteer_count = volunteer_count;
    },


  }

