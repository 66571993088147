
export const mutations = {

  'SET_USER_INFO' (state, {adminId, name, email}) {
    state.adminId = adminId ; 
    state.name = name ;
    state.email = email ; 
  },

  'SET_ACCESS_TOKEN' (state, {accessToken}) {
    state.accessToken = accessToken;

    // 토큰을 로컬 스토리지에 저장
    localStorage.accessToken = accessToken;
  },

  'SET_IS_AUTH' (state, { is_auth }) {
    state.isAuth = is_auth;
    // localStorage.isAuth = is_auth;
  },

  'SET_AUTH_GROUP' (state, { auth_group }) {
    state.auth_group = auth_group;
  },

  'SET_REFRESH_TOKEN' (state, {refreshToken}) {
    state.refreshToken = refreshToken;

    // 토큰을 로컬 스토리지에 저장
    localStorage.refreshToken = refreshToken; 
  },
  
  
}