
export const mutations = {

    'set_menu' (state, {topMenu, sideMenu }) {
        state.topMenu = topMenu ; 
        state.sideMenu = sideMenu ; 
    },
    'set_top_menu' (state, {topMenu}) {
        state.topMenu = topMenu ; 
    },
    'set_side_menu' (state, {sideMenu}) {
        state.sideMenu = sideMenu ; 
    },
      
}
