
import {mutations} from './mutations'
import {actions} from './actions'
import {getters} from "./getters";

const state = {
    customervoice_post_page: 1,
    customervoice_post_content_type: 0,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
