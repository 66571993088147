<template>
  <div>
    <nav>
      <ul>
        <li
          v-bind:class="{ checked: sideMenu == 'MainVisualContentsList' }"
          v-show="IsMySideMenu('MainVisualContentsList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('MainVisualContentsList')"
            >메인 비주얼 동영상 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
        <li
          v-bind:class="{ checked: sideMenu == 'DavichNumberEdit' }"
          v-show="IsMySideMenu('DavichNumberEdit', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('DavichNumberEdit')"
            >숫자로 보는 다비치 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
        <li
          v-bind:class="{ checked: sideMenu == 'PopupList' }"
          v-show="IsMySideMenu('PopupList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('PopupList')"
            >팝업 관리<img src="@/assets/image/icon-arrow.png" alt="icon-arrow"
          /></a>
        </li>
        <li
          v-bind:class="{ checked: sideMenu == 'PrivacyPolicyList' }"
          v-show="IsMySideMenu('PrivacyPolicyList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('PrivacyPolicyList')"
            >개인정보처리방침 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
        <li
          v-bind:class="{ checked: sideMenu == 'AdminList' }"
          v-show="IsMySideMenu('AdminList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('AdminList')"
            >관리자 계정 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
        <li
          v-bind:class="{ checked: sideMenu == 'AdminHistoryList' }"
          v-show="IsMySideMenu('AdminHistoryList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('AdminHistoryList')"
            >관리자 히스토리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
      </ul>
    </nav>
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import menuhaneld from "../../utils/menuhandle";

export default {
  name: "Others",
  data: () => ({}),
  computed: {
    ...mapState({
      sideMenu: state => state.sideMenu,
    }),
    ...mapGetters("auth", ["getAuthGroup"]),
  },
  methods: {
    IsMySideMenu: menuhaneld.IsMySideMenu,
    onSideMenu(menuname) {
      this.$store.commit("set_side_menu", { sideMenu: menuname });
      if (this.$route.name !== menuname) this.$router.push({ name: menuname });
    },
  },
};
</script>

<style></style>
