import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store/index'

import Login from "@/components/Login.vue";

import DefaultLayout from "../layouts/DefaultLayout";

import About from "@/views/about/About.vue"; 
import Products from "@/views/products/Products.vue"; 
import Partnership from "@/views/partnership/Partnership.vue"; 
import Education from "@/views/education/Education.vue"; 
import Whatsnew from "@/views/whatsnew/Whatsnew.vue"; 
import Support from "@/views/support/Support.vue"; 
import Others from "@/views/others/Others.vue"; 



// About / history - 연혁관리
// import HistoryList from "../components/about/history/HistoryList.vue"
// import HistoryEdit from "../components/about/history/HistoryEdit.vue"
// import HistoryCreate from "../components/about/history/HistoryCreate.vue"

// About / eyesight - 눈건강 시력지킴이 게시판

// About / brochure - 브로슈어 


import AboutRouters from './about'; 
import ProductsRouters from './products'; 
import PartnershipRouters from './partnership'; 
import EducationRouters from './educatioin'; 
import WhatsnewRouters from './whatsnew'; 
import SupportRouters from './support'; 
import OthersRouters from './others'; 


// import store from '@/vuex/store' 

Vue.use(VueRouter);



// eslint-disable-next-line no-unused-vars
// const requireAuth = () => (to, from, next) => {
//   console.log('from : ', from , ' to ', to ); 
//   console.log( 'store ', store.state.auth.isAuth ); 
//   if ( store.state.auth.accessToken !== '' && store.state.auth.isAuth )  {
//     return next();
//   } 
//   next('/login');
// };



export const router = new VueRouter({
    mode: "history",
    scrollBehavior() {
      return {
        x: 0,
        y: 0,
      };
    },
    routes: [
        {
          path: "/login",
          name: "Login",
          component: Login,
        },
        {
          path: "/",
          name: "Home",
          redirect: '/about',
          component: DefaultLayout,
          children: [
            {
              path: '/about',
              name: 'About',
              component: About,

              redirect: '/about/history', // 초기 사이드 메뉴 
              children: AboutRouters,
            },
            {
              path: '/products',
              name: 'Products',
              component: Products,
              redirect: '/products/glassesframe/list', // 초기 사이드 메뉴 
              children: ProductsRouters
            },
            {
              path: '/partnership',
              name: 'Partnership',
              component: Partnership,
              redirect: '/partnership/store/list', // 초기 사이드 메뉴 
              children: PartnershipRouters
            },
            {
              path: '/education',
              name: 'Education',
              component: Education,
              redirect: '/education/department/list', // 초기 사이드 메뉴 
              children: EducationRouters
            },
            {
              path: '/whatsnew',
              name: 'Whatsnew',
              component: Whatsnew,
              redirect: '/whatsnew/adcontent/list', // 초기 사이드 메뉴 
              children: WhatsnewRouters
            },
            {
              path: '/support',
              name: 'Support',
              component: Support,
              redirect: '/support/customervoicepost/list', // 초기 사이드 메뉴 
              children: SupportRouters

            },
            {
              path: '/others',
              name: 'Others',
              component: Others,
              redirect: '/others/davichnumber/edit', // 초기 사이드 메뉴 
              children: OthersRouters
            },
          ]
        },
    ],
});


router.beforeEach((to, from, next) => {
  
  // console.log('from => ', from , ' to => ', to );
  if ( to.path == '/login') {
    return next();
  }
  else if ( store.state.auth.accessToken !== '' && store.state.auth.isAuth )  {
    return next();
  } 

  next('/login');
}); 


