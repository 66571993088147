import { 
  // - 부서관리
  reqDepartmentList,
  reqCreateDepartment,
  reqUpdateDepartment,
  reqRemoveDepartmentList,

  // 부서 순위 변경 관련 
  reqUpdateDepartmentOrderList, 

  // - 조직관리
  reqEmployeeList,
  reqCreateEmployee,
  reqUpdateEmployee,
  reqRemoveEmployeeList,
  
  // - 성공사례 - successstory
  reqSuccessStoryList,
  reqCreateSuccessStory,
  reqUpdateSuccessStory,
  reqRemoveSuccessStoryList,

  // - 사진 및 졸업동영상 관리  - pictureandvideo
  reqPictureAndVideoList,
  reqCreatePictureAndVideo,
  reqUpdatePictureAndVideo,
  reqRemovePictureAndVideoList,

  // - 사관 공채 공고 관리 - officeropenrecruitment/announce
  reqOfficeropenrecruitmentAnnounceList, 
  reqCreateOfficeropenrecruitmentAnnounce,
  reqUpdateOfficeropenrecruitmentAnnounce,
  reqRemoveOfficeropenrecruitmentAnnounceList,

  // - 사관 공채 지원 관리 - officeropenrecruitment/apply
  reqOfficeropenrecruitmentApplyList, 
  reqCreateOfficeropenrecruitmentApply,
  reqUpdateOfficeropenrecruitmentApply,
  reqRemoveOfficeropenrecruitmentApplyList,

} from "@/api/davich_api";



export const actions = {

    // - 부서관리
    // eslint-disable-next-line no-unused-vars
    req_department_list ( { commit } , payload ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqDepartmentList( payload ).then( (res) => {
                console.log('res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_create_department ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreateDepartment( payload ).then( (res) => {
                console.log('req_create_department res => ', res.data ); 
                resolve({ data: res.data } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_update_department ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdateDepartment( payload ).then( (res) => {
                console.log('req_update_department res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    },

    // eslint-disable-next-line no-unused-vars
    req_remove_department_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemoveDepartmentList( payload ).then( (res) => {
                console.log('req_remove_department_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    },

    // 부서 순위 변경 업데이트
    // eslint-disable-next-line no-unused-vars
    req_update_department_order_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdateDepartmentOrderList( payload ).then( (res) => {
                console.log('req_update_department_order_list res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    },



    // - 조직관리
    // reqEmployeeList,
    // reqCreateEmployee,
    // reqUpdateEmployee,
    // reqRemoveEmployeeList,
    // eslint-disable-next-line no-unused-vars
    req_employee_list ( { commit } , payload ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqEmployeeList( payload ).then( (res) => {
                console.log('list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_create_employee ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreateEmployee( payload ).then( (res) => {
                console.log('create res => ', res.data ); 
                resolve({ data: res.data } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_update_employee ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdateEmployee( payload ).then( (res) => {
                console.log('update res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_remove_employee_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemoveEmployeeList( payload ).then( (res) => {
                console.log('remove_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },


    // - 성공사례 - successstory
    // reqSuccessStoryList,
    // reqCreateSuccessStory,
    // reqUpdateSuccessStory,
    // reqRemoveSuccessStoryList,

    // eslint-disable-next-line no-unused-vars
    req_successstory_list ( { commit } , payload ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqSuccessStoryList( payload ).then( (res) => {
                console.log('list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_create_successstory ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreateSuccessStory( payload ).then( (res) => {
                console.log('create res => ', res.data ); 
                resolve({ data: res.data } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_update_successstory ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdateSuccessStory( payload ).then( (res) => {
                console.log('update res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_remove_successstory_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemoveSuccessStoryList( payload ).then( (res) => {
                console.log('remove_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // - 사진 및 졸업동영상 관리  - pictureandvideo
    //   reqPictureAndVideoList,
    //   reqCreatePictureAndVideo,
    //   reqUpdatePictureAndVideo,
    //   reqRemovePictureAndVideoList,

    // eslint-disable-next-line no-unused-vars
    req_pictureandvideo_list ( { commit } , payload ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqPictureAndVideoList( payload ).then( (res) => {
                console.log('list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_create_pictureandvideo ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreatePictureAndVideo( payload ).then( (res) => {
                console.log('create res => ', res.data ); 
                resolve({ data: res.data } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_update_pictureandvideo ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdatePictureAndVideo( payload ).then( (res) => {
                console.log('update res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_remove_pictureandvideo_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemovePictureAndVideoList( payload ).then( (res) => {
                console.log('remove_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

//   // - 사관 공채 공고 관리 - officeropenrecruitment/announce
//   reqOfficeropenrecruitmentAnnounceList, 
//   reqCreateOfficeropenrecruitmentAnnounce,
//   reqUpdateOfficeropenrecruitmentAnnounce,
//   reqRemoveOfficeropenrecruitmentAnnounceList,      

    // eslint-disable-next-line no-unused-vars
    req_officeropenrecruitment_announce_list ( { commit } , payload ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqOfficeropenrecruitmentAnnounceList( payload ).then( (res) => {
                console.log('list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_create_officeropenrecruitment_announce ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreateOfficeropenrecruitmentAnnounce( payload ).then( (res) => {
                console.log('create res => ', res.data ); 
                resolve({ data: res.data } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    }, 

    // eslint-disable-next-line no-unused-vars
    req_update_officeropenrecruitment_announce ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdateOfficeropenrecruitmentAnnounce( payload ).then( (res) => {
                console.log('update res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_remove_officeropenrecruitment_announce_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemoveOfficeropenrecruitmentAnnounceList( payload ).then( (res) => {
                console.log('remove_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },


    // - 사관 공채 지원 관리 - officeropenrecruitment/apply
    //   reqOfficeropenrecruitmentApplyList, 
    //   reqCreateOfficeropenrecruitmentApply,
    //   reqUpdateOfficeropenrecruitmentApply,
    //   reqRemoveOfficeropenrecruitmentApplyList,

    // eslint-disable-next-line no-unused-vars
    req_officeropenrecruitment_apply_list ( { commit } , payload ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqOfficeropenrecruitmentApplyList( payload ).then( (res) => {
                console.log('list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_create_officeropenrecruitment_apply ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreateOfficeropenrecruitmentApply( payload ).then( (res) => {
                console.log('create res => ', res.data ); 
                resolve({ data: res.data } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_update_officeropenrecruitment_apply ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdateOfficeropenrecruitmentApply( payload ).then( (res) => {
                console.log('update res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_remove_officeropenrecruitment_apply_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemoveOfficeropenrecruitmentApplyList( payload ).then( (res) => {
                console.log('remove_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },




    


}
   