
import {mutations} from './mutations'
import {actions} from './actions'
import {getters} from "./getters";

const state = {
    
    // area_code 
    store_area_code_list: [],

    store_open_condition_list: [],
    store_open_condition_totalcount: 0,

    store_area_list: [],
    store_area_totalcount: 0 , 
    
    store_area_details_list: [],
    store_area_details_totalcount: 0,
    
    // 가맹 문의 게시글 관련 
    store_open_inquire_post_list: [],
    store_open_inquire_post_totalcount: 0, 
    // 제휴 문의 게시글 관련  
    partnership_post_list: [],
    partnership_post_totalcount: 0,
    partnership_post_page: 1,
    partnership_post_selected_type: null,
     
    // 제휴 문의 유형
    partnership_type_list: [],
    partnership_type_totalcount: 0,

    // 시그니처 첨부파일 데이터 

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}