import {_} from 'vue-underscore';

export const getters = {

    getHistoryYears:state => {
        return _.range( state.currentYear , state.minYear - 1 );
    },
    getHistoryList: state => {
        return state.historylist ; 
    },
    getHistoryTotalCount: state => {
        return state.historyTotalCount ; 
    },
    getEyesightList: state => {
        return state.eyesightlist ; 
    },
    getEyesightTotalCount: state => {
        return state.eyesightTotalCount ; 
    },


}
