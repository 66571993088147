const StoreCreate  = () => import(/* webpackChunkName: "partnership" */ '../components/partnership/store/StoreCreate.vue');
const StoreEdit    = () => import(/* webpackChunkName: "partnership" */ '../components/partnership/store/StoreEdit.vue');
const StoreList    = () => import(/* webpackChunkName: "partnership" */ '../components/partnership/store/StoreList.vue');
const StoreShow    = () => import(/* webpackChunkName: "partnership" */ '../components/partnership/store/StoreShow.vue');

// import StoreCreate from "../components/partnership/store/StoreCreate.vue";
// import StoreEdit from "../components/partnership/store/StoreEdit.vue";
// import StoreList from "../components/partnership/store/StoreList.vue";
// import StoreShow from "../components/partnership/store/StoreShow.vue";

const StoreOpenAreaCreate  = () => import(/* webpackChunkName: "partnership" */ '../components/partnership/storeopenarea/StoreOpenAreaCreate.vue');
const StoreOpenAreaEdit   = () => import(/* webpackChunkName: "partnership" */ '../components/partnership/storeopenarea/StoreOpenAreaEdit.vue');
const StoreOpenAreaList   = () => import(/* webpackChunkName: "partnership" */ '../components/partnership/storeopenarea/StoreOpenAreaList.vue');
const StoreOpenAreaShow   = () => import(/* webpackChunkName: "partnership" */ '../components/partnership/storeopenarea/StoreOpenAreaShow.vue');

// import StoreOpenAreaCreate from "../components/partnership/storeopenarea/StoreOpenAreaCreate.vue";
// import StoreOpenAreaEdit from "../components/partnership/storeopenarea/StoreOpenAreaEdit.vue";
// import StoreOpenAreaList from "../components/partnership/storeopenarea/StoreOpenAreaList.vue";
// import StoreOpenAreaShow from "../components/partnership/storeopenarea/StoreOpenAreaShow.vue";

const StoreOpenInquirePostCreate  = () => import(/* webpackChunkName: "partnership" */ '../components/partnership/storeopeninquirepost/StoreOpenInquirePostCreate.vue');
const StoreOpenInquirePostEdit   = () => import(/* webpackChunkName: "partnership" */  '../components/partnership/storeopeninquirepost/StoreOpenInquirePostEdit.vue');
const StoreOpenInquirePostList   = () => import(/* webpackChunkName: "partnership" */  '../components/partnership/storeopeninquirepost/StoreOpenInquirePostList.vue');
const StoreOpenInquirePostShow   = () => import(/* webpackChunkName: "partnership" */  '../components/partnership/storeopeninquirepost/StoreOpenInquirePostShow.vue');

// import StoreOpenInquirePostCreate from "../components/partnership/storeopeninquirepost/StoreOpenInquirePostCreate.vue";
// import StoreOpenInquirePostEdit   from "../components/partnership/storeopeninquirepost/StoreOpenInquirePostEdit.vue";
// import StoreOpenInquirePostList   from "../components/partnership/storeopeninquirepost/StoreOpenInquirePostList.vue";
// import StoreOpenInquirePostShow   from "../components/partnership/storeopeninquirepost/StoreOpenInquirePostShow.vue";

const StoreOpenInquireManagerCreate  = () => import(/* webpackChunkName: "partnership" */ '../components/partnership/storeopeninquiremanager/StoreOpenInquireManagerCreate.vue');
const StoreOpenInquireManagerEdit   = () => import(/* webpackChunkName: "partnership" */  '../components/partnership/storeopeninquiremanager/StoreOpenInquireManagerEdit.vue');
const StoreOpenInquireManagerList   = () => import(/* webpackChunkName: "partnership" */  '../components/partnership/storeopeninquiremanager/StoreOpenInquireManagerList.vue');
const StoreOpenInquireManagerShow   = () => import(/* webpackChunkName: "partnership" */  '../components/partnership/storeopeninquiremanager/StoreOpenInquireManagerShow.vue');

// import StoreOpenInquireManagerCreate from "../components/partnership/storeopeninquiremanager/StoreOpenInquireManagerCreate.vue";
// import StoreOpenInquireManagerEdit   from "../components/partnership/storeopeninquiremanager/StoreOpenInquireManagerEdit.vue";
// import StoreOpenInquireManagerList   from "../components/partnership/storeopeninquiremanager/StoreOpenInquireManagerList.vue";
// import StoreOpenInquireManagerShow   from "../components/partnership/storeopeninquiremanager/StoreOpenInquireManagerShow.vue";

const PartnershipPostCreate  = () => import(/* webpackChunkName: "partnership" */ '../components/partnership/partnershippost/PartnershipPostCreate.vue');
const PartnershipPostEdit   = () => import(/* webpackChunkName: "partnership" */  '../components/partnership/partnershippost/PartnershipPostEdit.vue');
const PartnershipPostList   = () => import(/* webpackChunkName: "partnership" */  '../components/partnership/partnershippost/PartnershipPostList.vue');
const PartnershipPostShow   = () => import(/* webpackChunkName: "partnership" */  '../components/partnership/partnershippost/PartnershipPostShow.vue');

const PartnershipTypeCreate  = () => import(/* webpackChunkName: "partnership" */ '../components/partnership/partnershiptype/PartnershipTypeCreate.vue');


// import PartnershipPostCreate from "../components/partnership/partnershippost/PartnershipPostCreate.vue";
// import PartnershipPostEdit   from "../components/partnership/partnershippost/PartnershipPostEdit.vue";
// import PartnershipPostList   from "../components/partnership/partnershippost/PartnershipPostList.vue";
// import PartnershipPostShow   from "../components/partnership/partnershippost/PartnershipPostShow.vue";

const PartnershipManagerCreate  = () => import(/* webpackChunkName: "partnership" */ '../components/partnership/partnershipmanager/PartnershipManagerCreate.vue');
const PartnershipManagerEdit   = () => import(/* webpackChunkName: "partnership" */  '../components/partnership/partnershipmanager/PartnershipManagerEdit.vue');
const PartnershipManagerList   = () => import(/* webpackChunkName: "partnership" */  '../components/partnership/partnershipmanager/PartnershipManagerList.vue');
const PartnershipManagerShow   = () => import(/* webpackChunkName: "partnership" */  '../components/partnership/partnershipmanager/PartnershipManagerShow.vue');

// import PartnershipManagerCreate from "../components/partnership/partnershipmanager/PartnershipManagerCreate.vue";
// import PartnershipManagerEdit   from "../components/partnership/partnershipmanager/PartnershipManagerEdit.vue";
// import PartnershipManagerList   from "../components/partnership/partnershipmanager/PartnershipManagerList.vue";
// import PartnershipManagerShow   from "../components/partnership/partnershipmanager/PartnershipManagerShow.vue";

// Partnership
// - store ( 가맹점 현황 관리 )
//   - Create.vue
//   - Edit.vue
//   - List.vue
//   - Show.vue
// - storeopenarea  ( 가맹점 오픈가능지역 관리 )
//   - Create.vue
//   - Edit.vue
//   - List.vue
//   - Show.vue
// - storeopeninquirepost  ( 가맹점 개설문의  )
//   - Create.vue
//   - Edit.vue
//   - List.vue
//   - Show.vue
// - storeopeninquiremanager ( 가맹 개설문의 담당자 관리 )
//   - Create.vue
//   - Edit.vue
//   - List.vue
//   - Show.vue
// - partnershippost  ( 제휴문의 )
//   - Create.vue
//   - Edit.vue
//   - List.vue
//   - Show.vue
// - partnershipmanager ( 제휴문의 담당자 관리)
//   - Create.vue
//   - Edit.vue
//   - List.vue
//   - Show.vue

export default [
  // ( 가맹점 현황 관리 )
  {
    path: "/partnership/store/create",
    name: "StoreCreate",
    component: StoreCreate,
  },
  {
    path: "/partnership/store/edit",
    name: "StoreEdit",
    component: StoreEdit,
    props: true
  },
  {
    path: "/partnership/store/list",
    name: "StoreList",
    component: StoreList,
  },
  {
    path: "/partnership/store/show",
    name: "StoreShow",
    component: StoreShow,
  },

  // ( 가맹점 오픈가능지역 관리 )
  {
    path: "/partnership/storeopenarea/create",
    name: "StoreOpenAreaCreate",
    component: StoreOpenAreaCreate,
  },
  {
    path: "/partnership/storeopenarea/edit",
    name: "StoreOpenAreaEdit",
    component: StoreOpenAreaEdit,
    props: true
  },
  {
    path: "/partnership/storeopenarea/list",
    name: "StoreOpenAreaList",
    component: StoreOpenAreaList,
  },
  {
    path: "/partnership/storeopenarea/show",
    name: "StoreOpenAreaShow",
    component: StoreOpenAreaShow,
    props: true
  },

  // ( 가맹점 개설문의  )
  {
    path: "/partnership/storeopeninquirepost/create",
    name: "StoreOpenInquirePostCreate",
    component: StoreOpenInquirePostCreate,
  },
  {
    path: "/partnership/storeopeninquirepost/edit",
    name: "StoreOpenInquirePostEdit",
    component: StoreOpenInquirePostEdit,
    props: true
  },
  {
    path: "/partnership/storeopeninquirepost/list",
    name: "StoreOpenInquirePostList",
    component: StoreOpenInquirePostList,
  },
  {
    path: "/partnership/storeopeninquirepost/show",
    name: "StoreOpenInquirePostShow",
    component: StoreOpenInquirePostShow,
    props: true
  },

  // ( 가맹 개설문의 담당자 관리 )
  {
    path: "/partnership/storeopeninquiremanager/create",
    name: "StoreOpenInquireManagerCreate",
    component: StoreOpenInquireManagerCreate,
  },
  {
    path: "/partnership/storeopeninquiremanager/edit",
    name: "StoreOpenInquireManagerEdit",
    component: StoreOpenInquireManagerEdit,
    props: true
  },
  {
    path: "/partnership/storeopeninquiremanager/list",
    name: "StoreOpenInquireManagerList",
    component: StoreOpenInquireManagerList,
  },
  {
    path: "/partnership/storeopeninquiremanager/show",
    name: "StoreOpenInquireManagerShow",
    component: StoreOpenInquireManagerShow,
    props: true
  },

  // ( 제휴문의 )
  {
    path: "/partnership/partnershippost/create",
    name: "PartnershipPostCreate",
    component: PartnershipPostCreate,
  },
  {
    path: "/partnership/partnershippost/edit",
    name: "PartnershipPostEdit",
    component: PartnershipPostEdit,
    props: true
  },
  {
    path: "/partnership/partnershippost/list",
    name: "PartnershipPostList",
    component: PartnershipPostList,
  },
  {
    path: "/partnership/partnershippost/show",
    name: "PartnershipPostShow",
    component: PartnershipPostShow,
    props: true
  },

  // 제휴 유형 관리
  {
    path: "/partnership/partnershiptype/create",
    name: "PartnershipTypeCreate",
    component: PartnershipTypeCreate,
  },

  // ( 제휴문의 담당자 관리 )
  {
    path: "/partnership/partnershipmanager/create",
    name: "PartnershipManagerCreate",
    component: PartnershipManagerCreate,
  },
  {
    path: "/partnership/partnershipmanager/edit",
    name: "PartnershipManagerEdit",
    component: PartnershipManagerEdit,
    props: true
  },
  {
    path: "/partnership/partnershipmanager/list",
    name: "PartnershipManagerList",
    component: PartnershipManagerList,
  },
  {
    path: "/partnership/partnershipmanager/show",
    name: "PartnershipManagerShow",
    component: PartnershipManagerShow,
    props: true
  },
];
