
import {mutations} from './mutations'
import {actions} from './actions'
import {getters} from "./getters";

const state = {
    currentYear: new Date().getFullYear() ,
    minYear: 1960,
    historylist: [],
    historyTotalCount:0,
    eyesightlist: [],
    eyesightTotalCount:0,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
