const GlassesFrameCreate  = () => import(/* webpackChunkName: "products" */ '../components/products/glassesframe/GlassesFrameCreate.vue');
const GlassesFrameEdit   = () => import(/* webpackChunkName: "products" */  '../components/products/glassesframe/GlassesFrameEdit.vue');
const GlassesFrameList   = () => import(/* webpackChunkName: "products" */  '../components/products/glassesframe/GlassesFrameList.vue');
const GlassesFrameShow   = () => import(/* webpackChunkName: "products" */  '../components/products/glassesframe/GlassesFrameShow.vue');

// import GlassesFrameCreate from "../components/products/glassesframe/GlassesFrameCreate.vue";
// import GlassesFrameEdit   from "../components/products/glassesframe/GlassesFrameEdit.vue";
// import GlassesFrameList   from "../components/products/glassesframe/GlassesFrameList.vue";
// import GlassesFrameShow   from "../components/products/glassesframe/GlassesFrameShow.vue";

const GlassesLensCreate  = () => import(/* webpackChunkName: "products" */ '../components/products/glasseslens/GlassesLensCreate.vue');
const GlassesLensEdit   = () => import(/* webpackChunkName: "products" */  '../components/products/glasseslens/GlassesLensEdit.vue');
const GlassesLensList   = () => import(/* webpackChunkName: "products" */  '../components/products/glasseslens/GlassesLensList.vue');
const GlassesLensShow   = () => import(/* webpackChunkName: "products" */  '../components/products/glasseslens/GlassesLensShow.vue');

// import GlassesLensCreate from "../components/products/glasseslens/GlassesLensCreate.vue";
// import GlassesLensEdit   from "../components/products/glasseslens/GlassesLensEdit.vue";
// import GlassesLensList   from "../components/products/glasseslens/GlassesLensList.vue";
// import GlassesLensShow   from "../components/products/glasseslens/GlassesLensShow.vue";

const ContactLensCreate  = () => import(/* webpackChunkName: "products" */ '../components/products/contactlens/ContactLensCreate.vue');
const ContactLensEdit   = () => import(/* webpackChunkName: "products" */  '../components/products/contactlens/ContactLensEdit.vue');
const ContactLensList   = () => import(/* webpackChunkName: "products" */  '../components/products/contactlens/ContactLensList.vue');
const ContactLensShow   = () => import(/* webpackChunkName: "products" */  '../components/products/contactlens/ContactLensShow.vue');

// import ContactLensCreate from "../components/products/contactlens/ContactLensCreate.vue";
// import ContactLensEdit   from "../components/products/contactlens/ContactLensEdit.vue";
// import ContactLensList   from "../components/products/contactlens/ContactLensList.vue";
// import ContactLensShow   from "../components/products/contactlens/ContactLensShow.vue";

const HearingAidCreate  = () => import(/* webpackChunkName: "products" */ '../components/products/hearingaid/HearingAidCreate.vue');
const HearingAidEdit   = () => import(/* webpackChunkName: "products" */  '../components/products/hearingaid/HearingAidEdit.vue');
const HearingAidList   = () => import(/* webpackChunkName: "products" */  '../components/products/hearingaid/HearingAidList.vue');
const HearingAidShow   = () => import(/* webpackChunkName: "products" */  '../components/products/hearingaid/HearingAidShow.vue');

// import HearingAidCreate from "../components/products/hearingaid/HearingAidCreate.vue";
// import HearingAidEdit   from "../components/products/hearingaid/HearingAidEdit.vue";
// import HearingAidList   from "../components/products/hearingaid/HearingAidList.vue";
// import HearingAidShow   from "../components/products/hearingaid/HearingAidShow.vue";

// Products
// - glassesFrame ( 안경테 )
//   - Create.vue
//   - Edit.vue
//   - List.vue
//   - Show.vue
// - glassesLens ( 안경렌즈 관리 )
//   - Create.vue
//   - Edit.vue
//   - List.vue
//   - Show.vue
// - contactLens (콘텍트렌즈 관리)
//   - Create.vue
//   - Edit.vue
//   - List.vue
//   - Show.vue
// - hearingAid (보청기 관리)
//   - Create.vue
//   - Edit.vue
//   - List.vue
//   - Show.vue

export default [
  // 안경테 관리
  {
    path: "/products/glassesframe/create",
    name: "GlassesFrameCreate",
    component: GlassesFrameCreate,
  },
  {
    path: "/products/glassesframe/edit/:id",
    name: "GlassesFrameEdit",
    component: GlassesFrameEdit,
    props: true
  },
  {
    path: "/products/glassesframe/list",
    name: "GlassesFrameList",
    component: GlassesFrameList,
  },
  {
    path: "/products/glassesframe/show",
    name: "GlassesFrameShow",
    component: GlassesFrameShow,
  },

  // 안경렌즈 관리
  {
    path: "/products/glasseslens/create",
    name: "GlassesLensCreate",
    component: GlassesLensCreate,
  },
  {
    path: "/products/glasseslens/edit/:id",
    name: "GlassesLensEdit",
    component: GlassesLensEdit,
    props: true
  },
  {
    path: "/products/glasseslens/list",
    name: "GlassesLensList",
    component: GlassesLensList,
  },
  {
    path: "/products/glasseslens/show",
    name: "GlassesLensShow",
    component: GlassesLensShow,
  },

  // 콘텍트렌즈 관리
  {
    path: "/products/contactlens/create",
    name: "ContactLensCreate",
    component: ContactLensCreate,
  },
  {
    path: "/products/contactlens/edit/:id",
    name: "ContactLensEdit",
    component: ContactLensEdit,
    props: true
  },
  {
    path: "/products/contactlens/list",
    name: "ContactLensList",
    component: ContactLensList,
  },
  {
    path: "/products/contactlens/show",
    name: "ContactLensShow",
    component: ContactLensShow,
  },

  // 보청기 관리
  {
    path: "/products/hearingaid/create",
    name: "HearingAidCreate",
    component: HearingAidCreate,
  },
  {
    path: "/products/hearingaid/edit/:id",
    name: "HearingAidEdit",
    component: HearingAidEdit,
    props: true
  },
  {
    path: "/products/hearingaid/list",
    name: "HearingAidList",
    component: HearingAidList,
  },
  {
    path: "/products/hearingaid/show",
    name: "HearingAidShow",
    component: HearingAidShow,
  },
];
