import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";


import {actions} from './actions' 
import {getters} from './getters' 
import {mutations} from './mutations'

import auth from '@/store/modules/auth'
import about from '@/store/modules/about'
import education from '@/store/modules/education'
import others from '@/store/modules/others'
import partnership from '@/store/modules/partnership'
import products from '@/store/modules/products'
import support from '@/store/modules/support'
import whatsnews from '@/store/modules/whatsnews'



Vue.use(Vuex);

const state =  {
    
    // 홈페이지 바로가기 url 주소 
    homePageUrl: "https://www.davich.com",
    topMenu: '' , 
    sideMenu: '' 
}; 

export default new Vuex.Store( {
    modules: {
        auth,
        about,
        education,
        others,
        partnership,
        products,
        support,
        whatsnews
    }, 
    plugins: [ 
        createPersistedState({ 
            //주목! : 여기에 쓴 모듈만 저장됩니다. 
            paths: ["auth"], }), 
    ],
    state,
    actions,
    getters,
    mutations,
    strict: process.env.NODE_ENV !== 'production'
});