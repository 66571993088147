<template>
   <div>
     <nav>
        <ul>
          <li v-bind:class="{ checked: (sideMenu == 'HistoryList' ) }" v-show="IsMySideMenu('HistoryList', getAuthGroup)">    
            <!-- <router-link :to="{ name: 'HistoryList'}"> -->
              <a @click.stop="onSideMenu('HistoryList')">연혁관리<img src="@/assets/image/icon-arrow.png" alt="icon-arrow"/></a>
            <!-- </router-link> -->
          </li>
          <li v-bind:class="{ checked: (sideMenu == 'EyesightList' ) }" v-show="IsMySideMenu('EyesightList', getAuthGroup)">    
            <!-- <router-link :to="{ name: 'EyesightList'}"> -->
            <a @click.stop="onSideMenu('EyesightList')">눈건강 시력지킴이 게시판<img src="@/assets/image/icon-arrow.png" alt="icon-arrow"/></a>
            <!-- </router-link> -->
          </li>
          <li v-bind:class="{ checked: (sideMenu == 'BrochureEdit' ) }" v-show="IsMySideMenu('BrochureEdit', getAuthGroup)">    
            <!-- <router-link :to="{ name: 'BrochureEdit'}"> -->
            <a @click.stop="onSideMenu('BrochureEdit')">브로슈어 다운로드 관리<img src="@/assets/image/icon-arrow.png" alt="icon-arrow"/></a>
            <!-- </router-link> -->
          </li>
        </ul>
      </nav>
      <router-view />
    </div>

</template>

<script>
import { mapState  } from "vuex";
import { mapGetters} from "vuex";
import menuhaneld  from "../../utils/menuhandle";

export default {
  name: 'About',
  data: () => ({
  }),
  computed: {
    ...mapState({
      sideMenu: state => state.sideMenu,
    }),
    ...mapGetters( 'auth', ['getAuthGroup']),
  },
  methods: {
    IsMySideMenu: menuhaneld.IsMySideMenu ,
    onSideMenu(menuname) {
      this.$store.commit('set_side_menu', {sideMenu:menuname}); 
      if( this.$route.name !== menuname ) this.$router.push( {name: menuname });       
    },   
  },
}

</script>

<style></style>
