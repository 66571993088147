
import {mutations} from './mutations'
import {actions} from './actions'
import {getters} from "./getters";

const state = {
    franchise_count: 0,
    graduate_count: 0,
    glasses_give_count:0,
    customer_count: 0,
    volunteer_count: 0

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
