import { 
    reqCustomerVoiceList,
    reqCreateCustomerVoice ,
    reqUpdateCustomerVoice ,
    reqRemoveCustomerVoiceList ,

    reqCreateCustomerVoiceAnswer,
    reqCustomerVoiceAnswerList,

    reqFaqList ,
    reqCreateFaq ,
    reqUpdateFaq ,
    reqRemoveFaqList,

    reqGiftcardOrderList,    
    reqCreateGiftcardOrder,
    reqUpdateGiftcardOrder,
    reqRemoveGiftcardOrderList,

    reqStoreList,
    
} from "@/api/davich_api";



export const actions = {

    // ===============================================
    // - 고객의 소리
    // ===============================================
    // - 조회 
    // eslint-disable-next-line no-unused-vars
    req_customervoice_list ( { commit } , payload ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCustomerVoiceList( payload ).then( (res) => {
                console.log('res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_create_customervoice ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreateCustomerVoice( payload ).then( (res) => {
                console.log('req_create_customervoice res => ', res.data ); 
                resolve({ data: res.data } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_update_customervoice  ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdateCustomerVoice( payload ).then( (res) => {
                console.log('req_update_customervoice res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    },

    // - 선택 삭제   
    // eslint-disable-next-line no-unused-vars
    req_remove_customervoice_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemoveCustomerVoiceList( payload ).then( (res) => {
                console.log('req_remove_customervoice_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // 고객의 소리 답변글 
    // eslint-disable-next-line no-unused-vars
    req_create_customervoice_answer ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreateCustomerVoiceAnswer( payload ).then( (res) => {
                console.log('req_create_customervoice_answer res => ', res.data ); 
                resolve({ data: res.data } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },
    // eslint-disable-next-line no-unused-vars
    req_customervoice_answer_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCustomerVoiceAnswerList( payload ).then( (res) => {
                console.log('req_customervoice_answer_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },


    // ===============================================
    // - 자주 묻는 질문
    // - faq 
    // ===============================================

    // - 조회 
    // eslint-disable-next-line no-unused-vars
    req_faq_list ( { commit } , payload ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqFaqList( payload ).then( (res) => {
                console.log('res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_create_faq ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreateFaq ( payload ).then( (res) => {
                console.log('req_create_faq res => ', res.data ); 
                resolve({ data: res.data } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_update_faq  ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdateFaq( payload ).then( (res) => {
                console.log('req_update_faq res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    },

    // - 선택 삭제   
    // eslint-disable-next-line no-unused-vars
    req_remove_faq_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemoveFaqList( payload ).then( (res) => {
                console.log('req_remove_faq_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },



    // ===============================================
    // - 지류 상품권 구매
    // - giftcardorder
    // ===============================================

    // - 조회 - giftcard 
    // eslint-disable-next-line no-unused-vars
    req_giftcardorder_list ( { commit } , payload ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqGiftcardOrderList( payload ).then( (res) => {
                console.log('res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_create_giftcardorder ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreateGiftcardOrder( payload ).then( (res) => {
                console.log('req_create_giftcardorder res => ', res.data ); 
                resolve({ data: res.data } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_update_giftcardorder  ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdateGiftcardOrder( payload ).then( (res) => {
                console.log('req_update_giftcardorder res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    },

    // - 선택 삭제   
    // eslint-disable-next-line no-unused-vars
    req_remove_giftcardorder_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemoveGiftcardOrderList( payload ).then( (res) => {
                console.log('req_remove_giftcardorder_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },


 

    // ===============================================
    // - 매장 찾기 관리 
    // ===============================================

    // - 조회 
    // eslint-disable-next-line no-unused-vars
    req_store_list ( { commit } , payload ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqStoreList( payload ).then( (res) => {
                console.log('res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

    // - 선택 삭제   
    // eslint-disable-next-line no-unused-vars
    // req_remove_store_list ( { commit } ,  payload  ) {
    //     console.log('payload => ', payload );
    //     return new Promise(( resolve, reject ) => {
    //         reqRemoveStoreList( payload ).then( (res) => {
    //             console.log('req_remove_department_list res => ', res.data ); 
    //             resolve({ data: res.data , total_count:res.headers['x-total-count']} );
    //         }).catch( (err) => { 
    //             console.log(err) ;    
    //             reject(err); 
    //         });
    //     });
    // },


}
   