<template>
  <div>
    <nav>
      <ul>
        <li
          v-bind:class="{ checked: sideMenu == 'CustomerVoicePostList' }"
          v-show="IsMySideMenu('CustomerVoicePostList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('CustomerVoicePostList')"
            >고객의 소리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
        <li
          v-bind:class="{ checked: sideMenu == 'CustomerVoiceManagerList' }"
          v-show="IsMySideMenu('CustomerVoiceManagerList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('CustomerVoiceManagerList')"
            >고객의 소리 담당자 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
        <li
          v-bind:class="{ checked: sideMenu == 'FaqList' }"
          v-show="IsMySideMenu('FaqList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('FaqList')"
            >자주하는 질문 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
        <li
          v-bind:class="{ checked: sideMenu == 'GiftCardList' }"
          v-show="IsMySideMenu('GiftCardList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('GiftCardList')"
            >지류 상품권 구매<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
        <li
          v-bind:class="{ checked: sideMenu == 'GiftCardManagerList' }"
          v-show="IsMySideMenu('GiftCardManagerList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('GiftCardManagerList')"
            >지류 상품권 구매 담당자 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
        <li
          v-bind:class="{ checked: sideMenu == 'FindStoreList' }"
          v-show="IsMySideMenu('FindStoreList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('FindStoreList')"
            >매장찾기 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
      </ul>
    </nav>
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters, mapMutations } from "vuex";

import menuhaneld from "../../utils/menuhandle";

export default {
  name: "Support",
  data: () => ({}),
  computed: {
    ...mapGetters({
      getCustomerVoicePostContentType:
        "support/getCustomerVoicePostContentType",
      getCustomerVoicePostSelectedPage:
        "support/getCustomerVoicePostSelectedPage",
    }),
    ...mapState({
      sideMenu: (state) => state.sideMenu,
    }),
    ...mapGetters("auth", ["getAuthGroup"]),
  },
  created() {},
  methods: {
    ...mapMutations({
      setCustomerVoicePostSelectedPage: "support/SET_CUSTOMERVOICE_POST_PAGE",
      setCustomerVoicePostContentType:
        "support/SET_CUSTOMERVOICE_POST_CONTENT_TYPE",
    }),
    IsMySideMenu: menuhaneld.IsMySideMenu,
    onSideMenu(menuname) {
      this.setCustomerVoicePostSelectedPage({ customervoice_post_page: 1 });
      this.setCustomerVoicePostContentType({
        customervoice_post_content_type: 0,
      });
      this.$store.commit("set_side_menu", { sideMenu: menuname });
      if (this.$route.name !== menuname) this.$router.push({ name: menuname });
    },
  },
};
</script>

<style></style>
