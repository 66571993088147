import axios from '@/api/axios-auth';
import { 
    reqMainVisualContentsList,
    reqCreateMainVisualContents,
    reqUpdateMainVisualContents,
    reqRemoveMainVisualContentsList,

    // 
    reqPopupList,
    reqCreatePopup,
    reqUpdatePopup,
    reqRemovePopupList,

    reqPrivacyPolicy,
    reqPrivacyPolicyList,
    reqCreatePrivacyPolicy,    
    reqUpdatePrivacyPolicy,
    reqRemovePrivacyPolicyList,

    reqAdminList,
    reqCreateAdmin,
    reqUpdateAdmin,
    reqRemoveAdminList,
    
    reqFindAdmin,

    reqAdminGroupList,

    reqAdminActionLogList,


} from "@/api/davich_api";
import appConfig from '../../../config/app_config'; 
import hmacSHA512 from 'crypto-js/hmac-sha512';

export const actions = {


    // ===============================================
    // - 메인 비주얼 콘텐츠 관리 
    // ===============================================

    // - 조회 
    // eslint-disable-next-line no-unused-vars
    req_mainvisualcontents_list ( { commit } , payload ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqMainVisualContentsList( payload ).then( (res) => {
                console.log('req_mainvisualcontents_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_create_mainvisualcontents ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreateMainVisualContents( payload ).then( (res) => {
                console.log('req_mainvisualcontents_list res => ', res.data ); 
                resolve({ data: res.data } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_update_mainvisualcontents ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdateMainVisualContents( payload ).then( (res) => {
                console.log('req_update_mainvisualcontents res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err);
            });
        });

    },

    // - 선택 삭제   
    // eslint-disable-next-line no-unused-vars
    req_remove_mainvisualcontents_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemoveMainVisualContentsList( payload ).then( (res) => {
                console.log('req_remove_mainvisualcontents_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

     

    // ===============================================
    // - 숫자로 보는 다비치 관리
    // ===============================================
    // eslint-disable-next-line no-unused-vars
    getDavichNumbers( {commit }) {

        var baseUrl = '/others/davichnumbers'; 
        return axios.get(baseUrl).then((res) => {

            commit('SET_DAVICH_NUMBERS', {franchise_count: res.data.franchise_count , 
                graduate_count: res.data.graduate_count ,
                glasses_give_count: res.data.glasses_give_count ,
                customer_count: res.data.customer_count,
                volunteer_count: res.data.volunteer_count,
            } );

        }).catch( (error) => {
            console.log('/others/davichnumbers error => ', error);
            throw error;
        });
    },

    // eslint-disable-next-line no-unused-vars
    updateDavichNumbers ({ commit } , { franchise_count, graduate_count , glasses_give_count, customer_count, volunteer_count} ) {
        
        var baseUrl = '/others/davichnumbers'; 
        return axios.put(baseUrl , {
            franchise_count: franchise_count, 
            graduate_count: graduate_count ,
            glasses_give_count: glasses_give_count ,
            customer_count: customer_count ,
            volunteer_count: volunteer_count,
        }) ;
    },

    // ===============================================
    // - 팝업 관리
    // - popup 
    // ===============================================

        // - 조회 
    // eslint-disable-next-line no-unused-vars
    req_popup_list ( { commit } , payload ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqPopupList( payload ).then( (res) => {
                console.log('req_mainvisualcontents_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_create_popup ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreatePopup( payload ).then( (res) => {
                console.log('req_mainvisualcontents_list res => ', res.data ); 
                resolve({ data: res.data } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_update_popup ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdatePopup( payload ).then( (res) => {
                console.log('req_update_mainvisualcontents res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    },

    // - 선택 삭제   
    // eslint-disable-next-line no-unused-vars
    req_remove_popup_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemovePopupList( payload ).then( (res) => {
                console.log('req_remove_mainvisualcontents_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

   

    // ===============================================
    // - 개인정보처리방침 관리
    // ===============================================

    // privacypolicy
    // eslint-disable-next-line no-unused-vars
    req_privacypolicy ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqPrivacyPolicy( ).then( (res) => {
                console.log('req_privacypolicy res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    }, 

    // eslint-disable-next-line no-unused-vars
    req_privacypolicyList ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqPrivacyPolicyList( payload ).then( (res) => {
                console.log('req_privacypolicyList res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    }, 




    // eslint-disable-next-line no-unused-vars
    req_update_privacypolicy ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdatePrivacyPolicy( payload ).then( (res) => {
                console.log('req_update_privacypolicy res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    }, 

    // eslint-disable-next-line no-unused-vars
    req_create_privacypolicy ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreatePrivacyPolicy( payload ).then( (res) => {
                console.log('req_create_privacypolicy res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    }, 

    
    // eslint-disable-next-line no-unused-vars
    req_remove_privacypolicy_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemovePrivacyPolicyList( payload ).then( (res) => {
                console.log('req_remove_privacypolicy_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // ===============================================
    // - 관리자 계정 관리
    // - admin 
    // ===============================================

        // - 조회 
    // eslint-disable-next-line no-unused-vars
    req_admin_list ( { commit } , payload ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqAdminList( payload ).then( (res) => {
                console.log('req_admin_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_create_admin ( { commit } ,  payload  ) {

        console.log('payload => ', payload );

        let hmacDigestPassword = hmacSHA512(payload.admin_pw, appConfig.privateKey).toString();
        payload.admin_pw = hmacDigestPassword ; 
        console.log('req_create_admin payload => ', payload );

        return new Promise(( resolve, reject ) => {
            reqCreateAdmin( payload ).then( (res) => {
                console.log('req_create_admin res => ', res.data ); 
                resolve({ data: res.data } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_find_admin ( { commit } ,  payload  ) {
        return new Promise(( resolve, reject ) => {
            reqFindAdmin( payload ).then( (res) => {
                console.log('req_find_admin res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_update_admin ( { commit } ,  payload  ) {
        console.log('payload => ', payload );

        if( payload.admin_pw != null && payload.admin_pw != undefined && payload.admin_pw.length > 0 ) {
            let hmacDigestPassword = hmacSHA512(payload.admin_pw, appConfig.privateKey).toString();
            payload.admin_pw = hmacDigestPassword ; 
        }
        console.log('req_update_admin payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdateAdmin( payload ).then( (res) => {
                console.log('req_update_admin res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    },

    // - 선택 삭제   
    // eslint-disable-next-line no-unused-vars
    req_remove_admin_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemoveAdminList( payload ).then( (res) => {
                console.log('req_remove_admin_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // reqAdminGroupList
    // eslint-disable-next-line no-unused-vars
    req_admingroup_list ( { commit } ,  ) {
        return new Promise(( resolve, reject ) => {
            reqAdminGroupList(  ).then( (res) => {
                console.log('req_admin_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

    // ===============================================
    // - 관리자 히스토리
    // - admin_history 
    // ===============================================
    
    // eslint-disable-next-line no-unused-vars
    req_admin_action_log_list ( { commit } , payload ) {
        console.log('payload => ', payload ); 
        return new Promise(( resolve, reject ) => {
            reqAdminActionLogList( payload ).then( (res) => {
                console.log('req_admin_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

}