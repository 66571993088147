import { 
    reqStoreOpenConditionList , 
    reqCreateStoreOpenCondition,
    reqUpdateStoreOpenCondition,
    reqRemoveStoreOpenConditionList, 
    reqStoreAreaCodeList,
    reqStoreAreaList , 
    reqStoreAreaDetailsList,
    reqCreateStoreAreaDetailsList,
    reqUpdateStoreAreaDetailsList,
    reqStoreOpenInquirePostList,
    reqRemoveStoreOpenInquirePostList,
    reqPartnershipPostList,
    reqUpdatePartnershipPost,
    reqRemovePartnershipPostList ,
    reqCreatePartnershipInquireAnswer,
    reqPartnershipInquireAnswerList,
    reqPartnershipTypeList,
    reqPartnershipTypeContentCount,
    reqPatchPartnershipTypeList
} from "@/api/davich_api";
 

 
export const actions = {

    req_store_open_condition_list ( { commit } , payload ) {

        console.log('payload => ', payload );
        reqStoreOpenConditionList( payload ).then( (res) => {
            console.log('res => ', res.data ); 
            commit('SET_STORE_OPEN_CONDITION_LIST', {store_open_condition_list: res.data} );
            commit('SET_STORE_OPEN_CONDITION_TOTALCOUNT', {store_open_condition_count: res.headers['x-total-count'] } );
        }).catch( (err) => { 
            commit('SET_STORE_OPEN_CONDITION_LIST', {store_open_condition_list: [] } );
            commit('SET_STORE_OPEN_CONDITION_TOTALCOUNT', {store_open_condition_count: 0 } );
            console.log(err) ;     
        });
    },
 
    // eslint-disable-next-line no-unused-vars
    req_create_store_open_condition ( { commit } , payload ) {
        console.log('req_create_store_open_condition payload => ', payload );
        reqCreateStoreOpenCondition( payload ).then( (res) => {
            console.log('res => ', res.data ); 
        }).catch( (err) => { 
            console.log(err) ;     
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_update_store_open_condition ( { commit } , payload ) {
        // console.log('req_update_store_open_condition payload => ', payload );
        reqUpdateStoreOpenCondition( payload.id, payload ).then( (res) => {
            console.log('res => ', res.data ); 
        }).catch( (err) => { 
            console.log(err) ;     
        });
    },

        // - 선택 삭제   
    // eslint-disable-next-line no-unused-vars
    req_remove_store_open_condition_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemoveStoreOpenConditionList( payload ).then( (res) => {
                console.log('req_remove_store_open_condition_list res => ', res.data ); 
                commit('SET_STORE_OPEN_CONDITION_LIST', {store_open_condition_list: res.data} );
                commit('SET_STORE_OPEN_CONDITION_TOTALCOUNT', {store_open_condition_count: res.headers['x-total-count'] } );
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },






    // ======================================================================================
    // area_code_list 
    // ======================================================================================
    // eslint-disable-next-line no-unused-vars
    req_store_area_code_list ( { commit } , payload ) {
        // store_area_details_list
        console.log('req_store_area_code_list payload => ' );
        reqStoreAreaCodeList( ).then( (res) => {
            // console.log('reqStoreAreaCodeList res => ', res.data );  

            commit('SET_STORE_AREA_CODE_LIST', {store_area_code_list: res.data} );
        }).catch( (err) => { 
            console.log(err) ;                
        });
    },
    // ======================================================================================

    // 가맹점 오픈 가능 지역 
    req_store_area_list ( { commit } , payload ) {
        // store_area_details_list
        // console.log('req_store_area_list payload => ', payload );
        reqStoreAreaList( payload ).then( (res) => {
            // console.log('reqStoreAreaList res => ', res.data ); 
            commit('SET_STORE_AREA_LIST', {store_area_list: res.data} );
            commit('SET_STORE_AREA_TOTALCOUNT', {store_area_totalcount: res.headers['x-total-count'] } );
        }).catch( (err) => { 
            console.log(err) ;                
        });
    },
    
    // eslint-disable-next-line no-unused-vars
    req_store_area_details_list ( { commit } , payload ) {
        // store_area_details_list
        console.log('req_store_area_details_list payload => ', payload );
        reqStoreAreaDetailsList( payload ).then( (res) => {
            console.log('res => ', res.data ); 
        }).catch( (err) => { 
            console.log(err) ;                
        });
    },

    // create 
    // eslint-disable-next-line no-unused-vars
    req_create_store_area_detailslist ( { commit } , payload ) {
        // req_create_store_area_details
        // console.log('req_create_store_area_details payload => ', payload );
        reqCreateStoreAreaDetailsList( payload ).then( (res) => {
            console.log('res => ', res.data ); 
        }).catch( (err) => { 
            console.log(err) ;                
        });
    },
    // eslint-disable-next-line no-unused-vars
    req_update_store_area_detailslist ( { commit } , payload ) {
        // req_create_store_area_details
        // console.log('req_update_store_area_detailslist payload => ', payload );
        reqUpdateStoreAreaDetailsList( payload ).then( (res) => {
            console.log('res => ', res.data ); 
        }).catch( (err) => { 
            console.log(err) ;                
        });
    },
    

    // 가맹점 문의 개시글 리스트 조회 
    // eslint-disable-next-line no-unused-vars
    req_store_open_inquire_post_list ( { commit } , payload ) {
        // store_area_details_list
        console.log('req_store_open_inquire_post_list payload => ', payload );
        reqStoreOpenInquirePostList( payload ).then( (res) => {
            // console.log('reqStoreOpenInquirePostList res => ', res.data ); 
            commit('SET_STORE_OPEN_INQUIRE_POST_LIST', {store_open_inquire_post_list: res.data} );
            commit('SET_STORE_OPEN_INQUIRE_POST_TOTALCOUNT', {store_open_inquire_post_totalcount: res.headers['x-total-count'] } );
        }).catch( (err) => { 
            console.log(err) ;                
        });
    },

    // 가맹점 문의 개시글 삭제 ( array )
    // eslint-disable-next-line no-unused-vars
    remove_store_open_inquire_post_list ( { commit } , payload ) {
        // store_area_details_list
        console.log('remove_store_open_inquire_post_list payload => ', payload );
        reqRemoveStoreOpenInquirePostList( payload ).then( (res) => {
            // console.log('reqRemoveStoreOpenInquirePostList res => ', res.data );
            commit('SET_STORE_OPEN_INQUIRE_POST_LIST', {store_open_inquire_post_list: res.data} );
            commit('SET_STORE_OPEN_INQUIRE_POST_TOTALCOUNT', {store_open_inquire_post_totalcount: res.headers['x-total-count'] } );
        }).catch( (err) => { 
            console.log(err) ;                
        });
    },


    // 제휴 문의 개시글 리스트 조회 
    req_partnership_post_list ( { commit } , payload ) {
        console.log('req_partnership_post_list payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqPartnershipPostList( payload ).then( (res) => {
                // console.log('reqPartnershipPostList res => ', res.data ); 
                commit('SET_PARTNERSHIP_POST_LIST', {partnership_post_list: res.data} );
                commit('SET_PARTNERSHIP_POST_TOTALCOUNT', {partnership_post_totalcount: res.headers['x-total-count'] } );
                commit('SET_PARTNERSHIP_POST_PAGE', {partnership_post_page: payload.page} );
                resolve({ data: res.data });
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err);            
            });
        });

    },

    // eslint-disable-next-line no-unused-vars
    req_update_partnership_post ( { commit } , payload ) {
        console.log('req_update_partnership_post payload => ', payload );

        return new Promise(( resolve, reject ) => {
            reqUpdatePartnershipPost( payload ).then( (res) => {
                // console.log('reqUpdatePartnershipPost res => ', res.data ); 
                resolve({ data: res.data });
            }).catch( (err) => { 
                console.log(err) ;      
                reject(err); 
            });
        });
    },


    remove_partnership_post_list ( { commit } , payload ) {
        // remove_parnership_post_list
        console.log('remove_parnership_post_list payload => ', payload );
        reqRemovePartnershipPostList( payload ).then( (res) => {
            // console.log('reqRemovePartnershipPostList res => ', res.data ); 
            commit('SET_PARTNERSHIP_POST_LIST', {partnership_post_list: res.data} );
            commit('SET_PARTNERSHIP_POST_TOTALCOUNT', {partnership_post_totalcount: res.headers['x-total-count'] } );
        }).catch( (err) => { 
            console.log(err) ;                
        });
    },

    // 제휴 문의글 답변글 생성
    // eslint-disable-next-line no-unused-vars
    req_create_partnership_inquire_answer ( { commit } , payload ) {
        console.log('req_create_partnership_inquire_answer payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreatePartnershipInquireAnswer( payload ).then( (res) => {
                // console.log('reqCreatePartnershipInquireAnswer res => ', res.data ); 
                resolve({ data: res.data });
            }).catch( (err) => { 
                console.log(err) ;      
                reject(err);          
            });
        });     


    },

    // 제휴 문의글 답변글 리스트 조회 
    // eslint-disable-next-line no-unused-vars
    req_partnership_inquire_answer_list ( { commit } , payload ) {
        console.log('req_partnership_inquire_answer_list payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqPartnershipInquireAnswerList( payload ).then( (res) => {
                // console.log('reqPartnershipInquireAnswerList res => ', res.data ); 
                resolve({ data: res.data });
            }).catch( (err) => { 
                console.log(err) ;       
                reject(err);        
            });
        });     
    },



    // 제휴 유형 리스트 조회 
    req_partnership_type_list ( { commit } , payload ) {
        return new Promise(( resolve, reject ) => {
            reqPartnershipTypeList( payload ).then( (res) => {
                // console.log('reqPartnershipTypeList res => ', res.data ); 
                commit('SET_PARTNERSHIP_TYPE_LIST', {partnership_type_list: res.data} );
                commit('SET_PARTNERSHIP_TYPE_TOTALCOUNT', {partnership_type_totalcount: res.headers['x-total-count'] } );
                resolve({ data: res.data });
            }).catch( (err) => { 
                console.log(err) ;        
               reject(err);               
            });
        });     

        
    },

    // 제휴 유형 삭제 가능한지 조회
    // eslint-disable-next-line no-unused-vars
    req_partnership_type_contents_count ( { commit } , payload ) {
        return new Promise(( resolve, reject ) => {
            reqPartnershipTypeContentCount( payload ).then( (res) => {
                resolve({ data: res.data });
            }).catch( (err) => { 
                console.log(err) ; 
                reject(err);                       
            });
        });
    },
    
    // 제휴 유형 list patch
    // eslint-disable-next-line no-unused-vars
    req_update_partnership_type_list ( { commit } , payload ) {
        return new Promise(( resolve, reject ) => {
            reqPatchPartnershipTypeList( payload ).then( (res) => {
                resolve({ data: res.data });
            }).catch( (err) => { 
                console.log(err) ; 
                reject(err);                       
            });
        });
    },


    // ==================================================
    // 가맹점 개설문의 담당자
    // ==================================================
    
    // 조회
    // eslint-disable-next-line no-unused-vars
    req_store_open_inquire_manager_list ( { commit } , payload ) {
 
    }, 
    // 등록
    // eslint-disable-next-line no-unused-vars
    req_create_store_open_inquire_manager ( { commit } , payload ) {


    },
    // 수정
    // eslint-disable-next-line no-unused-vars
    req_update_store_open_inquire_manager ( { commit } , payload ) {

    },
    // 선택삭제
    // eslint-disable-next-line no-unused-vars
    req_remove_store_open_inquire_manager_list ( { commit } , payload ) {

    },

}

 
