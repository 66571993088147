<template>
  <div>
    <nav>
      <ul>
        <li v-bind:class="{ checked: sideMenu == 'DepartmentList' }"  v-show="IsMySideMenu('DepartmentList', getAuthGroup)">
          <a @click.stop="onSideMenu('DepartmentList')"
            >부서관리<img src="@/assets/image/icon-arrow.png" alt="icon-arrow"
          /></a>
        </li>
        <li v-bind:class="{ checked: sideMenu == 'GroupList' }"  v-show="IsMySideMenu('GroupList', getAuthGroup)">
          <a @click.stop="onSideMenu('GroupList')"
            >조직 관리<img src="@/assets/image/icon-arrow.png" alt="icon-arrow"
          /></a>
        </li>
        <li v-bind:class="{ checked: sideMenu == 'SuccessStoryList' }"  v-show="IsMySideMenu('SuccessStoryList', getAuthGroup)">
          <a @click.stop="onSideMenu('SuccessStoryList')"
            >성공사례 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
        <li v-bind:class="{ checked: sideMenu == 'PictureAndVideoList' }"  v-show="IsMySideMenu('PictureAndVideoList', getAuthGroup)">
          <a @click.stop="onSideMenu('PictureAndVideoList')"
            >사진 및 졸업동영상 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
        <li
          v-bind:class="{
            checked: sideMenu == 'OfficerOpenRecruitmentPostList',
          }"
           v-show="IsMySideMenu('OfficerOpenRecruitmentPostList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('OfficerOpenRecruitmentPostList')"
            >사관 공채 공고 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
        <li
          v-bind:class="{
            checked: sideMenu == 'OfficerOpenRecruitmentApplyList',
          }"
           v-show="IsMySideMenu('OfficerOpenRecruitmentApplyList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('OfficerOpenRecruitmentApplyList')"
            >사관 공채 지원 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
        <li
          v-bind:class="{
            checked: sideMenu == 'OfficerOpenRecruitmentManagerList',
          }" 
           v-show="IsMySideMenu('OfficerOpenRecruitmentManagerList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('OfficerOpenRecruitmentManagerList')"
            >사관 공채 지원 담당자 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
        </li>
      </ul>
    </nav>
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters} from "vuex";
import menuhaneld  from "../../utils/menuhandle";

export default {
  name: "Education",
  data: () => ({}),
  computed: {
    ...mapState({
      sideMenu: (state) => state.sideMenu,
    }),
    ...mapGetters( 'auth', ['getAuthGroup']),
  },
  methods: {
    IsMySideMenu: menuhaneld.IsMySideMenu ,
    onSideMenu(menuname) {
      this.$store.commit("set_side_menu", { sideMenu: menuname });
      if (this.$route.name !== menuname) this.$router.push({ name: menuname });
    },
  },
};
</script>

<style></style>
