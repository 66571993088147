import axios from '@/api/axios-auth';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import appConfig from '../../../config/app_config'; 

import { 
  reqAuthnumberSendToMail,
  reqCheck2FAAuthnumber,
} from "@/api/davich_api";


export const actions = {

  reqLogin ({ commit },  {adminId, adminPw} ) {

        // passwd 암호화 필요.
        const hmacDigestPassword = hmacSHA512(adminPw, appConfig.privateKey).toString();

        return new Promise(( resolve, reject ) => {
          axios.post(`/auth/admin/login`, { 
              'admin_id': adminId,
              'admin_pw': hmacDigestPassword
          })
            .then( (res) => { 
              // console.log('login res ', res); 
              console.log('login res ', res.data); 
              // res.status 
              // commit('SET_USER_INFO', {adminId: adminId , name: res.data.name , email: res.data.email });
              // commit('SET_ACCESS_TOKEN', {accessToken: res.data.access_token} );
              // commit('SET_REFRESH_TOKEN', {refreshToken: res.data.refresh_token} );
              // commit('SET_IS_AUTH', {is_auth: true});
              // commit('SET_AUTH_GROUP', {auth_group: res.data.auth_group });
              resolve(res.data);
            })
            .catch((error) => {
              commit('SET_IS_AUTH', { is_auth: false });
              commit('SET_AUTH_GROUP', {auth_group: 99 });
              console.log('reqLogin fail.', error );
              reject(error);
              throw error
            })
      });
  },

  // eslint-disable-next-line no-unused-vars
  reqLogout({ commit }, payload ) {

    // eslint-disable-next-line no-unused-vars
    return new Promise(( resolve, reject ) => {
      commit('SET_USER_INFO', {adminId: '' , name: '' , email: '' });
      commit('SET_ACCESS_TOKEN', {accessToken: null } );
      commit('SET_REFRESH_TOKEN', {refreshToken: null}  );
      commit('SET_IS_AUTH', {is_auth: false});
      commit('SET_AUTH_GROUP', {auth_group: 99 });
      resolve();
    });

  },


  // 이메일로 인증번호 발송 요청
  // eslint-disable-next-line no-unused-vars 
  req_authnumber_send_to_mail({ commit },  payload ) {
    console.log('payload => ', payload );
    return new Promise(( resolve, reject ) => {
      reqAuthnumberSendToMail( payload ).then( (res) => {
            console.log('req_authnumber_send_to_mail res => ', res.data ); 
            resolve({ data: res.data} );
        }).catch( (err) => { 
            console.log(err) ;    
            reject(err); 
        });
    });
  },

  // 인증번호 확인 요청
  // eslint-disable-next-line no-unused-vars 
  req_check_2fa_authnumber({ commit },  payload ) {
    console.log('payload => ', payload );
    return new Promise(( resolve, reject ) => {
      reqCheck2FAAuthnumber( payload ).then( (res) => {
            console.log('req_check_2fa_authnumber res => ', res.data ); 
            resolve({ data: res.data} );
        }).catch( (err) => { 
            console.log( 'req_check_2fa_authnumber err => ', err) ;    
            reject(err); 
        });
    });
  }

}
  