
export const getters = {

    // eslint-disable-next-line no-unused-vars
    getAdminId: state => {
        return state.adminId;
    },

    getEmail: state => {
        return state.email;
    },
    
    // eslint-disable-next-line no-unused-vars
    getName: state => {
        return state.name;
    },

    // eslint-disable-next-line no-unused-vars
    getAccessToken: state => {
        return state.access_token;
    },
    
    getRefressToken: state => {
        return state.refress_token;
    },

    // eslint-disable-next-line no-unused-vars
    getIsAuth: state => {
        return state.is_auth;
    },
    
    // eslint-disable-next-line no-unused-vars
    getAuthGroup: state => {
        return state.auth_group;
    },
    
    
}
  