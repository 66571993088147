<template>
  <div>
    <p class="copyright">COPYRIGHTⓒ DAVICH OPTICAL. ALL RIGHTS RESERVED.</p>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
