const CustomerVoicePostCreate  = () => import(/* webpackChunkName: "support" */ '../components/support/customervoicepost/CustomerVoicePostCreate.vue');
const CustomerVoicePostEdit   = () => import(/* webpackChunkName: "support" */  '../components/support/customervoicepost/CustomerVoicePostEdit.vue');
const CustomerVoicePostList   = () => import(/* webpackChunkName: "support" */  '../components/support/customervoicepost/CustomerVoicePostList.vue');
const CustomerVoicePostShow   = () => import(/* webpackChunkName: "support" */  '../components/support/customervoicepost/CustomerVoicePostShow.vue');

// import CustomerVoicePostCreate from "../components/support/customervoicepost/CustomerVoicePostCreate.vue";
// import CustomerVoicePostEdit   from "../components/support/customervoicepost/CustomerVoicePostEdit.vue";
// import CustomerVoicePostList   from "../components/support/customervoicepost/CustomerVoicePostList.vue";
// import CustomerVoicePostShow   from "../components/support/customervoicepost/CustomerVoicePostShow.vue";

const CustomerVoiceManagerCreate  = () => import(/* webpackChunkName: "support" */ '../components/support/customervoicemanager/CustomerVoiceManagerCreate.vue');
const CustomerVoiceManagerEdit   = () => import(/* webpackChunkName: "support" */  '../components/support/customervoicemanager/CustomerVoiceManagerEdit.vue');
const CustomerVoiceManagerList   = () => import(/* webpackChunkName: "support" */  '../components/support/customervoicemanager/CustomerVoiceManagerList.vue');
const CustomerVoiceManagerShow   = () => import(/* webpackChunkName: "support" */  '../components/support/customervoicemanager/CustomerVoiceManagerShow.vue');

// import CustomerVoiceManagerCreate from "../components/support/customervoicemanager/CustomerVoiceManagerCreate.vue";
// import CustomerVoiceManagerEdit   from "../components/support/customervoicemanager/CustomerVoiceManagerEdit.vue";
// import CustomerVoiceManagerList   from "../components/support/customervoicemanager/CustomerVoiceManagerList.vue";
// import CustomerVoiceManagerShow   from "../components/support/customervoicemanager/CustomerVoiceManagerShow.vue";

const FaqCreate  = () => import(/* webpackChunkName: "support" */ '../components/support/faq/FaqCreate.vue');
const FaqEdit   = () => import(/* webpackChunkName: "support" */  '../components/support/faq/FaqEdit.vue');
const FaqList   = () => import(/* webpackChunkName: "support" */  '../components/support/faq/FaqList.vue');
const FaqShow   = () => import(/* webpackChunkName: "support" */  '../components/support/faq/FaqShow.vue');

// import FaqCreate from "../components/support/faq/FaqCreate.vue";
// import FaqEdit   from "../components/support/faq/FaqEdit.vue";
// import FaqList   from "../components/support/faq/FaqList.vue";
// import FaqShow   from "../components/support/faq/FaqShow.vue";

const GiftCardCreate  = () => import(/* webpackChunkName: "support" */ '../components/support/giftcard/GiftCardCreate.vue');
const GiftCardEdit   = () => import(/* webpackChunkName: "support" */  '../components/support/giftcard/GiftCardEdit.vue');
const GiftCardList   = () => import(/* webpackChunkName: "support" */  '../components/support/giftcard/GiftCardList.vue');
const GiftCardShow   = () => import(/* webpackChunkName: "support" */  '../components/support/giftcard/GiftCardShow.vue');

// import GiftCardCreate from "../components/support/giftcard/GiftCardCreate.vue";
// import GiftCardEdit   from "../components/support/giftcard/GiftCardEdit.vue";
// import GiftCardList   from "../components/support/giftcard/GiftCardList.vue";
// import GiftCardShow   from "../components/support/giftcard/GiftCardShow.vue";

const GiftCardManagerCreate  = () => import(/* webpackChunkName: "support" */ '../components/support/giftcardmanager/GiftCardManagerCreate.vue');
const GiftCardManagerEdit   = () => import(/* webpackChunkName: "support" */  '../components/support/giftcardmanager/GiftCardManagerEdit.vue');
const GiftCardManagerList   = () => import(/* webpackChunkName: "support" */  '../components/support/giftcardmanager/GiftCardManagerList.vue');
const GiftCardManagerShow   = () => import(/* webpackChunkName: "support" */  '../components/support/giftcardmanager/GiftCardManagerShow.vue');

// import GiftCardManagerCreate from "../components/support/giftcardmanager/GiftCardManagerCreate.vue";
// import GiftCardManagerEdit   from "../components/support/giftcardmanager/GiftCardManagerEdit.vue";
// import GiftCardManagerList   from "../components/support/giftcardmanager/GiftCardManagerList.vue";
// import GiftCardManagerShow   from "../components/support/giftcardmanager/GiftCardManagerShow.vue";

const FindStoreCreate  = () => import(/* webpackChunkName: "support" */ '../components/support/findstore/FindStoreCreate.vue');
const FindStoreEdit   = () => import(/* webpackChunkName: "support" */  '../components/support/findstore/FindStoreEdit.vue');
const FindStoreList   = () => import(/* webpackChunkName: "support" */  '../components/support/findstore/FindStoreList.vue');
const FindStoreShow   = () => import(/* webpackChunkName: "support" */  '../components/support/findstore/FindStoreShow.vue');

// import FindStoreCreate from "../components/support/findstore/FindStoreCreate.vue";
// import FindStoreEdit   from "../components/support/findstore/FindStoreEdit.vue";
// import FindStoreList   from "../components/support/findstore/FindStoreList.vue";
// import FindStoreShow   from "../components/support/findstore/FindStoreShow.vue";

export default [
  // ( 광고/홍보영상 관리)
  {
    path: "/support/customervoicepost/create",
    name: "CustomerVoicePostCreate",
    component: CustomerVoicePostCreate,
  },
  {
    path: "/support/customervoicepost/edit",
    name: "CustomerVoicePostEdit",
    component: CustomerVoicePostEdit,
    props: true
  },
  {
    path: "/support/customervoicepost/list",
    name: "CustomerVoicePostList",
    component: CustomerVoicePostList,
  },
  {
    path: "/support/customervoicepost/show",
    name: "CustomerVoicePostShow",
    component: CustomerVoicePostShow,
    props: true
  },

  // - customervoicemanager (고객의 소리 담당자 관리)
  {
    path: "/support/customervoicemanager/create",
    name: "CustomerVoiceManagerCreate",
    component: CustomerVoiceManagerCreate,
  },
  {
    path: "/support/customervoicemanager/edit",
    name: "CustomerVoiceManagerEdit",
    component: CustomerVoiceManagerEdit,
    props: true
  },
  {
    path: "/support/customervoicemanager/list",
    name: "CustomerVoiceManagerList",
    component: CustomerVoiceManagerList,
  },
  {
    path: "/support/customervoicemanager/show",
    name: "CustomerVoiceManagerShow",
    component: CustomerVoiceManagerShow,
    props: true
  },

  // - faq ( 자주하는질문 관리)
  {
    path: "/support/faq/create",
    name: "FaqCreate",
    component: FaqCreate,
  },
  {
    path: "/support/faq/edit",
    name: "FaqEdit",
    component: FaqEdit,
    props: true
  },
  {
    path: "/support/faq/list",
    name: "FaqList",
    component: FaqList,
  },
  {
    path: "/support/faq/show",
    name: "FaqShow",
    component: FaqShow,
    props: true
  },

  // - giftcard ( 지류 상품권 구매 )
  {
    path: "/support/giftcard/create",
    name: "GiftCardCreate",
    component: GiftCardCreate,
  },
  {
    path: "/support/giftcard/edit",
    name: "GiftCardEdit",
    component: GiftCardEdit,
    props: true
  },
  {
    path: "/support/giftcard/list",
    name: "GiftCardList",
    component: GiftCardList,
  },
  {
    path: "/support/giftcard/show",
    name: "GiftCardShow",
    component: GiftCardShow,
    props: true
  },

  // - giftcardmanager ( 지류상품권 구매 담당자 관리)
  {
    path: "/support/giftcardmanager/create",
    name: "GiftCardManagerCreate",
    component: GiftCardManagerCreate,
  },
  {
    path: "/support/giftcardmanager/edit",
    name: "GiftCardManagerEdit",
    component: GiftCardManagerEdit,
    props: true
  },
  {
    path: "/support/giftcardmanager/list",
    name: "GiftCardManagerList",
    component: GiftCardManagerList,
  },
  {
    path: "/support/giftcardmanager/show",
    name: "GiftCardManagerShow",
    component: GiftCardManagerShow,
    props: true
  },

  // - findstore (매장찾기 관리 )
  {
    path: "/support/findstore/create",
    name: "FindStoreCreate",
    component: FindStoreCreate,
  },
  {
    path: "/support/findstore/edit",
    name: "FindStoreEdit",
    component: FindStoreEdit,
    props: true
  },
  {
    path: "/support/findstore/list",
    name: "FindStoreList",
    component: FindStoreList,
  },
  {
    path: "/support/findstore/show",
    name: "FindStoreShow",
    component: FindStoreShow,
    props: true
  },
];
