
const HistoryList = () => import(/* webpackChunkName: "about" */ '../components/about/history/HistoryList.vue');
const HistoryEdit = () => import(/* webpackChunkName: "about" */ '../components/about/history/HistoryEdit.vue');
const HistoryCreate = () => import(/* webpackChunkName: "about" */ '../components/about/history/HistoryCreate.vue');

// import HistoryList from "../components/about/history/HistoryList.vue"
// import HistoryEdit from "../components/about/history/HistoryEdit.vue"
// import HistoryCreate from "../components/about/history/HistoryCreate.vue"


const EyesightCreate = () => import(/* webpackChunkName: "about" */ '../components/about/eyesight/EyesightCreate.vue');
const EyesightEdit = () => import(/* webpackChunkName: "about" */ '../components/about/eyesight/EyesightEdit.vue');
const EyesightList = () => import(/* webpackChunkName: "about" */ '../components/about/eyesight/EyesightList.vue');
const EyesightShow = () => import(/* webpackChunkName: "about" */ '../components/about/eyesight/EyesightShow.vue');

const BrochureEdit = () => import(/* webpackChunkName: "about" */ '../components/about/brochure/BrochureEdit.vue');

// import EyesightCreate from "../components/about/eyesight/EyesightCreate.vue"
// import EyesightEdit from "../components/about/eyesight/EyesightEdit.vue"
// import EyesightList from "../components/about/eyesight/EyesightList.vue"
// import EyesightShow from "../components/about/eyesight/EyesightShow.vue"

// import BrochureEdit from "../components/about/brochure/BrochureEdit.vue"

// About

// - history (연혁관 )
//   - HistoryCreate.vue
//   - historyEdit.vue
//   - historyList.vue

// - eyesight ( 눈건강 시력지킴이 게시판)
//   - EyesightCreate.vue
//   - EyesightList.vue
//   - EyesightShow.vue
//   - EyesightEdit.vue

// - brochure (브로슈어)
//   - BrochureEdit.vue


export default [
    
    {
        path: '/about/history/create', 
        name: 'HistoryCreate',
        component: HistoryCreate
    },
    {
        path: '/about/history/edit/:id', 
        name: 'HistoryEdit',
        component: HistoryEdit,
        props: true
    },
    {
        path: '/about/history', 
        name: 'HistoryList',
        component: HistoryList,
    },
   
    
    {
        path: '/about/eyesight', 
        name: 'EyesightList',
        component: EyesightList,
    },
    {
        path: '/about/eyesight/create', 
        name: 'EyesightCreate',
        component: EyesightCreate,
    },
    {
        path: '/about/eyesight/edit/:id', 
        name: 'EyesightEdit',
        component: EyesightEdit,
        props: true
    },
    {
        path: '/about/eyesight/list', 
        name: 'EyesightList',
        component: EyesightList,
    },
    {
        path: '/about/eyesight/show', 
        name: 'EyesightShow',
        component: EyesightShow,
    },

    {
        path: '/about/brochure', 
        name: 'BrochureEdit',
        component: BrochureEdit,
    },
]

   