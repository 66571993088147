export const getters = {


    getGlassesFrameList: state => {
        return state.glassesframelist ; 
    },
    getGlassesFrameTotalCount: state => {
        return state.glassesframe_total_count ; 
    },

    getGlassesLensList: state => {
        return state.glasseslenslist ; 
    },
    getGlassesLensTotalCount: state => {
        return state.glasseslens_total_count ; 
    },

    getContactLensList: state => {
        return state.contactlenslist ; 
    },
    getContactLensTotalCount: state => {
        return state.contactlens_total_count ; 
    },

    getHearingAidList: state => {
        return state.hearingaidlist ; 
    },
    getHearingAidTotalCount: state => {
        return state.hearingaid_total_count ; 
    },

    
}