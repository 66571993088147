const AdContentCreate  = () => import(/* webpackChunkName: "whatsnew" */ '../components/whatsnew/adcontent/AdContentCreate.vue');
const AdContentEdit   = () => import(/* webpackChunkName: "whatsnew" */  '../components/whatsnew/adcontent/AdContentEdit.vue');
const AdContentList   = () => import(/* webpackChunkName: "whatsnew" */  '../components/whatsnew/adcontent/AdContentList.vue');
const AdContentShow   = () => import(/* webpackChunkName: "whatsnew" */  '../components/whatsnew/adcontent/AdContentShow.vue');

// import AdContentCreate from "../components/whatsnew/adcontent/AdContentCreate.vue";
// import AdContentEdit   from "../components/whatsnew/adcontent/AdContentEdit.vue";
// import AdContentList   from "../components/whatsnew/adcontent/AdContentList.vue";
// import AdContentShow   from "../components/whatsnew/adcontent/AdContentShow.vue";

const NewsAndNoticeCreate  = () => import(/* webpackChunkName: "whatsnew" */ '../components/whatsnew/newsandnotice/NewsAndNoticeCreate.vue');
const NewsAndNoticeEdit   = () => import(/* webpackChunkName: "whatsnew" */  '../components/whatsnew/newsandnotice/NewsAndNoticeEdit.vue');
const NewsAndNoticeList   = () => import(/* webpackChunkName: "whatsnew" */  '../components/whatsnew/newsandnotice/NewsAndNoticeList.vue');
const NewsAndNoticeShow   = () => import(/* webpackChunkName: "whatsnew" */  '../components/whatsnew/newsandnotice/NewsAndNoticeShow.vue');

// import NewsAndNoticeCreate from "../components/whatsnew/newsandnotice/NewsAndNoticeCreate.vue";
// import NewsAndNoticeEdit   from "../components/whatsnew/newsandnotice/NewsAndNoticeEdit.vue";
// import NewsAndNoticeList   from "../components/whatsnew/newsandnotice/NewsAndNoticeList.vue";
// import NewsAndNoticeShow   from "../components/whatsnew/newsandnotice/NewsAndNoticeShow.vue";

const EventCreate  = () => import(/* webpackChunkName: "whatsnew" */ '../components/whatsnew/event/EventCreate.vue');
const EventEdit   = () => import(/* webpackChunkName: "whatsnew" */  '../components/whatsnew/event/EventEdit.vue');
const EventList   = () => import(/* webpackChunkName: "whatsnew" */  '../components/whatsnew/event/EventList.vue');
const EventShow   = () => import(/* webpackChunkName: "whatsnew" */  '../components/whatsnew/event/EventShow.vue');

// import EventCreate from "../components/whatsnew/event/EventCreate.vue";
// import EventEdit   from "../components/whatsnew/event/EventEdit.vue";
// import EventList   from "../components/whatsnew/event/EventList.vue";
// import EventShow   from "../components/whatsnew/event/EventShow.vue";

// Whatsnew
// - adcontent ( 광고/홍보영상 관리)
// - newsandnotice (다비치 소식 - 공지/뉴스 )
// - event (이벤트 관리)

export default [
  // ( 광고/홍보영상 관리)
  {
    path: "/whatsnew/adcontent/create",
    name: "AdContentCreate",
    component: AdContentCreate,
  },
  {
    path: "/whatsnew/adcontent/edit",
    name: "AdContentEdit",
    component: AdContentEdit,
    props: true
  },
  {
    path: "/whatsnew/adcontent/list",
    name: "AdContentList",
    component: AdContentList,
  },
  {
    path: "/whatsnew/adcontent/show",
    name: "AdContentShow",
    component: AdContentShow,
    props: true
  },

  // (다비치 소식 - 공지/뉴스 )
  {
    path: "/whatsnew/newsandnotice/create",
    name: "NewsAndNoticeCreate",
    component: NewsAndNoticeCreate,
  },
  {
    path: "/whatsnew/newsandnotice/edit",
    name: "NewsAndNoticeEdit",
    component: NewsAndNoticeEdit,
    props: true
  },
  {
    path: "/whatsnew/newsandnotice/list",
    name: "NewsAndNoticeList",
    component: NewsAndNoticeList,
  },
  {
    path: "/whatsnew/newsandnotice/show",
    name: "NewsAndNoticeShow",
    component: NewsAndNoticeShow,
    props: true
  },

  // (이벤트 관리)
  {
    path: "/whatsnew/event/create",
    name: "EventCreate",
    component: EventCreate,
  },
  {
    path: "/whatsnew/event/edit",
    name: "EventEdit",
    component: EventEdit,
    props: true
  },
  {
    path: "/whatsnew/event/list",
    name: "EventList",
    component: EventList,
  },
  {
    path: "/whatsnew/event/show",
    name: "EventShow",
    component: EventShow,
    props: true
  },
];
