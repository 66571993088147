/**
 * 
 * @project davich admin
 * @author mrwang 2022.03.07
 * 
 */

import axios from 'axios'
import dotenv from 'dotenv';
import { router } from '../router/index';
import VueSimpleAlert from "vue-simple-alert";


dotenv.config();

const apiServerUrl = process.env.VUE_APP_API_SERVER_URL;

// console.log('apiServerUrl => ', apiServerUrl) ; 

// const apiServerUrl = "http://116.126.85.64:8222/api/v1";
// const apiServerUrl = "http://14.63.225.142:8222/api/v1";     // test 
// const apiServerUrl = "http://116.126.85.64/api/v1";          // live 

const instance = axios.create({
    baseURL: apiServerUrl,
    headers: { 'Accept-Language': 'ko'}
});

// before a request is made start  
instance.interceptors.request.use(function (config) {


    const token = localStorage.getItem('accessToken');
    const lang  = localStorage.getItem('LANGUAGE');
    if (token) {
        config.headers.common['Authorization'] = `Bearer ${token}`;
        config.headers.common['Content-Type'] = 'application/json;charset=UTF-8';
    }
    config.headers['Accept-Language'] = lang;

    return config
    }, function (error) {
        // Do something with response error
        return Promise.reject(error.response)
    }
);


// before a response is returned stop  
instance.interceptors.response.use(
    (response) => {

        return response;
    },
    (error) => {
        if ( error.response && error.response.status === 401 && error.config.url != "/auth/authnumberemail/check" ) {
            console.log('error ==> ', error.response.status ); 
            console.log('error ==> ', error.response ); 
            console.log('error ==> ', error.config.url  ); 

            let errorText = error.response.data.message ; 
            VueSimpleAlert.fire({
                title: errorText,
                text: "로그인 페이지로 이동합니다." , 
                type: "error",
                timer: 5000
              }).then(r => {
                console.log(r.value);
                router.push({ name: "Login" });
              });
        }
        return Promise.reject(error);

    }
);
  
 
export default instance
