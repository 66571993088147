import axios from '@/api/axios-auth';
import $ from 'jquery'





// ============================================================
// - about    - eyesight
// - 시력 건강 치킴이   - about/eyesight
// ============================================================

function reqUpdateEyesight( payload ) {
  var apiUrl = '/about/eyesight' + '/' + payload.id ; 
  return axios.patch(apiUrl, payload);
}
// ====================
// PRODUCTS API
// ====================

// create brand 
function createProductBrand( formData ) {
    // console.log( 'createProductBrand ==> ', formData ); 
    return axios.post('/products/brands' , formData , {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
}

function reqUpdateProductBrand( payload ) {
  var apiUrl = '/products/brands' + '/' + payload.id ; 
  return axios.patch(apiUrl, payload);
}


// remove brand 
// remove products_brands list 
function removeProductBrandList( payload ) {
  return axios.delete('/products/brands' , {data: payload});
}


// ============================================================
// - Partnership    - partnership
// - 가맹점 현황 관리   - store/open
// ============================================================

// - list 조회  GET  /partnership/store/open
// eslint-disable-next-line no-unused-vars
function reqStoreOpenConditionList( payload ) {
  
  var apiUrl = '/partnership/store/open'; 
  // paging 
  if( payload.page != null && payload.size != null ) {
    const params = {page:payload.page, size:payload.size}; 
    if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
        params.searchtext = payload.searchtext; 
    }   
    let queryString = $.param( params );
    if( queryString.length > 0 ) {
      apiUrl += '?' + queryString ;
    }
  }
  return axios.get(apiUrl);
}

// - 등록 
function reqCreateStoreOpenCondition( payload ) {
  var apiUrl = '/partnership/store/open'; 
  return axios.post(apiUrl, payload);
}
// - 수정
function reqUpdateStoreOpenCondition( id ,  payload ) {
  var apiUrl = '/partnership/store/open' + '/' + id ; 
  return axios.patch(apiUrl, payload);
}
// - 삭제 
function reqRemoveStoreOpenConditionList( payload ) {
  var apiUrl = '/partnership/store/open'; 
  return axios.delete(apiUrl, {data: payload} );
}
// 

// ============================================================
// - Partnership    - partnership
// - 가맹점 오픈가능 지역관리   - store/area
// ============================================================

// 코드 조회 - list 
function reqStoreAreaCodeList() {
  var apiUrl = '/partnership/store/area/code '; 
  return axios.get(apiUrl);
}

// 조회 - list 
function reqStoreAreaList( payload ) {
  var apiUrl = '/partnership/store/area'; 
  var params = {}; 
  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }
  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }       

  // 
  if( payload.parent_id != null && payload.parent_id != undefined ) {
    params.parent_id = payload.parent_id; 
  }       

  let queryString = $.param( params );
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  }
  return axios.get(apiUrl);
}


// ============================================================
// - Partnership    - partnership
// - 가맹점 오픈가능 지역관리   - store/area
// ============================================================
// 등록
// 등록시 대분류 / 중분류 선택 후 , 소분류를 1개 이상 작성하여 등록 
// 등록의 경우  /partnership/store/area/details 의 record가 생성되어야함. 

// ============================================================
// - Partnership    - partnership
// - 가맹점 오픈가능 지역관리   - store/area
// ============================================================
 
// 가맹점 오픈가능 지역 아이템 조회시 개별 조회와 details 리스트 처리를 같이 하여야함. (서버영역 - 클라이언트에서 필요한지 체크)

// 가맹점 오픈가능 지역관리 
function reqStoreAreaDetailsList( payload ) {
  
  var apiUrl = '/partnership/store/area/details'; 
  // paging 
  if( payload.page != null && payload.size != null ) {
    const params = {page:payload.page, size:payload.size}; 
    if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
        params.searchtext = payload.searchtext; 
    }   
    let queryString = $.param( params );
    if( queryString.length > 0 ) {
      apiUrl += '?' + queryString ;
    }
  }
  return axios.get(apiUrl);
}


function reqCreateStoreAreaDetailsList( payload ) {
  var apiUrl = '/partnership/store/area/detailslist'; 
  // payload 에 item list 를 
  return axios.post(apiUrl, payload );
}

function reqUpdateStoreAreaDetailsList( payload ) {
  var apiUrl = '/partnership/store/area/detailslist'; 
  // payload 에 item list 를  addedlist , deletedlist 
  return axios.put(apiUrl, payload );
}



// ============================================================
// - Partnership 
// - 가맹점 개설문의   - inquire
// ============================================================

// 조회 
function reqStoreOpenInquirePostList( payload ) {
  
  var apiUrl = '/partnership/store/openinquire'; 
  // paging 
  var params = {}; 
  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }       
  
  let queryString = $.param( params );
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  }
  
  return axios.get(apiUrl);
}

function reqRemoveStoreOpenInquirePostList( payload ) {
  
  var apiUrl = '/partnership/store/openinquire'; 
  return axios.delete(apiUrl, {data: payload} );
}


// ============================================================
// - Partnership 
// - 제휴문의 - inquire
// ============================================================

// 조회 - List
function reqPartnershipPostList( payload ) {
  
  var apiUrl = '/partnership/partnership/inquire'; 

  var params = {}; 
  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }     

  // 제휴 유형 
  if( payload.type_id != null && payload.type_id != undefined ) {
    params.type_id = payload.type_id; 
  }     
  
  
  let queryString = $.param( params );
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);
}

function reqUpdatePartnershipPost( payload ) {

  var apiUrl = '/partnership/partnership/inquire'  + '/ ' +  payload.id ; 
  return axios.patch(apiUrl, payload);
}


// 삭제
function reqRemovePartnershipPostList( payload ) {
  
  var apiUrl = '/partnership/partnership/inquire'; 
  return axios.delete(apiUrl, {data: payload} );
}


// 답변글 생성 
function reqCreatePartnershipInquireAnswer( payload ) {
  var apiUrl = 'partnership/partnership/inquire/answer'; 
  // payload 에 item list 를 
  return axios.post(apiUrl, payload );
}

// 답변글 리스트 조회
function reqPartnershipInquireAnswerList( payload ) {

  var apiUrl = '/partnership/partnership/inquire' + '/' + payload.partnership_inquire_post_id + '/answer'  ;  

  var params = {}; 
  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }     
  
  let queryString = $.param( params );
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);

}

// 조회 - List
function reqPartnershipTypeList( payload ) {
  
  var apiUrl = '/partnership/partnership/type'; 

  var params = {}; 
  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      

  let queryString = $.param( params );
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);
}

// 해당 타입의 콘테츠의 개수를 리턴한다.
function reqPartnershipTypeContentCount( payload ) {
  
  var apiUrl = '/partnership/partnership/type' + '/' + payload.type_id + '/contentscount'
 
  return axios.get(apiUrl);
}

function reqPatchPartnershipTypeList( payload) {
  var apiUrl = '/partnership/partnership/typelist' ;
  return axios.patch(apiUrl, payload);
}

// ============================================================
// 매지너 관리 - 서버에서 처리.. url로 판단하여..    ???
// ============================================================

// action 에서 api를 호출한다. 
// action에서 url을 알고 있으므로 taskpart 파라미터를 넣어줄수 있음. 

// 조회
function reqManagerList( taskpart , payload ) {
  
  var apiUrl = '/managers'; 

  var params = {}; 
  params.taskpart = taskpart; 

  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      
  
  let queryString = $.param( params );
  
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);
}


// ============================================================
// - Partnership 
// - 가맹 개설문의 담당자 관리 -  storeopeninquiremanager
// ============================================================


// 등록
function reqCreateManager( payload ) {
  
  var apiUrl = '/managers'; 
  return axios.post(apiUrl, payload);
}
// 수정
function reqUpdateManager( payload ) {
  
  // payload 내의 id 값으로 element 구분이 가능함.
  var apiUrl = '/managers' + '/ ' +  payload.id ; 
  return axios.patch(apiUrl, payload);
}

// 삭제 
function reqRemoveManagerList( payload ) {
  console.log('reqRemoveManagerList payload ==> ', payload);
  var apiUrl = '/managers'; 
  return axios.delete(apiUrl, {data: payload} );
}



// ============================================================
// - Eudcation 
// - 부서 관리  - departments 
// ============================================================

// 조회
function reqDepartmentList( payload ) {
  
  var apiUrl = '/education/departments'; 

  var params = {}; 

  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      
  
  let queryString = $.param( params );
  
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);
}

// 등록 ( 첨부 없음 )
function reqCreateDepartment( payload ) {
  
  var apiUrl = '/education/departments'; 
  return axios.post(apiUrl, payload);
}

// 수정 ( 첨부 없음 )
function reqUpdateDepartment( payload ) {
  var apiUrl = '/education/departments' + '/' + payload.id ; 
  return axios.patch(apiUrl, payload);
}
// 선택삭제
function reqRemoveDepartmentList( payload ) {
  var apiUrl = '/education/departments'; 
  return axios.delete(apiUrl, {data: payload} );
}

// 부서 순위 업데이트 
function reqUpdateDepartmentOrderList( payload ) {
  var apiUrl = '/education/departments'  ; 
  return axios.patch(apiUrl, payload);
}



// ============================================================
// - Eudcation
// - 조직 관리  - employee 
// ============================================================
// 조회
function reqEmployeeList( payload ) {
  
  var apiUrl = '/education/employee'; 

  var params = {}; 

  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      
  
  let queryString = $.param( params );
  
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);
}

// 등록 ( 이미지 첨부 있음 )
function reqCreateEmployee( formData ) {
  
  var apiUrl = '/education/employee'; 
  return axios.post(apiUrl, formData , {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// 수정 ( 이미지 첨부 있음 )
function reqUpdateEmployee( payload ) {
  var apiUrl = '/education/employee' + '/' + payload.id   ; 
  return axios.patch(apiUrl, payload);
}
// 선택삭제
function reqRemoveEmployeeList( payload ) {
  var apiUrl = '/education/employee'; 
  return axios.delete(apiUrl, {data: payload} );
}


// ============================================================
// - Eudcation
// - 성공사례 관리 - successstory 
// ============================================================

// 조회
function reqSuccessStoryList( payload ) {
  
  var apiUrl = '/education/successstory'; 

  var params = {}; 

  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      
  
  let queryString = $.param( params );
  
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);
}
   
// 등록 ( 첨부 X, 유튜브 링크 )
function reqCreateSuccessStory( payload ) {
  
  var apiUrl = '/education/successstory'; 
  return axios.post(apiUrl, payload);
}

// 수정 ( 첨부 X )
function reqUpdateSuccessStory( payload ) {
  var apiUrl = '/education/successstory' + '/' + payload.id   ;  
  return axios.patch(apiUrl, payload);
}
// 선택삭제
function reqRemoveSuccessStoryList( payload ) {
  var apiUrl = '/education/successstory'; 
  return axios.delete(apiUrl, {data: payload} );
}



// ============================================================
// - Eudcation
// - 사진 및 졸업동영상 관리  - pictureandvideo
// ============================================================

// 조회
function reqPictureAndVideoList( payload ) {
  
  var apiUrl = '/education/pictureandvideo'; 

  var params = {}; 

  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      
  
  let queryString = $.param( params );
  
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);
}

// 등록 ( 유튜브 링크 )
function reqCreatePictureAndVideo( payload ) {
  var apiUrl = '/education/pictureandvideo'; 
  return axios.post(apiUrl, payload);
}

// 수정 ( 첨부 X )
function reqUpdatePictureAndVideo( payload ) {
  var apiUrl = '/education/pictureandvideo'+ '/' + payload.id   ;  
  return axios.patch(apiUrl, payload);
}
// 선택삭제
function reqRemovePictureAndVideoList( payload ) {
  var apiUrl = '/education/pictureandvideo'; 
  return axios.delete(apiUrl, {data: payload} );
}


// ============================================================
// - Eudcation  - OfficeropenrecruitmentAnnounce 
// - 사진 및 공채 공고 관리 -  officeropenrecruitmentpost 
// ============================================================

// 조회
function reqOfficeropenrecruitmentAnnounceList( payload ) {
  
  var apiUrl = '/education/officeropenrecruitment/announce'; 

  var params = {}; 

  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      
  
  let queryString = $.param( params );
  
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);
}

// 등록 ( 이미치 첨부 있음 )  
function reqCreateOfficeropenrecruitmentAnnounce( formData ) {
  
  var apiUrl = '/education/officeropenrecruitment/announce'; 
  return axios.post(apiUrl  , formData , {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// 수정 ( 이미치 첨부 있음  )
function reqUpdateOfficeropenrecruitmentAnnounce( payload ) {
  var apiUrl = '/education/officeropenrecruitment/announce'+ '/' + payload.id   ;  
  return axios.patch(apiUrl, payload);
}

// 선택삭제
function reqRemoveOfficeropenrecruitmentAnnounceList( payload ) {
  var apiUrl = '/education/officeropenrecruitment/announce'; 
  return axios.delete(apiUrl, {data: payload} );
}





// ============================================================
// - Eudcation 
// - 사진 및 공채 지원 관리 
// ============================================================

// 조회
function reqOfficeropenrecruitmentApplyList( payload ) {
  
  var apiUrl = '/education/officeropenrecruitment/apply'; 

  var params = {}; 

  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      
  
  if( payload.apply_type != null && payload.apply_type != undefined && payload.apply_type != 0  ) {
    params.apply_type = payload.apply_type; 
  }      
  

  let queryString = $.param( params );
  
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);
}

// 등록 ( 이미치 첨부 있음 )  
function reqCreateOfficeropenrecruitmentApply( payload ) {
  
  var apiUrl = '/education/officeropenrecruitment/apply'; 
  return axios.post(apiUrl, payload);
}

// 등록 ( 이미치 첨부 있음 )  
function reqUpdateOfficeropenrecruitmentApply( payload ) {
  var apiUrl = '/education/officeropenrecruitment/apply'+ '/' + payload.id   ;  
  return axios.patch(apiUrl, payload);
}

// 선택삭제
function reqRemoveOfficeropenrecruitmentApplyList( payload ) {
  var apiUrl = '/education/officeropenrecruitment/apply'; 
  return axios.delete(apiUrl, {data: payload} );
}


// ============================================================
// - Eudcation 
// - 사진 및 공채       담당자 관리 
// ============================================================
// 조회
// 등록  
// 수정 
// 선택삭제
// <== manager 에서 처리 




// ============================================================
// - What's New  - whatsnew 
// - 광고/홍보영상관리 adcontents
// ============================================================
// 조회
function reqAdContentsList( payload ) {
  
  var apiUrl = '/whatsnew/adcontents'; 

  var params = {}; 

  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      

  if( payload.type != null && payload.type != undefined && payload.type != 0  ) {
    params.type = payload.type; 
  }      
  
  let queryString = $.param( params );
  
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);
}

// 등록 ( 유튜뷰 링크 있음)  
function reqCreateAdContents( payload ) {
  
  var apiUrl = '/whatsnew/adcontents'; 
  return axios.post(apiUrl, payload);
}
 
// 수정 ( 유튜뷰 링크 있음  )
function reqUpdateAdContents( payload ) {
  var apiUrl = '/whatsnew/adcontents'+ '/' + payload.id   ;  
  return axios.patch(apiUrl, payload);
}

// 선택삭제
function reqRemoveAdContentsList( payload ) {
  var apiUrl = '/whatsnew/adcontents'; 
  return axios.delete(apiUrl, {data: payload} );
}



// ============================================================
// - What's New 
// - 다비치소식 (공지/뉴스) newsandnotice
// ============================================================
// reqNewsAndNoticeList,
// reqCreateNewsAndNotice,
// reqUpdateNewsAndNotice,
// reqRemoveNewsAndNoticeList,

// 조회
function reqNewsAndNoticeList( payload ) {
  
  var apiUrl = '/whatsnew/newsandnotice'; 

  var params = {}; 

  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      

  if( payload.type != null && payload.type != undefined && payload.type != 0 ) {
    params.type = payload.type; 
  }      
  
  let queryString = $.param( params );
  
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);
}

// 등록  
function reqCreateNewsAndNotice( formData ) {
  
  var apiUrl = '/whatsnew/newsandnotice'; 
  
  return axios.post(apiUrl, formData , {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

}

// 수정  
function reqUpdateNewsAndNotice( payload ) {
  var apiUrl = '/whatsnew/newsandnotice'+ '/' + payload.id   ;  
  return axios.patch(apiUrl, payload);
}

// 선택삭제
function reqRemoveNewsAndNoticeList( payload ) {
  var apiUrl = '/whatsnew/newsandnotice'; 
  return axios.delete(apiUrl, {data: payload} );
}


// ============================================================
// - What's New 
// - 이벤트 관리 event - whatsnew/event
// ============================================================
// reqEventList,
// reqCreateEvent,
// reqUpdateEvent,
// reqRemoveEventList,

// 조회
// 조회
function reqEventList( payload ) {
  
  var apiUrl = '/whatsnew/event'; 

  var params = {}; 

  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      

  if( payload.type != null && payload.type != undefined && payload.type != 0  ) {
    params.type = payload.type; 
  }      
  
  let queryString = $.param( params );
  
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);
}

// 등록  
function reqCreateEvent( formData ) {
  
  var apiUrl = '/whatsnew/event'; 

  return axios.post(apiUrl, formData , {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

}

// 수정  
function reqUpdateEvent( payload ) {
  var apiUrl = '/whatsnew/event'+ '/' + payload.id   ;  
  return axios.patch(apiUrl, payload);
}

// 선택삭제
function reqRemoveEventList( payload ) {
  var apiUrl = '/whatsnew/event'; 
  return axios.delete(apiUrl, {data: payload} );
}




// ============================================================
// - Support
// - 고객의 소리 customervoice 
// ============================================================

// 조회
// 조회
function reqCustomerVoiceList( payload ) {
  
  var apiUrl = '/support/customervoice'; 

  var params = {}; 

  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size = payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }     

  if( payload.category_id != null && payload.category_id != undefined && payload.category_id != 0 ) {
    params.category_id = payload.category_id; 
  }     
  
  let queryString = $.param( params );
  
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);
}

// 등록  
function reqCreateCustomerVoice( payload ) {
  
  var apiUrl = '/support/customervoice'; 
  return axios.post(apiUrl, payload);
}

// 수정  
function reqUpdateCustomerVoice( payload ) {
  var apiUrl = '/support/customervoice' + '/' + payload.id   ;  
  return axios.patch(apiUrl, payload);
}

// 선택삭제
function reqRemoveCustomerVoiceList( payload ) {
  var apiUrl = '/support/customervoice'; 
  return axios.delete(apiUrl, {data: payload} );
}

// 고객의 소리 답변글 
// 조회
function reqCustomerVoiceAnswerList( payload ) {
  
  var apiUrl = '/support/customervoice' + '/' + payload.customer_voice_id + '/answer'  ;  

  var params = {}; 

  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }     

  if( payload.category_id != null && payload.category_id != undefined && payload.category_id != 0 ) {
    params.category_id = payload.category_id; 
  }     
  
  let queryString = $.param( params );
  
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);
}
// 등록  
function reqCreateCustomerVoiceAnswer( payload ) {
  
  var apiUrl = '/support/customervoice/answer'; 
  return axios.post(apiUrl, payload);
}


// 수정  


// 선택삭제
// function reqRemoveCustomerVoicePostList( payload ) {
//   var apiUrl = '/customervoicepost'; 
//   return axios.delete(apiUrl, {data: payload} );
// }


// ============================================================
// - Support
// - 고객의 소리 담당자 관리 -  customervoicemanager
// ============================================================

// 조회 // 등록 // 수정 // 선택삭제
// <== manager 에서 처리 



// ============================================================
// - Support
// - 자주 하는 질문 관리 -  faq
// ============================================================

// 조회
function reqFaqList( payload ) {
  var apiUrl = '/support/faq'; 

  var params = {}; 
  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      

  if( payload.category_id != null && payload.category_id != undefined  ) {
    params.category_id = payload.category_id; 
  }   

  let queryString = $.param( params );
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 

  return axios.get(apiUrl);
}

// 등록     
function reqCreateFaq( formData ) {
  var apiUrl = '/support/faq'; 

  return axios.post(apiUrl, formData , {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

}
// 수정  
function reqUpdateFaq( payload ) {
  var apiUrl = '/support/faq'+ '/' + payload.id   ;  
  return axios.patch(apiUrl, payload);
}
// 선택삭제
function reqRemoveFaqList( payload ) {
  var apiUrl = '/support/faq'; 
  return axios.delete(apiUrl, {data: payload} );
}


// ============================================================
// - Support
// - 지류 상품권 구매 -  giftcard
// ============================================================

// 조회
function reqGiftcardOrderList( payload ) {
  
  var apiUrl = '/support/giftcardorder'; 

  var params = {}; 
  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      

  let queryString = $.param( params );
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 

  return axios.get(apiUrl);
}

// 등록     
function reqCreateGiftcardOrder( payload ) {
  var apiUrl = '/support/giftcardorder'; 
  return axios.post(apiUrl, payload);
}
// 수정  
function reqUpdateGiftcardOrder( payload ) {
  var apiUrl = '/support/giftcardorder'+ '/' + payload.id   ;  
  return axios.patch(apiUrl, payload);
}
// 선택삭제
function reqRemoveGiftcardOrderList( payload ) {
  var apiUrl = '/support/giftcardorder'; 
  return axios.delete(apiUrl, {data: payload} );
}


// ============================================================
// - Support
// - 지류 상품권 구매 담당자 관리 -  giftcardmanager
// ============================================================

// 조회 // 등록 // 수정 // 선택삭제
// <== manager 에서 처리 


// ============================================================
// - Support 
// - 매장찾기 관리 -  findstore
// - 다비전 데이터 연동 ( 일배치 처리 선행되어야 함.)
// ============================================================


// 조회
function reqStoreList( payload ) {
  
  var apiUrl = '/support/findstore'; 

  var params = {}; 
  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      


  let queryString = $.param( params ) ;
  
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 

  return axios.get(apiUrl);
}


// 등록 - 사용 안함. 
// 수정 - 사용 안함. 

// 선택삭제
// function reqRemoveStoreList( taskpart, payload ) {
//   var apiUrl = '/findstore';     
//   return axios.delete(apiUrl, {data: payload} );
// }




// ============================================================
// - Other 
// - 메인 비주얼 동영상 관리 - mainvisualcontents
// - apiUrl - others/mainvisualcontents 
// ============================================================

// 조회
function reqMainVisualContentsList( payload ) {
  
  var apiUrl = '/others/mainvisualcontents'; 

  var params = {}; 
  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      

  let queryString = $.param( params );
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 

  return axios.get(apiUrl);
}
 
// 등록     
function reqCreateMainVisualContents( formData ) {

  var apiUrl = '/others/mainvisualcontents'; 
  return axios.post(apiUrl, formData , {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// 수정  
function reqUpdateMainVisualContents( payload ) {
  var apiUrl = '/others/mainvisualcontents'+ '/' + payload.id   ;  
  return axios.patch(apiUrl, payload);
}
// 선택삭제
function reqRemoveMainVisualContentsList( payload ) {
  var apiUrl = '/others/mainvisualcontents'; 
  return axios.delete(apiUrl, {data: payload} );
}



// ============================================================
// - Other 
// - 팝업 관리 - popup
// - apiUrl - others/popup 
// ============================================================

// 조회
function reqPopupList( payload ) {
  
  var apiUrl = '/others/popup'; 

  var params = {}; 

  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      
  
  let queryString = $.param( params );
  
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);
}
   
// 등록 ( 첨부 X, 유튜브 링크 )
function reqCreatePopup( formData ) {
  
  var apiUrl = '/others/popup'; 
  return axios.post(apiUrl, formData , {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// 수정 ( 첨부 X )
function reqUpdatePopup( payload ) {

  var apiUrl = '/others/popup' + '/' + payload.id   ;  
  return axios.patch(apiUrl, payload);
}
// 선택삭제
function reqRemovePopupList( payload ) {
  var apiUrl = '/others/popup'; 
  return axios.delete(apiUrl, {data: payload} );
}


// ============================================================
// - Other 
// - 개인정보처리방침 관리 - privacypolicy
// - apiUrl - others/privacypolicy
// ============================================================

// 조회 
function reqPrivacyPolicy( ) {
  var apiUrl = '/others/privacypolicy' ; 
  return axios.get(apiUrl);
}

function reqPrivacyPolicyList( payload ) {

  var apiUrl = '/others/privacypolicies' ; 

  var params = {}; 

  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      
  
  let queryString = $.param( params );
  
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 

  return axios.get(apiUrl);
}

// 수정  - 이것만으로 처리. 
function reqUpdatePrivacyPolicy( payload ) {
  // payload  
  // { 
  //   "contents" : "변경된 개인정보처리방침"
  // }
  

  var apiUrl = '/others/privacypolicy' + '/' + payload.id ; 

  return axios.put(apiUrl, payload);
}

function reqCreatePrivacyPolicy( payload ) {
  // payload  
  // { 
  //    "title": "제목"
  //   "contents" : "변경된 개인정보처리방침"
  // }
  var apiUrl = '/others/privacypolicy' ; 
  return axios.post(apiUrl, payload);
}

// 선택삭제
function reqRemovePrivacyPolicyList( payload ) {
  var apiUrl = '/others/privacypolicy'; 
  return axios.delete(apiUrl, {data: payload} );
}


// ============================================================
// - Other 
// - 관리자 계정  - 
// - apiUrl - others/admin
// ============================================================

// 조회
function reqAdminList( payload ) {
  
  var apiUrl = '/admins'; 

  var params = {}; 

  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      

  if( payload.admin_id != null && payload.admin_id != undefined && payload.admin_id.length > 0 ) {
    params.admin_id = payload.admin_id; 
  }   
  
  let queryString = $.param( params );
  
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);
}

// id 중복 체크 를위한. 
function reqFindAdmin( payload ) {
  var apiUrl = '/admins'; 

  var params = {}; 
  if( payload.admin_id != null && payload.admin_id != undefined && payload.admin_id.length > 0 ) {
    params.admin_id = payload.admin_id; 
  }   
  
  let queryString = $.param( params );
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  return axios.get(apiUrl);
}

// 등록 ( 첨부 X, 유튜브 링크 )
function reqCreateAdmin( payload ) {
  
  var apiUrl = '/admins'; 
  return axios.post(apiUrl, payload );
}

// 수정 ( 첨부 X )
function reqUpdateAdmin( payload ) {

  var apiUrl = '/admins' + '/' + payload.id   ;  
  return axios.patch(apiUrl, payload);
}

// 선택삭제
function reqRemoveAdminList( payload ) {
  var apiUrl = '/admins'; 
  return axios.delete(apiUrl, {data: payload} );
}

function reqAdminGroupList( ) {
  
  var apiUrl = '/admins_groups';   
  return axios.get(apiUrl);
}



// ============================================================
// - Other 
// - 관리자 히스토리
// - apiUrl - others/adminhistory 
// ============================================================


// 조회
function reqAdminActionLogList( payload ) {
  
  var apiUrl = '/others/admin/actionlog'; 

  var params = {}; 

  if( payload.page != null && payload.size != null ) {
    params.page = payload.page ;
    params.size =  payload.size ;   
  }

  if( payload.searchtext != null && payload.searchtext != undefined && payload.searchtext.length > 0 ) {
    params.searchtext = payload.searchtext; 
  }      
  
  let queryString = $.param( params );
  
  if( queryString.length > 0 ) {
    apiUrl += '?' + queryString ;
  } 
  
  return axios.get(apiUrl);
}


// attachemnts 
function reqCreateAttachments( formData ) {
  
  var apiUrl = '/attachments'; 
  return axios.post(apiUrl, formData , {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

//
//
//
function reqAuthnumberSendToMail( payload ) {
  var apiUrl = '/auth/authnumberemail'; 
  return axios.post(apiUrl, payload);
}

function reqCheck2FAAuthnumber( payload ) {
  var apiUrl = '/auth/authnumberemail/check'; 
  return axios.post(apiUrl, payload);
}


// 시그니처 이미지 정보 요청  
// eslint-disable-next-line no-unused-vars
function reqSignatureImage( payload ) {
  let apiUrl = '/admins/signatureimage/1';
  return axios.get(apiUrl);
}




export {

  // About 
  // eyesight 
  reqUpdateEyesight, 

  // 
  createProductBrand,
  reqUpdateProductBrand,
  removeProductBrandList,

  // 가맹점 현황 관리 
  reqStoreOpenConditionList,
  reqCreateStoreOpenCondition,
  reqUpdateStoreOpenCondition,
  reqRemoveStoreOpenConditionList,

  // 가맹점 오픈가능 지역관리
  reqStoreAreaCodeList,
  reqStoreAreaList,

  reqStoreAreaDetailsList,
  reqCreateStoreAreaDetailsList,
  reqUpdateStoreAreaDetailsList,

  reqStoreOpenInquirePostList,
  reqRemoveStoreOpenInquirePostList,

  reqPartnershipPostList,
  reqUpdatePartnershipPost,
  reqRemovePartnershipPostList,

  reqPartnershipTypeList,
  reqPartnershipTypeContentCount,
  reqPatchPartnershipTypeList,

  reqCreatePartnershipInquireAnswer,
  reqPartnershipInquireAnswerList,
  
  
  // ======== education ========
  // - 부서관리
  reqDepartmentList,
  reqCreateDepartment,
  reqUpdateDepartment,
  reqRemoveDepartmentList,

  // 부서 순위 정보 업데이트
  reqUpdateDepartmentOrderList,

  // - 조직관리
  reqEmployeeList,
  reqCreateEmployee,
  reqUpdateEmployee,
  reqRemoveEmployeeList,
  
  // - 성공사례 - successstory
  reqSuccessStoryList,
  reqCreateSuccessStory,
  reqUpdateSuccessStory,
  reqRemoveSuccessStoryList,

  // - 사진 및 졸업동영상 관리  - pictureandvideo
  reqPictureAndVideoList,
  reqCreatePictureAndVideo,
  reqUpdatePictureAndVideo,
  reqRemovePictureAndVideoList,

  // - 사관 공채 공고 관리 - officeropenrecruitment/announce
  reqOfficeropenrecruitmentAnnounceList, 
  reqCreateOfficeropenrecruitmentAnnounce,
  reqUpdateOfficeropenrecruitmentAnnounce,
  reqRemoveOfficeropenrecruitmentAnnounceList,

  // - 사관 공채 지원 관리 - officeropenrecruitment/apply
  reqOfficeropenrecruitmentApplyList, 
  reqCreateOfficeropenrecruitmentApply,
  reqUpdateOfficeropenrecruitmentApply,
  reqRemoveOfficeropenrecruitmentApplyList,

  // ======== education ========

  // ======== whatsnew ========
  
  // - 광고/홍보영상 관리 
  reqAdContentsList,
  reqCreateAdContents,
  reqUpdateAdContents,
  reqRemoveAdContentsList,

  // - 다비치 소식 ( 공지/뉴스 ) => newsandnotice
  reqNewsAndNoticeList,
  reqCreateNewsAndNotice,
  reqUpdateNewsAndNotice,
  reqRemoveNewsAndNoticeList,

  // - 이벤트 관리 => event 
  reqEventList,
  reqCreateEvent,
  reqUpdateEvent,
  reqRemoveEventList,

  // ======== whatsnew ========



  // ======== support   ========
  // - 고객의 소리 
  reqCustomerVoiceList ,
  reqCreateCustomerVoice ,
  reqUpdateCustomerVoice ,
  reqRemoveCustomerVoiceList ,
  // -- 고객의 소리 

  // - 고객의 소리 
  reqCustomerVoiceAnswerList ,
  reqCreateCustomerVoiceAnswer ,
  // -- 고객의 소리 



  // 지류 상품권 구매 
  reqGiftcardOrderList,
  reqCreateGiftcardOrder,
  reqUpdateGiftcardOrder,
  reqRemoveGiftcardOrderList,
  // - 지류 상품권 구매

  reqFaqList,
  reqCreateFaq,
  reqUpdateFaq,
  reqRemoveFaqList,

  // - 매장찾기 관리 
  reqStoreList,

  // ======== support   ========

  // 매니저 관리 
  reqCreateManager,
  reqManagerList,
  reqUpdateManager,
  reqRemoveManagerList,

  // ===================
  // Others
  // ===================
  
  // 메인 비주얼 콘텐츠 관리 
  reqMainVisualContentsList,
  reqCreateMainVisualContents,
  reqUpdateMainVisualContents,
  reqRemoveMainVisualContentsList,

  // 팝업 관리
  reqPopupList,
  reqCreatePopup,
  reqUpdatePopup,
  reqRemovePopupList,


  // - privacypolicy
  reqPrivacyPolicy,
  reqPrivacyPolicyList,
  reqCreatePrivacyPolicy,
  reqUpdatePrivacyPolicy,
  reqRemovePrivacyPolicyList,
  
  // 어드민 계정 관리
  reqAdminList,
  reqCreateAdmin,
  reqUpdateAdmin,
  reqRemoveAdminList,
  reqFindAdmin,
  
  reqAdminGroupList,
    
  // 어드민 로그
  reqAdminActionLogList,

  // 시그니처 이미지 
  reqSignatureImage, 

  // 첨부파일 생성 /수정 

  reqCreateAttachments,

  // 2차 인증 관련
  reqAuthnumberSendToMail,
  reqCheck2FAAuthnumber,

};
