
export const getters = {

    
    getHomePageURL: state => {
        return state.homePageUrl;
    },

    getTopMenu: state => {
        return state.topMenu;
    },

    getSideMenu: state => {
        return state.SideMenu;
    },

}