<template>
  <div>
    <header>
      <h1>
        <img src="@/assets/image/davich-logo-w.png" alt="davich logo" />
      </h1>
      <ul class="gnb">
        <li
          v-bind:class="{ checked: getTopMenu == 'about' }"
          v-show="isMyMenu('about')"
        >
          <a @click.stop="onMenu('about')">ABOUT</a>
        </li>
        <li
          v-bind:class="{ checked: getTopMenu == 'products' }"
          v-show="isMyMenu('products')"
        >
          <a @click.stop="onMenu('products')">PRODUCTS</a>
        </li>
        <li
          v-bind:class="{ checked: getTopMenu == 'partnership' }"
          v-show="isMyMenu('partnership')"
        >
          <a @click.stop="onMenu('partnership')">PARTNERSHIP</a>
        </li>
        <li
          v-bind:class="{ checked: getTopMenu == 'education' }"
          v-show="isMyMenu('education')"
        >
          <a @click.stop="onMenu('education')">EDUCATION</a>
        </li>
        <li
          v-bind:class="{ checked: getTopMenu == 'whatsnew' }"
          v-show="isMyMenu('whatsnew')"
        >
          <a @click.stop="onMenu('whatsnew')">WHAT'S NEW</a>
        </li>
        <li
          v-bind:class="{ checked: getTopMenu == 'support' }"
          v-show="isMyMenu('support')"
        >
          <a @click.stop="onMenu('support')">SUPPORT</a>
        </li>
        <li
          v-bind:class="{ checked: getTopMenu == 'others' }"
          v-show="isMyMenu('others')"
        >
          <a @click.stop="onMenu('others')">OTHERS</a>
        </li>
      </ul>
      <a v-bind:href="getHomePageURL" target="_blank" class="btn-icon"
        >홈페이지 바로가기
        <img
          src="@/assets/image/icon-shortcut.png"
          alt="shortcut button icon"
        />
      </a>
      <span
        >{{ getName }}(<em>{{ getAdminId }}</em
        >) 접속 중</span
      >
      <button class="btn-icon-left" @click="onConrifmLogout">
        <img
          src="@/assets/image/icon-sign-out.png"
          alt="log out icon"
        />로그아웃
      </button>
    </header>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import auth_menu from "../config/auth_menu";
import menuHandle from "../utils/menuhandle";

export default {
  name: "AppHeader",
  data() {
    return {
      adminId: "",
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters("auth", ["getAdminId", "getAuthGroup", "getName"]),
    ...mapGetters(["getTopMenu", "getHomePageURL"]),
  },
  created() {},
  methods: {
    // eslint-disable-next-line no-unused-vars
    // return true or false
    isMyMenu(menu_name) {
      var myAuthGroup = this.getAuthGroup;
      if (myAuthGroup == 0) {
        // 전메뉴 오픈
        // console.log( 'mymenu list all ' );
        return true;
      } else if (myAuthGroup == 99) {
        return false;
      } else {
        var myMenuObject = auth_menu[myAuthGroup];
        var myTopMenuList = Object.keys(myMenuObject);
        // console.log("myTopMenuList ", myTopMenuList);
        if (myTopMenuList != undefined)
          return myTopMenuList.includes(menu_name);
        return false;
      }
    },
    // ...mapMutations('set_top_menu'),
    onMenu(menuname) {
      // 로그인한 관리자의 권한에 따서
      // 사이드 메뉴의 첫 시작 메를 선택해 주어야함.
      const myAuthGroup = this.getAuthGroup;
      console.log("getMyAuthGroup ==> ", myAuthGroup);

      var defaultTopMenuSideMenuCompName =
        menuHandle.getAuthorizedDefaultTopMenuSideMenuNameComponentName(
          myAuthGroup,
          menuname
        );
      console.log("menuname ==> ", menuname);
      console.log(
        "defaultTopMenuSideMenuCompName ==> ",
        defaultTopMenuSideMenuCompName
      );

      var path = null;
      switch (menuname) {
        case "about":
          path = "/about/history";
          // console.log('current route.path => ',this.$route.path );
          this.$store.commit("set_menu", {
            topMenu: menuname,
            sideMenu: defaultTopMenuSideMenuCompName,
          });
          // if (this.$route.path !== path) this.$router.push(path);
          if (this.$route.path !== path) {
            this.$router.push({ name: defaultTopMenuSideMenuCompName });
          }
          break;
        case "products":
          path = "/products/glassesframe/list"; // 상위 메뉴 선택시 초기 디폴트 선택메뉴
          this.$store.commit("set_menu", {
            topMenu: menuname,
            sideMenu: defaultTopMenuSideMenuCompName,
          });
          if (this.$route.path !== path) {
            this.$router.push({ name: defaultTopMenuSideMenuCompName });
          }
          break;
        case "partnership":
          path = "/partnership/store/list"; // 상위 메뉴 선택시 초기 디폴트 선택메뉴
          this.$store.commit("set_menu", {
            topMenu: menuname,
            sideMenu: defaultTopMenuSideMenuCompName,
          });
          if (this.$route.path !== path) {
            this.$router.push({ name: defaultTopMenuSideMenuCompName });
          }
          break;
        case "education":
          path = "/education/department/list"; // 상위 메뉴 선택시 초기 디폴트 선택메뉴
          this.$store.commit("set_menu", {
            topMenu: menuname,
            sideMenu: defaultTopMenuSideMenuCompName,
          });
          if (this.$route.path !== path) {
            this.$router.push({ name: defaultTopMenuSideMenuCompName });
          }
          break;
        case "whatsnew":
          path = "/whatsnew/adcontent/list"; // 상위 메뉴 선택시 초기 디폴트 선택메뉴
          this.$store.commit("set_menu", {
            topMenu: menuname,
            sideMenu: defaultTopMenuSideMenuCompName,
          });
          if (this.$route.path !== path) {
            this.$router.push({ name: defaultTopMenuSideMenuCompName });
          }
          break;
        case "support":
          path = "/support/customervoicepost/list"; // 상위 메뉴 선택시 초기 디폴트 선택메뉴
          this.$store.commit("set_menu", {
            topMenu: menuname,
            sideMenu: defaultTopMenuSideMenuCompName,
          });
          if (this.$route.path !== path) {
            this.$router.push({ name: defaultTopMenuSideMenuCompName });
          }
          break;
        case "others":
          path = "/others/mainvisualcontents/list"; // 상위 메뉴 선택시 초기 디폴트 선택메뉴
          this.$store.commit("set_menu", {
            topMenu: menuname,
            sideMenu: defaultTopMenuSideMenuCompName,
          });
          if (this.$route.path !== path) {
            this.$router.push({ name: defaultTopMenuSideMenuCompName });
          }
          break;
      }
    },
    onConrifmLogout() {
      this.$fire({
        title: this.msg.logout_confirm_title,
        text: this.msg.logout_confirm_message,
        type: null,
        showCancelButton: true,
        cancelButtonText: "취소",
        confirmButtonText: "로그아웃",
      })
        .then((result) => {
          if (result.value) {
            this.onButtonLogout();
          }
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    onButtonLogout() {
      console.log("로그아웃 버튼");

      this.$store.dispatch("auth/reqLogout").then(() => {
        this.$router.push({ name: "Login" });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/helpers/variables";
@import "../assets/scss/helpers/mixins";
@import "../assets/scss/base/typhography";

header {
  z-index: 9999;
  position: relative;
  color: $color-white;
  width: 100%;
  height: 80px;
  background-color: $color-darkblue;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: $color-white;
  }
  // logo
  h1 {
    width: 166.2px;
    height: 40.1px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  // 상단 메뉴
  .gnb {
    height: 100%;
    display: flex;
    padding: 0 3.5vw;
    flex-wrap: nowrap;
    li {
      flex-shrink: 0;
      height: 100%;
      margin-right: 1.5vw;
      &:last-child {
        margin: 0;
      }
      &.checked {
        font-weight: 900;
        border-bottom: 2px solid white;
      }
      a {
        display: inline-block;
        height: 100%;
        color: $color-white;
        font-size: 17px;
        font-weight: 500;
        line-height: 80px;
        border-bottom: 2px solid $color-darkblue;
      }
    }
  }
  // 홈페이지 바로가기
  .btn-icon {
    flex-shrink: 0;
    width: 160px !important;
    height: 28px;
    margin-right: 30px;

    font-size: 14px;
    color: $color-darkblue;
    padding: 6px 16px;
    font-weight: 500;
    letter-spacing: normal;
    border-radius: 14px;
    background-color: $color-white;
    img {
      margin-left: 5px;
      width: 10.8px;
      height: 10.8px;
    }
  }
  // 접속중
  > span {
    flex-shrink: 0;
    font-size: 15px;
    em {
      font-weight: bold;
    }
  }
  //로그아웃
  .btn-icon-left {
    flex-shrink: 0;
    width: 81px;
    display: inline-block;
    margin-left: 20px;
    font-size: 15px;
    @include box-center-flex;
    color: $color-white;
    img {
      margin-right: 5px;
    }
  }
}
</style>
