<template>
  <div class="wrap">
    <app-header></app-header>
    <!-- router view 에 side menu 와 contents  -->
    <router-view />
  </div>
</template>
<script>
import AppHeader from '@/components/AppHeader.vue'

export default {
  name: 'DefaultLayout',
  components: {
    AppHeader,
  },
}
</script>
