// import axios from '@/api/axios-auth';
import { 
    reqManagerList , 
    reqCreateManager ,
    reqUpdateManager , 
    reqRemoveManagerList,

    reqCreateAttachments,

    reqSignatureImage,
} from "@/api/davich_api";

export const actions = {

    // eslint-disable-next-line no-unused-vars
    req_manager_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqManagerList( payload.taskpart, payload ).then( (res) => {
                console.log('req_manager_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    }, 

    // eslint-disable-next-line no-unused-vars
    req_create_manager ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreateManager( payload ).then( (res) => {
                console.log('req_create_manager res => ', res.data ); 
                resolve({ data: res.data } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_update_manager ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdateManager( payload ).then( (res) => {
                console.log('req_update_manager res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    },

    // eslint-disable-next-line no-unused-vars
    req_remove_manager_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemoveManagerList( payload ).then( (res) => {
                console.log('req_remove_manager_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    },

    // eslint-disable-next-line no-unused-vars
    // req_update_attachments ( { commit } ,  payload  ) {
    //     console.log('payload => ', payload );
    //     return new Promise(( resolve, reject ) => {
    //         reqRemoveManagerList( payload ).then( (res) => {
    //             console.log('req_remove_manager_list res => ', res.data ); 
    //             resolve({ data: res.data , total_count:res.headers['x-total-count']} );
    //         }).catch( (err) => { 
    //             console.log(err) ;    
    //             reject(err); 
    //         });
    //     });
    // },
 

    // eslint-disable-next-line no-unused-vars
    req_create_attachments ( { commit } ,  formData  ) {
        console.log('formData => ', formData );
        return new Promise(( resolve, reject ) => {
            reqCreateAttachments( formData ).then( (res) => {
                console.log('req_create_attachment res => ', res  ); 
                resolve({ res } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },
    


    // 시그니처 이미지 파일 업로드
  // ======================================================================== 
  // - signature image  
  // - 시그니처 이미지  ( /about/brochure )
  // ======================================================================== 

  // eslint-disable-next-line no-unused-vars
  req_signatureimage({ commit } , payload ) {

    return new Promise(( resolve, reject ) => {
        reqSignatureImage( payload ).then( (res) => {
            console.log('reqSignatureImage res => ', res  ); 
            resolve({ res } );
        }).catch( (err) => { 
            console.log(err) ;    
            reject(err); 
        });
    });

  },


}