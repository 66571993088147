export const mutations = {

    'SET_STORE_OPEN_CONDITION_LIST' (state, {store_open_condition_list}) {
        state.store_open_condition_list = store_open_condition_list ; 
    },
    'SET_STORE_OPEN_CONDITION_TOTALCOUNT' (state, {store_open_condition_count}) {
        state.store_open_condition_totalcount = store_open_condition_count ; 
    },

    // ================================================================================
    'SET_STORE_AREA_CODE_LIST' (state, {store_area_code_list}) {
        state.store_area_code_list = store_area_code_list ; 
    },
    // ================================================================================


    'SET_STORE_AREA_LIST' (state, {store_area_list}) {
        state.store_area_list = store_area_list ; 
    },
    'SET_STORE_AREA_TOTALCOUNT' (state, {store_area_totalcount}) {
        state.store_area_totalcount = store_area_totalcount ; 
    },


    'SET_STORE_AREA_DETAILS_LIST' (state, {store_area_details_list}) {
        state.store_area_details_list = store_area_details_list ; 
    },
    'SET_STORE_AREA_DETAILS_TOTALCOUNT' (state, {store_area_details_count}) {
        state.store_area_details_totalcount = store_area_details_count ; 
    },

    // 가맹 문의 게시글 관련 
    'SET_STORE_OPEN_INQUIRE_POST_LIST' (state, {store_open_inquire_post_list}) {
        state.store_open_inquire_post_list = store_open_inquire_post_list ; 
    },
    'SET_STORE_OPEN_INQUIRE_POST_TOTALCOUNT' (state, {store_open_inquire_post_totalcount}) {
        state.store_open_inquire_post_totalcount = store_open_inquire_post_totalcount ; 
    },

    // 제휴 문의 게시글 관련 
    'SET_PARTNERSHIP_POST_LIST' (state, {partnership_post_list}) {
        state.partnership_post_list = partnership_post_list ; 
    },
    'SET_PARTNERSHIP_POST_TOTALCOUNT' (state, {partnership_post_totalcount}) {
        state.partnership_post_totalcount = partnership_post_totalcount ; 
    },
    'SET_PARTNERSHIP_POST_PAGE' (state, {partnership_post_page}) {
        state.partnership_post_page = partnership_post_page ; 
    },
    'SET_PARTNERSHIP_POST_SELECTED_TYPE' (state, {partnership_post_selected_type}) {
        state.partnership_post_selected_type = partnership_post_selected_type ; 
    },

    // 제휴 유형 리스트
    'SET_PARTNERSHIP_TYPE_LIST' (state, {partnership_type_list}) {
        state.partnership_type_list = partnership_type_list ; 
    },
    'SET_PARTNERSHIP_TYPE_TOTALCOUNT' (state, {partnership_type_totalcount}) {
        state.partnership_type_totalcount = partnership_type_totalcount ; 
    },
     
}