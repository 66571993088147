export const getters = {

    getStoreOpenConditionList: state => {
        return state.store_open_condition_list ; 
    },
    getStoreOpenConditionTotalCount: state => {
        return state.store_open_condition_totalcount ; 
    },

    // ========================================================
    getStoreAreaCodeList: state => {
        return state.store_area_code_list ; 
    },

    getStoreAreaCodeDivision1List: state => {
        var codelist = state.store_area_code_list ;
        var division1Item = codelist.filter( el => el.class_type == 1 ) ; 
        return division1Item; 
    },
    // ========================================================


    getStoreAreaList: state => {
        return state.store_area_list ; 
    },
    getStoreAreaTotalCount: state => {
        return state.store_area_totalcount ; 
    },

    getStoreAreaDetailsList: state => {
        return state.store_area_details_list ; 
    },
    getStoreAreaDetailsTotalCount: state => {
        return state.store_area_details_count ; 
    },

    // ============================
    // 가맹점 개설 문의  
    // ============================
    getStoreOpenInquirePostList: state => {
        return state.store_open_inquire_post_list ; 
    },
    getStoreOpenInquirePostTotalCount: state => {
        return state.store_open_inquire_post_totalcount ; 
    },


    // ============================
    // 제휴 문의  
    // ============================
    getPartnershipPostList: state => {
        return state.partnership_post_list ; 
    },
    getPartnershipTotalCount: state => {
        return state.partnership_post_totalcount ; 
    },

    getPartnershipPostSelectedPage: state => {
        return state.partnership_post_page;
    },
    getPartnershipPostSelectedType: state => {
        return state.partnership_post_selected_type;
    },

    getPartnershipTypeList: state => {
        return state.partnership_type_list; 

    },
}