<template>
  <div>
    <nav>
      <ul>
        <li
          v-bind:class="{ checked: sideMenu == 'StoreList' }"
          v-show="IsMySideMenu('StoreList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('StoreList')"
            >가맹점 현황 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
          <!-- </router-link> -->
        </li>
        <li
          v-bind:class="{ checked: sideMenu == 'StoreOpenAreaList' }"
          v-show="IsMySideMenu('StoreOpenAreaList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('StoreOpenAreaList')"
            >가맹점 오픈가능 지역관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
          <!-- </router-link> -->
        </li>
        <li
          v-bind:class="{ checked: sideMenu == 'StoreOpenInquirePostList' }"
          v-show="IsMySideMenu('StoreOpenInquirePostList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('StoreOpenInquirePostList')"
            >가맹점 개설문의<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
          <!-- </router-link> -->
        </li>
        <li
          v-bind:class="{ checked: sideMenu == 'StoreOpenInquireManagerList' }"
          v-show="IsMySideMenu('StoreOpenInquireManagerList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('StoreOpenInquireManagerList')"
            >가맹점 개설문의 담당자 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
          <!-- </router-link> -->
        </li>
        <li
          v-bind:class="{ checked: sideMenu == 'PartnershipPostList' }"
          v-show="IsMySideMenu('PartnershipPostList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('PartnershipPostList')"
            >제휴문의<img src="@/assets/image/icon-arrow.png" alt="icon-arrow"
          /></a>
          <!-- </router-link> -->
        </li>
        <li
          v-bind:class="{ checked: sideMenu == 'PartnershipManagerList' }"
          v-show="IsMySideMenu('PartnershipManagerList', getAuthGroup)"
        >
          <a @click.stop="onSideMenu('PartnershipManagerList')"
            >제휴문의 담당자 관리<img
              src="@/assets/image/icon-arrow.png"
              alt="icon-arrow"
          /></a>
          <!-- </router-link> -->
        </li>
      </ul>
    </nav>
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters, mapMutations } from "vuex";
import menuhaneld from "../../utils/menuhandle";

export default {
  name: "Partnership",
  data: () => ({}),
  computed: {
    ...mapState({
      sideMenu: (state) => state.sideMenu,
    }),
    ...mapGetters("auth", ["getAuthGroup"]),
  },
  methods: {
    ...mapMutations({
      setPartnershipPostPage: "partnership/SET_PARTNERSHIP_POST_PAGE",
      setPartnershipPostSelectedType:
        "partnership/SET_PARTNERSHIP_POST_SELECTED_TYPE",
    }),
    IsMySideMenu: menuhaneld.IsMySideMenu,
    onSideMenu(menuname) {
      this.setPartnershipPostPage({ partnership_post_page: 1 });
      this.setPartnershipPostSelectedType({
        partnership_post_selectedType: null,
      });
      this.$store.commit("set_side_menu", { sideMenu: menuname });
      if (this.$route.name !== menuname) this.$router.push({ name: menuname });
    },
  },
}; 
</script>

<style></style>
