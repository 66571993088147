import Vue from "vue";
import App from "./App.vue";

import { router } from "./router";
import store from "./store";

import scss from "./assets/scss/style.scss";

import VueSimpleAlert from "vue-simple-alert";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import underscore from "vue-underscore";
import vueMoment from 'vue-moment';
import Paginate from "vuejs-paginate";
import DropdownDatepicker from "vue-dropdown-datepicker";
import message_text  from "./config/message"; 
import maskingFunc from "./utils/maskfunctions"; 

String.prototype.trim = function() {
  return this.replace(/^\s+|\s+$/g,"");
}
String.prototype.ltrim = function() {
  return this.replace(/^\s+/,"");
}
String.prototype.rtrim = function() {
  return this.replace(/\s+$/,"");
}

Vue.config.productionTip = process.env.NODE_ENV === "production";

Vue.use( VueSimpleAlert);
Vue.use( CKEditor );
Vue.use( underscore );
Vue.use( vueMoment);
Vue.use( Paginate );
Vue.use( DropdownDatepicker );
Vue.component('paginate', Paginate)
Vue.component('dropdown-datepicker', DropdownDatepicker);


Vue.mixin({
  data: function() {
    return {
      fileMain: null,
      files: [null, null, null, null, null],
      checkAllValue: false,
      checkedValues: [],
      searchtext: "", 
      get PAGE_SIZE() {
        return 10;  // 
      },
      msg: message_text,

      attachments: null ,  // 첨부 original 
      attachment: null ,  // 첨부 original ,

      datepicker_lang: {
        days:["일","월","화","수","목","금","토"],
        months: ["1월","2월","3월","4월","5월","6월","7월","8월","9월","10월","11월","12월"],
        yearFormat: "YYYY년",
        monthFormat: "MM월",
        monthBeforeYear: false,
      }
    }
  },
  created() {
    this.checkAllValue = false ;
    this.checkedValues = [] ;
    // console.log('mixin created ====> '); 
  },
  computed: { 
    getSelectOptionYears() {
      var date = new Date(); 
      const currentYear = date.getFullYear() ; 
      const minYear = parseInt(currentYear) - 20 ; 
      console.log( ' currentYear ' , currentYear , ' minYear ', minYear );
      return this.$_.range( currentYear , minYear ); 
    },
    totalPages: function () {
      return this.calPageCount(this.getDataTotalCount, this.PAGE_SIZE );
    },
  },
  methods: {
    // removeDataList( action_name  ) {
    //   this.$store.dispatch(action_name, {
    //     idarr: this.checkedValues,
    //   }).then( () => {
    //     this.checkedValues = [];
    //   });
    // },
    
    checkNull: maskingFunc.checkNull, 
    maskingFuncPhone: maskingFunc.phone,
    maskingFuncEmail: maskingFunc.email,
    maskingFuncName: maskingFunc.maskingName,
    formatSizeUnits(bytes){
      
      if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
      else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
      else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
      else if (bytes > 1)           { bytes = bytes + " bytes"; }
      else if (bytes == 1)          { bytes = bytes + " byte"; }
      else                          { bytes = "0 bytes"; }
      return bytes;
    },
    numberWithCommas(val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    daysInMonth (month, year) {
      var days = new Date(year, month, 0).getDate();
      return days ; 
    },
    calPageCount( totalcount  , size ) {
      var mok = parseInt(totalcount/size) ; 
      var remain =  totalcount % size ; 
      const pagecount = mok + (remain == 0 ? 0 : 1 ) ; 
      return pagecount; 
    },
    check() {
      if (!this.checkAllValue) {
        this.checkedValues = [];
        for (var idx in this.getData) {
          this.checkedValues.push(this.getData[idx].id.toString());
        }
      } else {
        this.checkedValues = [];
      }
    },
    onConfirmDelete() {
      this.$fire({
        title: this.msg.delete_confirm_title  ,
        text: this.msg.delete_confirm_message ,
        type: null ,
        showCancelButton: true,
        cancelButtonText: "취소",
        confirmButtonText: '삭제',
      }).then( result => {
        console.log('confirm result =>', result ); 
        if( result.value) {
          // 삭제 선택 ( confrim 선택함 )
          this.onSubmitDelete();
        }  
      }).catch( () => {
        console.log("OK not selected.");
      }); 
    },
    onConfirmDeleteOne() {
      this.$fire({
        title: this.msg.delete_confirm_title  ,
        text: this.msg.delete_confirm_message ,
        type: null ,
        showCancelButton: true,
        cancelButtonText: "취소",
        confirmButtonText: '삭제',
      }).then( result => {
        console.log('confirm result =>', result ); 
        if( result.value) {
          // 삭제 선택 ( confrim 선택함 )
          this.onSubmitDeleteOne();
        }  
      }).catch( () => {
        console.log("OK not selected.");
      }); 
    },
    onModifyCompleted( route_to ) {
      // 수정완료. 
      this.$fire({
        title: this.msg.edit_success_title,
        text: "", 
        type: "success",
        timer: 2000
      }).then(r => {
        console.log(r.value);
        if( route_to != null && route_to != undefined ) {
          this.$router.push({ name: route_to });
        }
      });
    }
  }
});



new Vue({
  router,
  store,
  scss: scss,
  render: (h) => h(App),
}).$mount("#app");
