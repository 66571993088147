// - mainvisualcontents ( 메인 비주얼 동영상 관리 )
const MainVisualContentsCreate  = () => import(/* webpackChunkName: "others" */ '../components/others/mainvisualcontents/MainVisualContentsCreate.vue');
const MainVisualContentsEdit    = () => import(/* webpackChunkName: "others" */ '../components/others/mainvisualcontents/MainVisualContentsEdit.vue');
const MainVisualContentsList    = () => import(/* webpackChunkName: "others" */ '../components/others/mainvisualcontents/MainVisualContentsList.vue');
const MainVisualContentsShow    = () => import(/* webpackChunkName: "others" */ '../components/others/mainvisualcontents/MainVisualContentsShow.vue');

// import MainVisualContentsCreate from "../components/others/mainvisualcontents/MainVisualContentsCreate.vue";
// import MainVisualContentsEdit from "../components/others/mainvisualcontents/MainVisualContentsEdit.vue";
// import MainVisualContentsList from "../components/others/mainvisualcontents/MainVisualContentsList.vue";
// import MainVisualContentsShow from "../components/others/mainvisualcontents/MainVisualContentsShow.vue";

// - davichnumber ( 숫자로 보는 다비치 관리 )
const DavichNumberEdit  = () => import(/* webpackChunkName: "others" */ '../components/others/davichnumber/DavichNumberEdit.vue');

// import DavichNumberEdit from "../components/others/davichnumber/DavichNumberEdit.vue";

// - popup ( 팝업 관리 )
const PopupCreate  = () => import(/* webpackChunkName: "others" */ '../components/others/popup/PopUpCreate.vue');
const PopupEdit    = () => import(/* webpackChunkName: "others" */ '../components/others/popup/PopUpEdit.vue');
const PopupList    = () => import(/* webpackChunkName: "others" */ '../components/others/popup/PopUpList.vue');
const PopupShow    = () => import(/* webpackChunkName: "others" */ '../components/others/popup/PopUpShow.vue');

// import PopupCreate from "../components/others/popup/PopUpCreate.vue";
// import PopupEdit from "../components/others/popup/PopUpEdit.vue";
// import PopupList from "../components/others/popup/PopUpList.vue";
// import PopupShow from "../components/others/popup/PopUpShow.vue";

// - privacypolicy  (개인정보처리방침)
const PrivacyPolicyCreate  = () => import(/* webpackChunkName: "others" */ '../components/others/privacypolicy/PrivacyPolicyCreate.vue');
const PrivacyPolicyEdit    = () => import(/* webpackChunkName: "others" */ '../components/others/privacypolicy/PrivacyPolicyEdit.vue');
const PrivacyPolicyList    = () => import(/* webpackChunkName: "others" */ '../components/others/privacypolicy/PrivacyPolicyList.vue');

// import PrivacyPolicyCreate from "../components/others/privacypolicy/PrivacyPolicyCreate.vue";
// import PrivacyPolicyEdit from "../components/others/privacypolicy/PrivacyPolicyEdit.vue";
// import PrivacyPolicyList from "../components/others/privacypolicy/PrivacyPolicyList.vue";

// import PrivacyPolicyShow from "../components/others/privacypolicy/PrivacyPolicyShow.vue";

// - admin ( 관리자계정 관리)
const AdminCreate  = () => import(/* webpackChunkName: "others" */ '../components/others/admin/AdminCreate.vue');
const AdminEdit    = () => import(/* webpackChunkName: "others" */ '../components/others/admin/AdminEdit.vue');
const AdminList    = () => import(/* webpackChunkName: "others" */ '../components/others/admin/AdminList.vue');
const AdminShow    = () => import(/* webpackChunkName: "others" */ '../components/others/admin/AdminShow.vue');

// import AdminCreate from "../components/others/admin/AdminCreate.vue";
// import AdminEdit from "../components/others/admin/AdminEdit.vue";
// import AdminList from "../components/others/admin/AdminList.vue";
// import AdminShow from "../components/others/admin/AdminShow.vue";

// - adminhistory ( 관리자 히스토리 )
const AdminHistoryCreate  = () => import(/* webpackChunkName: "others" */ '../components/others/adminhistory/AdminHistoryCreate.vue');
const AdminHistoryEdit    = () => import(/* webpackChunkName: "others" */ '../components/others/adminhistory/AdminHistoryEdit.vue');
const AdminHistoryList    = () => import(/* webpackChunkName: "others" */ '../components/others/adminhistory/AdminHistoryList.vue');
const AdminHistoryShow    = () => import(/* webpackChunkName: "others" */ '../components/others/adminhistory/AdminHistoryShow.vue');

// import AdminHistoryCreate from "../components/others/adminhistory/AdminHistoryCreate.vue";
// import AdminHistoryEdit from "../components/others/adminhistory/AdminHistoryEdit.vue";
// import AdminHistoryList from "../components/others/adminhistory/AdminHistoryList.vue";
// import AdminHistoryShow from "../components/others/adminhistory/AdminHistoryShow.vue";

export default [
  // - mainvisualcontents ( 메인 비주얼 동영상 관리 )
  {
    path: "/others/mainvisualcontents/create",
    name: "MainVisualContentsCreate",
    component: MainVisualContentsCreate,
  },
  {
    path: "/others/mainvisualcontents/edit",
    name: "MainVisualContentsEdit",
    component: MainVisualContentsEdit,
    props: true,
  },
  {
    path: "/others/mainvisualcontents/list",
    name: "MainVisualContentsList",
    component: MainVisualContentsList,
  },
  {
    path: "/others/mainvisualcontents/show",
    name: "MainVisualContentsShow",
    component: MainVisualContentsShow,
    props: true,
  },

  // - davichnumber ( 숫자로 보는 다비치 관리 )
  {
    path: "/others/davichnumber/edit",
    name: "DavichNumberEdit",
    component: DavichNumberEdit,
    props: true,
  },
  {
    path: "/others/davichnumber",
    name: "DavichNumber",
    component: DavichNumberEdit,
    props: true,
  },

  // - popup ( 팝업 관리 )
  {
    path: "/others/popup/create",
    name: "PopupCreate",
    component: PopupCreate,
  },
  {
    path: "/others/popup/edit",
    name: "PopupEdit",
    component: PopupEdit,
    props: true,
  },
  {
    path: "/others/popup/list",
    name: "PopupList",
    component: PopupList,
  },
  {
    path: "/others/popup/show",
    name: "PopupShow",
    component: PopupShow,
    props: true,
  },

  // - privacypolicy  (개인정보처리방침)
  {
    path: "/others/privacypolicy/create",
    name: "PrivacyPolicyCreate",
    component: PrivacyPolicyCreate,
  },
  {
    path: "/others/privacypolicy/edit",
    name: "PrivacyPolicyEdit",
    component: PrivacyPolicyEdit,
    props: true,
  },
  {
    path: "/others/privacypolicy/list",
    name: "PrivacyPolicyList",
    component: PrivacyPolicyList,
  },
  // {
  //   path: "/others/privacypolicy/show",
  //   name: "PrivacyPolicyShow",
  //   component: PrivacyPolicyShow,
  // },

  // - admin ( 관리자계정 관리)
  {
    path: "/others/admin/create",
    name: "AdminCreate",
    component: AdminCreate,
  },
  {
    path: "/others/admin/edit",
    name: "AdminEdit",
    component: AdminEdit,
    props: true,
  },
  {
    path: "/others/admin/list",
    name: "AdminList",
    component: AdminList,
  },
  {
    path: "/others/admin/show",
    name: "AdminShow",
    component: AdminShow,
    props: true,
  },

  // - adminhistory ( 관리자 히스토리 )
  {
    path: "/others/adminhistory/create",
    name: "AdminHistoryCreate",
    component: AdminHistoryCreate,
  },
  {
    path: "/others/adminhistory/edit",
    name: "AdminHistoryEdit",
    component: AdminHistoryEdit,
    props: true,
  },
  {
    path: "/others/adminhistory/list",
    name: "AdminHistoryList",
    component: AdminHistoryList,
  },
  {
    path: "/others/adminhistory/show",
    name: "AdminHistoryShow",
    component: AdminHistoryShow,
    props: true,
  },
];
