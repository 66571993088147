export default {
    create_success_title: "등록성공",
    edit_success_title: "수정하였습니다",
    delete_confirm_title: "정말로 삭제하시겠습니까?" , 
    delete_confirm_message: "게시물 삭제시 복구되지 않습니다.",
    logout_confirm_title: "로그아웃을 하시겠습니까?",
    logout_confirm_message: "",
    history_create_success_title: "등록성공",
    giftcardorder_complete_confirm_title: "완료 처리 하시겠습니까?",
    giftcardorder_complete_confirm_message: "",
}