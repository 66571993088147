export const mutations = {

  'SET_HISTORY_LIST' (state, {historylist}) {
    state.historylist = historylist ; 
  },
  'SET_HISTORY_TOTALCOUNT' (state, {history_total_count}) {
    state.historyTotalCount = history_total_count ; 
  },
  'SET_EYESIGHT_LIST' (state, {eyesightlist}) {
    state.eyesightlist = eyesightlist ; 
  },
  'SET_EYESIGHT_TOTALCOUNT' (state, {eyesight_total_count}) {
    state.eyesightTotalCount = eyesight_total_count ; 
  },

}