import { 

    reqAdContentsList,
    reqCreateAdContents,
    reqUpdateAdContents,
    reqRemoveAdContentsList,
    
    // - 다비치 소식 ( 공지/뉴스 ) => newsandnotice
    reqNewsAndNoticeList,
    reqCreateNewsAndNotice,
    reqUpdateNewsAndNotice,
    reqRemoveNewsAndNoticeList,

    reqEventList,
    reqCreateEvent,
    reqUpdateEvent,
    reqRemoveEventList,

} from "@/api/davich_api";


export const actions = {

    // ===============================================
    // - 광고/홍보영상 관리
    // - adcontent 
    // ===============================================
    
    // - 조회 - giftcard 
    // eslint-disable-next-line no-unused-vars
    req_adcontents_list ( { commit } , payload ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqAdContentsList( payload ).then( (res) => {
                console.log('res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_create_adcontents ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreateAdContents( payload ).then( (res) => {
                console.log('req_create_adcontents res => ', res.data ); 
                resolve({ data: res.data } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_update_adcontents  ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdateAdContents( payload ).then( (res) => {
                console.log('req_update_adcontents res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    },

    // - 선택 삭제   
    // eslint-disable-next-line no-unused-vars
    req_remove_adcontents_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemoveAdContentsList( payload ).then( (res) => {
                console.log('req_remove_adcontents_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // ===============================================
    // - 다비치 소식 (공지/뉴스)
    // - newsandnotice 
    // ===============================================

    // - 조회 - newsandnotice 
    // eslint-disable-next-line no-unused-vars
    req_newsandnotice_list ( { commit } , payload ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {  
            reqNewsAndNoticeList( payload ).then( (res) => {
                console.log('res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_create_newsandnotice ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreateNewsAndNotice( payload ).then( (res) => {
                console.log('req_create_newsandnotice res => ', res.data ); 
                resolve({ data: res.data } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_update_newsandnotice  ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdateNewsAndNotice( payload ).then( (res) => {
                console.log('req_update_newsandnotice res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    },

    // - 선택 삭제   
    // eslint-disable-next-line no-unused-vars
    req_remove_newsandnotice_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemoveNewsAndNoticeList( payload ).then( (res) => {
                console.log('req_remove_newsandnotice_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // ===============================================
    // - 이벤트 관리
    // - event 
    // ===============================================

    // - 조회 - giftcard 
    // eslint-disable-next-line no-unused-vars
    req_event_list ( { commit } , payload ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqEventList( payload ).then( (res) => {
                console.log('res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                reject(err); 
                console.log(err) ;     
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_create_event ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqCreateEvent( payload ).then( (res) => {
                console.log('req_create_adcontents res => ', res.data ); 
                resolve({ data: res.data } );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    req_update_event  ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqUpdateEvent( payload ).then( (res) => {
                console.log('req_update_adcontents res => ', res.data ); 
                resolve( res.data );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });

    },

    // - 선택 삭제   
    // eslint-disable-next-line no-unused-vars
    req_remove_event_list ( { commit } ,  payload  ) {
        console.log('payload => ', payload );
        return new Promise(( resolve, reject ) => {
            reqRemoveEventList( payload ).then( (res) => {
                console.log('req_remove_adcontents_list res => ', res.data ); 
                resolve({ data: res.data , total_count:res.headers['x-total-count']} );
            }).catch( (err) => { 
                console.log(err) ;    
                reject(err); 
            });
        });
    },


}