import auth_menu from "../config/auth_menu";


export default {

    getAuthorizedTopMenuList: function(auth_group) {
        var menuObject = auth_menu[auth_group]; 
        return  Object.keys(menuObject); 
    },
    getAuthorizedAllSideMenuComponents: function(auth_group) {
        var menuObject = auth_menu[auth_group]; 
        var topMenuList = this.getAuthorizedTopMenuList(auth_group) ;
        var sideMenuCompnentsList = []; 
        for( var menu of topMenuList) {
            sideMenuCompnentsList= sideMenuCompnentsList.concat(menuObject[menu]);
        }
        return sideMenuCompnentsList; 
    },
    getAuthorizedDefaultTopMenuName: function (auth_group) {
        var topMenuList = this.getAuthorizedTopMenuList(auth_group) ;
        var topMenu = topMenuList[0];
        return topMenu ; 
    },
    getAuthorizedDefaultSideMenuComponentName: function (auth_group) {
        var menuObject = auth_menu[auth_group]; 
        var topMenuList = this.getAuthorizedTopMenuList(auth_group) ;
        var topMenu = topMenuList[0];
        var sideMenuCompnentsList =  menuObject[topMenu] ; 
        return sideMenuCompnentsList[0]; 
    },
    getAuthorizedDefaultTopMenuSideMenuNameComponentName : function (auth_group , topment_name ) {
        if( auth_group != 0 ) {
            var menuObject = auth_menu[auth_group]; 
            var sideMenuCompnentsList =  menuObject[topment_name] ; 
            return sideMenuCompnentsList[0]; 
        }
        else {
            switch (topment_name) {
                case "about": return "HistoryList";
                case "products": return "GlassesFrameList"; 
                case "partnership": return "StoreList";
                case "education": return "DepartmentList";
                case "whatsnew": return "AdContentList"; 
                case "support": return "CustomerVoicePostList";
                case "others": return "MainVisualContentsList"; 
                default: return "HistoryList";
            }
        }
    },    
    IsMySideMenu: function (menuname , auth_group ) {
        // console.log( 'IsMySideMenu => ', menuname , auth_group); 
        if( auth_group  == 0 ) return true ; 
        else if( auth_group  == 99 ) return false ; 

        var menuObject = auth_menu[auth_group]; 
        var topMenuList = Object.keys(menuObject); 
        var allCompList = []; 
        for( var menu of topMenuList) {
            allCompList= allCompList.concat(menuObject[menu]);
        }
        // console.log( 'allCompList => ', allCompList );
        return allCompList.includes(menuname); 
    }

}
